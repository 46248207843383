import "@meddream/viewports-core"
import "./work-list-viewer-form.sass"

import * as React from "react"
import * as Yup from "yup"

import {
  clearCurrentMeasurement,
  clearCurrentTaskForm,
  getCurrentTaskEndpoint,
  setCurrentLineMeasurement,
  submitTask,
} from "../../actions/CentralReviewAction"
import { useDispatch, useSelector } from "react-redux"

import Button from "@material-ui/core/Button"
import { Grid } from "@material-ui/core"
import { RootState } from "../../reducers/mainReducer"
import SquareFootIcon from "@material-ui/icons/SquareFoot"
import SubmitModal from "../../components/common/Table/submit-modal"
import TextField from "@material-ui/core/TextField"
import { styled } from "@material-ui/styles"
import { useFormik } from "formik"
import { NotificationsActions } from "../../utils/Notifications"

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2CA895",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2CA895",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#2CA895",
    },
  },
})

const WorkListViewerForm: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)
  const [names, namesSet] = React.useState({})
  const [namesAndTypes, namesAndTypesSet] = React.useState({})
  const [visibleSubmitModal, visibleSubmitModalSet] = React.useState(false)
  const [title, titleSet] = React.useState("")
  const [subjectId, subjectIdSet] = React.useState("")
  const [sessionId, sessionIdSet] = React.useState("")
  const [submitButton, submitButtonSet] = React.useState("")
  const [formHasErr, formHasErrSet] = React.useState(false)
  const [activeIcon, setActiveIcon] = React.useState("")
  const [onSameInstance, onSameInstanceSet] = React.useState(false)
  const [dummyState, dummyStateSet] = React.useState(false)
  const [modalFormData, modalFormDataSet] = React.useState({})
  const globalViewport: any = global
  const namesAndTypesObj = {}
  const namesObj = {}

  React.useEffect(() => {
    dispatch(clearCurrentTaskForm())
    globalViewport.viewportsCore.setMouseButtonFunction(1, 0)
    // globalViewport.viewportsCore.layoutController.deselectActiveLayoutContainer()
  }, [])

  React.useEffect(() => {
    if(state.CentralReview.currentTask.task_info.status === "done") {
      disableCanvas()
    } else {
      enableCanvas()
    }
  }, [state.CentralReview.currentTask.task_info.status])

  React.useEffect(() => {
    createMeasureEvent()
    handleInstanceChange()
  }, [state.CentralReview.currentLineMeasurement])

  React.useEffect(() => {
    const reg = new RegExp("^[0-9]+$")

    if (state.CentralReview.currentTask.form_fields.length) {
      dispatch(clearCurrentTaskForm())
    }

    if (reg.test(props.match.params.task_id)) {
      Promise.all([dispatch(getCurrentTaskEndpoint(props.match.params))]).then(() => showMeasurement())
    } else {
      dispatch(NotificationsActions.error("Task ID is not a number!"))
    }
  }, [state.CentralReview.currentTask.annotation?.data?.length])

  React.useEffect(() => {
    for (const key in state.CentralReview.currentTask.form_fields) {
      if (
        key !== "task_id" &&
        key !== "projectset_id" &&
        key !== "annotation"
      ) {
        if("enum" in state.CentralReview.currentTask.form_fields[key]) {
          namesAndTypesObj[`${key}`] = "select"
        } else {
          namesAndTypesObj[
            `${key}`
          ] = `${state.CentralReview.currentTask.form_fields[key].type}`
        }
        namesObj[`${key}`] = ""
      }
    }
    namesSet(namesObj)
    namesAndTypesSet(namesAndTypesObj)
  }, [state.CentralReview.currentTask.form_fields])

  React.useEffect(() => {
    if (Object.keys(namesAndTypes).length) {
      Object.keys(namesAndTypes).forEach((type) => {
        switch (namesAndTypes[type]) {
          case "string":
            return (names[type] = Yup.string()
              .max(15, "Must be only 15 or less!")
              .required("Required!")
              .matches(
                /^[aA-zZ0-9-\s]+$/,
                "Only letters, numbers and hyphens are allowed for this field "
              ))
          case "select":
            return (names[type] = Yup.string()
              .required("Required!"))
          case "number":
            return (names[type] = Yup.string()
              .max(10, "Must be only 10 or less!")
              .required("Required!")
              .matches(
                /^\d*\.?\d*$/,
                "Only floating point number/numeric string are allowed for this field "
              ))
          case "integer":
            return (names[type] = Yup.string()
              .max(10, "Must be only 10 or less!")
              .required("Required!")
              .matches(
                /^[1-9]+[0-9]*$/,
                "Only integers are allowed for this field "
              ))
        }
        if ("target_lesion" in namesAndTypes) {
          return (names[type] = Yup.string()
            .max(20, "Must be only 20 or less!")
            .required("Required!")
            .matches(
              /^(\d*)[.](\d*)[x*](\d*)[.](\d*)$/,
              "Should be on the format '12.30 * 1.20' "
            ))
        }
      })
    }
  }, [namesAndTypes])

  const formik = useFormik({
    initialValues: {
      ...namesObj,
    },
    validationSchema: Yup.object({
      ...names,
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: formHasErr,
    validateOnBlur: false,
    validateOnMount: true,
  })

  React.useEffect(() => {
    formHasErrSet(Object.keys(formik.errors).length !== 0)
  }, [formik.errors])

  React.useEffect(() => {
    if (Object.keys(state.CentralReview.currentLineMeasurement).length) {
      document.getElementById("longest_diameter")?.focus()
    } else {
      document.getElementById("longest_diameter")?.blur()
    }
  }, [formik.values["longest_diameter"]])

  React.useEffect(() => {
    globalViewport.viewportsCore.registerEvent(
      "measurement-updated",
      (measurement) => {
        dispatch(setCurrentLineMeasurement(measurement))
        const strippedMeasurement = measurement.data.length.split(" mm")[0]
        formik.setFieldValue("longest_diameter", strippedMeasurement, false)
      }
    )
  }, [])

  const formData = {
    task_id: parseInt(props.match.params.task_id),
    projectset_id: parseInt(props.match.params.project_id),
  }

  const createMeasureEvent = () => {
    globalViewport.viewportsCore.registerEvent(
      "measurement-created",
      (data: any) => {
        const measurement =
          globalViewport.viewportsCore.measurementsController.getMeasurementDataById(
            data.containerId,
            data.id
          )
        if (!measurement) {
          const strippedMeasurement =
            state.CentralReview.currentLineMeasurement.data?.length.split(
              " mm"
            )[0]
          formik.setFieldValue("longest_diameter", strippedMeasurement, false)
          setActiveIcon("")
        } else {
          onSameInstanceSet(true)
          Promise.all([dispatch(setCurrentLineMeasurement(measurement))]).then(
            () => {
              const strippedMeasurement =
                measurement.data?.length.split(" mm")[0]
              formik.setFieldValue(
                "longest_diameter",
                strippedMeasurement,
                false
              )
              setActiveIcon("")
            }
          )
        }
      }
    )
  }

  const disableCanvas = () => {
    [
      document.getElementById("viewer"),
      document.getElementById("viewer-buttons-menu")
    ].forEach((el) => {
      if (el?.style) {
        el.style.pointerEvents = "none"
      }
    })
  }

  const enableCanvas = () => {
    [
      document.getElementById("viewer"),
      document.getElementById("viewer-buttons-menu")
    ].forEach((el) => {
      if (el?.style) {
        el.style.pointerEvents = "auto"
      }
    })
  }

  const showMeasurement = () => {
    if (
      state.CentralReview.currentTask.task_info.status === "done" &&
      Object.keys(state.CentralReview.currentTask.annotation).length
    ) {
      globalViewport.viewportsCore.registerEvent(
        "viewport-loader",
        (data: any) => {
          if (!data.status) {
            const measurement = state.CentralReview.currentTask.annotation
            globalViewport.viewportsCore.measurementsController.createNewMeasurement(
              state.CentralReview.currentTask.annotation["containerId"],
              measurement
            )
          }
        }
      )
    }
  }

  const handleInstanceChange = () => {
    if (state.CentralReview.currentLineMeasurement) {
      globalViewport.viewportsCore.registerEvent(
        "viewport-instance-changed",
        (viewportId: any, instanceUid: any) => {
          if (
            instanceUid ===
            state.CentralReview.currentLineMeasurement["instanceUid"]
          ) {
            document.getElementById("longest_diameter")?.focus()
            onSameInstanceSet(true)
          } else {
            document.getElementById("longest_diameter")?.blur()
            onSameInstanceSet(false)
          }
        }
      )
    }
  }

  const takeToSpecificMeasurementInstance = () => {
    if (state.CentralReview.currentLineMeasurement) {
      globalViewport.viewportsCore.layoutController.openInstanceToViewportContainer(
        state.CentralReview.currentLineMeasurement["containerId"],
        state.CentralReview.currentLineMeasurement["instanceUid"]
      )
      globalViewport.viewportsCore.layoutController.selectLayoutContainer(
        state.CentralReview.currentLineMeasurement["containerId"]
      )
    }
  }

  const deleteAllMeasurements = () => {
    globalViewport.viewportsCore.getActiveViewport().deleteMeasurements()
    const allMeasurements: any = []
    const measureIds: any = []
    globalViewport.viewportsCore.getViewportsList().forEach((data: any) => {
      allMeasurements.push(
        globalViewport.viewportsCore.measurementsController.getMeasurementsDataByContainerId(
          data.containerId
        )
      )
    })
    if (allMeasurements.length) {
      allMeasurements.map((layout) => {
        layout.map((measure) => {
          measureIds.push(measure.id)
        })
      })
    }
    if (measureIds.length) {
      globalViewport.viewportsCore.measurementsController.deleteMeasurementsByIds(
        measureIds
      )
    }
    formik.setFieldValue("longest_diameter", " ", false)
    dispatch(clearCurrentMeasurement())
  }

  const _openModal = (file: any = null) => {
    visibleSubmitModalSet(true)
    titleSet("Sign & Submit")
    subjectIdSet(state.CentralReview.currentTask.patient_secret_name)
    sessionIdSet(state.current.project.data.name)
    submitButtonSet("Sign")
  }

  const _closeSubmitModal = () => {
    visibleSubmitModalSet(false)
  }

  const handleActiveIcon = (value) => {
    setActiveIcon(value)
  }

  const prepareAnnotation = () => {
    const annotation = state.CentralReview.currentLineMeasurement
    return JSON.stringify(annotation)
  }

  const onSubmit = (values) => {
    if (state.current.project.data.config.password_verify) {
      modalFormDataSet({
        ...formData,
        ...values,
        annotation: prepareAnnotation(),
      })
      _openModal()
    } else {
      if (!Object.keys(formik.errors).length) {
        dispatch(
          submitTask(props, {
            ...formData,
            ...values,
            annotation: prepareAnnotation(),
          })
        )
      }
    }
  }

  const handleMeasurementChange = () => {
    const htmlCanvasElement = document.querySelectorAll(".image-viewport")
    htmlCanvasElement.forEach((el) => {
      const id = el?.id
      if (id) {
        const htmlElement = document.getElementById(id)
        if (htmlElement?.style) {
          htmlElement.style.pointerEvents = "auto"
        }
      }
    })

    if (activeIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.setMouseButtonFunction(0)
      globalViewport.viewportsCore.measurementsController.setActiveMeasurementType(
        ""
      )
    } else {
      handleActiveIcon("ruler")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 5)
      globalViewport.viewportsCore.measurementsController.setActiveMeasurementType(
        "ruler"
      )
    }
  }

  const buildForm: any = (key: any, index: number) => {
    if (key !== "task_id" && key !== "projectset_id") {
      if (
        state.CentralReview.currentTask.form_fields[key].type === "string" &&
        state.CentralReview.currentTask.form_fields[key].title !==
          "annotation string"
      ) {
        if ("enum" in state.CentralReview.currentTask.form_fields[key]) {
          if (state.CentralReview.currentTask.form_fields[key].enum.length) {
            return (
              <div key={index}>
                <div>
                  <h4>
                    {state.CentralReview.currentTask.form_fields[key].title}
                  </h4>
                  <CssTextField
                    id={key}
                    name={key}
                    select
                    disabled={
                      state.CentralReview.currentTask.task_info.status ===
                        "done" || state.session.user_data.isManager
                    }
                    value={
                      formik.values[key] ||
                      state.CentralReview.currentTask.form_fields[key].value ||
                      0
                    }
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option disabled value={0}>
                      {" "}
                      -- select an option --{" "}
                    </option>
                    {state.CentralReview.currentTask.form_fields[key].enum.map(
                      (option, index) => (
                        <option key={index} value={option[0]}>
                          {option[1]}
                        </option>
                      )
                    )}
                  </CssTextField>
                  {((formik.touched[key] && formik.errors[key]) ||
                    !formik.isSubmitting) && (
                    <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                      {formik.errors[key]}
                    </p>
                  )}
                </div>
              </div>
            )
          }
        } else {
          return (
            <div key={index}>
              <div>
                <h4>
                  {state.CentralReview.currentTask.form_fields[key].title}
                </h4>
                <CssTextField
                  id={key}
                  name={key}
                  disabled={
                    state.CentralReview.currentTask.task_info.status ===
                      "done" || state.session.user_data.isManager
                  }
                  value={
                    formik.values[key] ||
                    state.CentralReview.currentTask.form_fields[key].value
                  }
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                />
                {((formik.touched[key] && formik.errors[key]) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors[key]}
                  </p>
                )}
              </div>
            </div>
          )
        }
      }
      if (
        state.CentralReview.currentTask.form_fields[key].type === "number" ||
        state.CentralReview.currentTask.form_fields[key].type === "integer"
      ) {
        return (
          <div key={index}>
            <div>
              <h4>{state.CentralReview.currentTask.form_fields[key].title}</h4>
              <CssTextField
                id={key}
                name={key}
                style={{
                  color:
                    state.CentralReview.currentTask.task_info.status ===
                      "done" || state.session.user_data.isManager
                      ? "#aaa"
                      : "#292929",
                  cursor:
                    state.CentralReview.currentTask.form_fields[key].title ===
                    "Longest Diameter (mm)"
                      ? "pointer"
                      : "auto",
                }}
                disabled={
                  state.CentralReview.currentTask.task_info.status === "done" ||
                  state.session.user_data.isManager
                    ? true
                    : false
                }
                variant={
                  state.CentralReview.currentTask.form_fields[key].title ===
                  "Longest Diameter (mm)"
                    ? "standard"
                    : "outlined"
                }
                value={
                  formik.values[key] ||
                  (typeof state.CentralReview.currentTask.form_fields[key]
                    .value === "object"
                    ? state.CentralReview.currentTask.form_fields[key].value[
                        "$numberDecimal"
                      ]
                    : state.CentralReview.currentTask.form_fields[key].value)
                }
                onChange={formik.handleChange}
                InputProps={{
                  readOnly:
                    state.CentralReview.currentTask.task_info.status ===
                      "done" ||
                    state.session.user_data.isManager ||
                    state.CentralReview.currentTask.form_fields[key].title ===
                      "Longest Diameter (mm)",
                }}
                fullWidth
              />
              {state.CentralReview.currentTask.form_fields[key].title ===
                "Longest Diameter (mm)" &&
                state.CentralReview.currentTask.task_info.status !== "done" && (
                  <div style={{ marginTop: "10px" }}>
                    {Object.keys(state.CentralReview.currentLineMeasurement)
                      .length ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            width: "50%",
                            borderColor:
                              activeIcon || onSameInstance ? "#2CA895" : "#666",
                            color:
                              activeIcon || onSameInstance ? "#2CA895" : "#666",
                          }}
                          onClick={() => {
                            takeToSpecificMeasurementInstance()
                            document.getElementById(key)?.focus()
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            width: "50%",
                            borderColor:
                              activeIcon || onSameInstance ? "#2CA895" : "#666",
                            color:
                              activeIcon || onSameInstance ? "#2CA895" : "#666",
                          }}
                          onClick={() => {
                            document.getElementById(key)?.blur()
                            deleteAllMeasurements()
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <Button
                        style={{
                          borderColor: activeIcon ? "#2CA895" : "#666",
                          color: activeIcon ? "#2CA895" : "#666",
                          width: "100%",
                        }}
                        variant="outlined"
                        size="small"
                        startIcon={
                          <SquareFootIcon
                            style={{ color: activeIcon ? "#2CA895" : "#666" }}
                          />
                        }
                        onClick={(e) => {
                          activeIcon
                            ? document.getElementById(key)?.blur()
                            : document.getElementById(key)?.focus()
                          handleMeasurementChange()
                        }}
                      >
                        Measure
                      </Button>
                    )}
                  </div>
                )}
              {((formik.touched[key] && formik.errors[key]) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors[key]}
                </p>
              )}
            </div>
          </div>
        )
      }
    }
  }

  return (
    <div className="viewer-form-container">
      {visibleSubmitModal && (
        <SubmitModal
          open={visibleSubmitModal}
          onClose={_closeSubmitModal}
          title={title}
          subjectId={subjectId}
          sessionId={sessionId}
          submitButton={submitButton}
          formData={modalFormData}
        />
      )}
      <Grid container className="title-container">
        <Grid item xs={12} className="info">
          <h5>Subject ID: </h5>
          <h4>
            {state.CentralReview.currentTask.task_info.patient_secret_name}
          </h4>
        </Grid>
        <Grid item xs={12} className="info">
          <h5>Timepoint: </h5>
          <h4>{state.CentralReview.currentTask.task_info.timepoint}</h4>
        </Grid>
      </Grid>
      <form className="form-viewer" onSubmit={formik.handleSubmit}>
        {Object.keys(state.CentralReview.currentTask.form_fields).length && (
          <>
            {Object.keys(state.CentralReview.currentTask.form_fields).map(
              (key, index) => (
                <div style={{ width: "100%" }} key={index}>
                  {buildForm(key, index)}
                </div>
              )
            )}
          </>
        )}
        <Grid container spacing={2} style={{ marginTop: "50px" }}>
          {!props.isManager &&
          state.CentralReview.currentTask.task_info.status !== "done" ? (
            <Grid item xs={12}>
              <div className="btn-group">
                <button
                  className="btn-viewer-form tertiary"
                  onClick={(e) => {
                    e.preventDefault()
                    props.history.go(-1)
                  }}
                >
                  cancel
                </button>
                {Object.keys(formik.errors).length === 0 ? (
                  <button
                    id="add_collaboartor.add_collaborator_button"
                    className={
                      Object.keys(formik.errors).length
                        ? "btn-viewer-form inactive denial"
                        : "btn-viewer-form primary"
                    }
                    onClick={() => dummyStateSet(!dummyState)}
                  >
                    Submit
                  </button>
                ) : (
                  <button className="btn-viewer-form inactive denial" disabled>
                    Submit
                  </button>
                )}
              </div>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <button
                className="btn-viewer-form tertiary"
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.preventDefault()
                  props.history.go(-1)
                }}
              >
                back
              </button>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  )
}

export default WorkListViewerForm
