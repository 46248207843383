import React from "react"
import { Logo } from "./Logo"
import Tabs from "./dashboard/tabs"
import { Link } from "react-router-dom"

export const ProjectSidePanel = ({ elementsProject }) => {
  if (
    window.location.href.includes("task") &&
    !window.location.href.includes("history")
  ) {
    return <></>
  } else {
    return (
      <aside className={"project-side-panel"}>
        <Logo color="#279181" />
        <span className="dashboard-link">
          <Link to={"/project"}>Dashboard</Link>
        </span>
        <Tabs elements={elementsProject} />
      </aside>
    )
  }
}
