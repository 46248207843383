import axios from "axios"

const frontendUrl = process.env.REACT_APP_FRONTEND_URL
const backendUrl = process.env.REACT_APP_BACKEND_URL

// Static definition
export const base = backendUrl
// Used for updating the Papaya URL
export const clientBase = frontendUrl

export default axios.create({
  baseURL: base,
  headers: {
    "Mint-Api-Call": "1",
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: true,
})
