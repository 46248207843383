import React from "react"
import {
  QC_HISTORY_TABLE_FETCHING_DATA,
  QC_HISTORY_TABLE_RECEIVE_DATA,
  DATE_FORMAT,
} from "../constants/constants"
import { format } from "date-fns"

const formatReceiveData = (action) => {
  return action.payload.data.data.map((el) => {
    const data = {
      id: el._id || " - ",
      date: format(el.time_created.$date, `${DATE_FORMAT}`) || " - ", // 'LLL' in the format should take from the browser locale
      timestamp: el.time_created.$date, // need to add this so that we can sort by date properly
      username: el.userfull || " - ",
      qc_status: "",
      comments: "",
    }

    // we expect this to be a formatted HTML string
    // e.g.
    // Status : <b>pass</b> <br/>test pass
    //
    // split this into two parts status and comment
    const statusStr = el.new_value || ""
    const splitArray = statusStr.split("<br/>")

    if (splitArray.length > 1) {
      data.qc_status = splitArray[0]
      data.comments = splitArray[1]
    } else {
      data.qc_status = " - "
      data.comments = " - "
    }
    return data
  })
}

const initialState = {
  columns: [
    {
      Header: "Date & time",
      maxWidth: 250,
      accessor: "date",
      id: "date",
      show: true,
      sortField: "timestamp",
    },
    {
      Header: "Username",
      maxWidth: 250,
      accessor: "username",
      id: "username",
      show: true,
      sortField: "username",
    },
    {
      Header: "QC status",
      accessor: "qc_status",
      id: "qc_status",
      maxWidth: 450,
      show: true,
      sortField: "qc_status",
      Cell: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.qc_status }} />
      },
    },
    {
      Header: "Comments",
      accessor: "comments",
      id: "comments",
      maxWidth: 450,
      show: true,
      Cell: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.comments }} />
      },
    },
  ],
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case QC_HISTORY_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case QC_HISTORY_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
