import * as React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { withStyles } from "@material-ui/core"

const styles = {
  buttonMenu: {
    background: "transparent",
    padding: "0px",
    borderRadius: "5px",
    fontSize: "17px",
    marginLeft: "5px",
    height: "32px",
    minWidth: "40px",
  },
  dotImage: {
    height: "25px",
    width: "auto",
  },
}

class ExpandMenuButton extends React.Component<any, any> {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { classes, row, isForView } = this.props

    const cellIndex = this.props.cellDetails.cellIndex
    const rowIndex = this.props.cellDetails.rowIndex
    return (
      <React.Fragment>
        <Button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          className={classes.buttonMenu}
          aria-haspopup="true"
          onClick={this.handleClick}
          id={"more-button--" + row.name}
        >
          {this.props.centralReadingTable ? (
            <img
              alt="dotImage"
              className={classes.dotImage}
              src="assets/icons/dots.png"
            />
          ) : (
            <p>...</p>
          )}
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          // this puts an id on the visible menu
          PaperProps={{ id: "action-menu-list-div" }}
          // the BackDrop is an invisible fullscreen modal - clicking it closes the menu
          BackdropProps={{ id: "action-menu-backdrop", invisible: true }}
          // the following can be uncommented to position the menu
          // transformOrigin={{ horizontal : 75 , vertical : 60 }}
          children={
            isForView
              ? this.props.children
              : [
                  <MenuItem
                    id={"menu_item_info.row-" + rowIndex + "-cell-" + cellIndex}
                    onClick={this.props.actions.info}
                    disabled={!row.imagefile}
                  >
                    Info
                  </MenuItem>,
                  <MenuItem
                    id={
                      "menu_item_delete.row-" + rowIndex + "-cell-" + cellIndex
                    }
                    onClick={this.props.actions.delete}
                    disabled={true}
                  >
                    Delete
                  </MenuItem>,
                ]
          }
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ExpandMenuButton)
