import * as React from "react"
import iconSet from "../../styles/fonts/selection.json"
import IcomoonReact from "icomoon-react"

class AnalysisRender extends React.Component<any, any> {
  _getProcessingIcon() {
    const {
      row: { state, qa_status },
    } = this.props
    const stateIcon = {
      pending: <i className="material-icons">hourglass_empty</i>,
      running: <i className="material-icons">hourglass_empty</i>,
      waiting: <i className="material-icons">hourglass_empty</i>,
      exception: (
        <IcomoonReact
          iconSet={iconSet}
          color="#9B1A06"
          size={24}
          icon="screen-reject"
        />
      ),
      completed: (
        <IcomoonReact
          iconSet={iconSet}
          color="#279181"
          size={24}
          icon="screen-accept"
        />
      ),

      "completed:pass": (
        <IcomoonReact
          iconSet={iconSet}
          color="#279181"
          size={24}
          icon="user-accept"
        />
      ),
      "completed:fail": (
        <IcomoonReact
          iconSet={iconSet}
          color="#9B1A06"
          size={24}
          icon="user-reject"
        />
      ),

      "": <i className="material-icons">hourglass_empty</i>,
    }
    const key = state + (qa_status ? ":" + qa_status : "")
    return key in stateIcon ? (
      stateIcon[key]
    ) : (
      <i className="material-icons">remove</i>
    )
  }

  _getManualAnalysisIcon() {
    const {
      row: { qa_status },
    } = this.props
    const stateIcon = {
      pass: (
        <React.Fragment>
          <IcomoonReact
            iconSet={iconSet}
            color="#279181"
            size={24}
            icon="user-accept"
          />
          <span>Approved by the user</span>
        </React.Fragment>
      ),
      fail: (
        <React.Fragment>
          <IcomoonReact
            iconSet={iconSet}
            color="#9B1A06"
            size={24}
            icon="user-reject"
          />
          <span>Rejected by the user</span>
        </React.Fragment>
      ),
    }
    const key = qa_status
    return key in stateIcon ? (
      stateIcon[key]
    ) : (
      <i className="material-icons">remove</i>
    )
  }

  _getAutoAnalysisIcon() {
    const {
      row: { state },
    } = this.props
    const stateIcon = {
      pending: (
        <React.Fragment>
          <i className="material-icons">hourglass_empty</i>
          <span>Pending</span>
        </React.Fragment>
      ),
      running: (
        <React.Fragment>
          <i className="material-icons">hourglass_empty</i>
          <span>Running</span>
        </React.Fragment>
      ),
      waiting: (
        <React.Fragment>
          <i className="material-icons">hourglass_empty</i>
          <span>Waiting</span>
        </React.Fragment>
      ),
      exception: (
        <React.Fragment>
          <IcomoonReact
            iconSet={iconSet}
            color="#9B1A06"
            size={24}
            icon="screen-reject"
          />
          <span>Exception</span>
        </React.Fragment>
      ),
      completed: (
        <React.Fragment>
          <IcomoonReact
            iconSet={iconSet}
            color="#279181"
            size={24}
            icon="screen-accept"
          />
          <span>Completed</span>
        </React.Fragment>
      ),
    }
    const key = state
    return key in stateIcon ? (
      stateIcon[key]
    ) : (
      <i className="material-icons">remove</i>
    )
  }

  _renderIcons() {
    const { manual, auto } = this.props

    if (!manual && !auto) {
      return this._getProcessingIcon()
    } else if (manual && !auto) {
      return this._getManualAnalysisIcon()
    } else {
      return this._getAutoAnalysisIcon()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>{this._renderIcons()}</div>
      </React.Fragment>
    )
  }
}

export default AnalysisRender
