import * as React from "react"
import DropdownList from "react-widgets/DropdownList"

export const renderDropdownList = ({
  input,
  data,
  valuefield,
  textField,
  defaultValue,
  id,
  disabled = false,
}) => {
  return (
    <DropdownList
      {...input}
      data={data}
      valuefield={valuefield}
      onBlur={(event) => event.preventDefault()}
      textField={textField}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={input.onChange}
      id={id}
    />
  )
}
