import {
  SESSION_PROFILE_TABLE_RECEIVE_DATA,
  SESSION_PROFILE_TABLE_FETCHING_DATA,
  SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_IMAGES,
  SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_OTHER,
  DATE_FORMAT,
} from "../constants/constants"
import { format } from "date-fns"

const getFileSize = (size: number): string => {
  const fSExt = ["Bytes", "KB", "MB", "GB"]
  let fSize = size
  let i = 0
  while (fSize > 900) {
    fSize /= 1024
    i++
  }
  return Math.round(fSize * 100) / 100 + " " + fSExt[i]
}

const canBeViewed = (filename): number => {
  const ext = filename.split(".").pop()
  const viewableFileTypes = [
    "csv",
    "txt",
    "jpg",
    "gif",
    "png",
    "jpeg",
    "jpg",
    "tiff",
    "tif",
    "bmp",
  ]

  if (viewableFileTypes.indexOf(ext) !== -1) {
    return 1
  } else {
    return 0
  }
}

const formatReceiveDataImages = (action) => {
  return action.payload.images.map((el) => ({
    type: el.metadata.modality || "-",
    tags: el.tags.map((item) => item).join(" ") || "-",
    name: el.name || "-",
    size: getFileSize(el.size),
    imagefile: 1,
    viewoption: 1,
  }))
}

const formatReceiveDataOtherFiles = (action) => {
  return action.payload.other_files.map((el) => ({
    type: el.metadata.modality || "-",
    tags: el.tags.map((item) => item).join(" ") || "-",
    name: el.name || "-",
    size: getFileSize(el.size),
    imagefile: 0,
    viewoption: canBeViewed(el.name),
  }))
}

const formatReceiveData = (action) => {
  return action.payload.data.data.map((el) => {
    const data = {
      id: el._id || " - ",
      date: format(el.time_created.$date, `${DATE_FORMAT}`) || " - ", // 'LLL' in the format should take from the browser locale
      timestamp: el.time_created.$date, // need to add this so that we can sort by date properly
      username: el.userfull || " - ",
      qc_status: "",
      comments: "",
    }

    // we expect this to be a formatted HTML string
    // e.g.
    // Status : <b>pass</b> <br/>test pass
    //
    // split this into two parts status and comment
    const statusStr = el.new_value || ""
    const splitArray = statusStr.split("<br/>")

    if (splitArray.length > 1) {
      data.qc_status = splitArray[0]
      data.comments = splitArray[1]
    } else {
      data.qc_status = " - "
      data.comments = " - "
    }
    return data
  })
}

const initialState = {
  columns: [
    {
      Header: "Type",
      maxWidth: 50,
      accessor: "type",
      className: "type-row",
      id: "type",
      show: true,
      sortField: "type",
    },
    {
      Header: "Tags",
      maxWidth: 150,
      accessor: "tags",
      id: "tags",
      className: "tags-row",
      show: true,
    },
    {
      Header: "Name",
      maxWidth: 250,
      accessor: "name",
      id: "name",
      show: true,
      sortField: "name",
    },
    {
      Header: "Size",
      maxWidth: 150,
      accessor: "size",
      id: "size",
      show: true,
      sortField: "size",
    },
  ],
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  images: [],
  other_files: [],
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_PROFILE_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }
    case SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_IMAGES:
      return {
        ...state,
        data: { ...state.data, images: formatReceiveDataImages(action) },
        page: {
          ...state.page,
          ...action.payload.page,
        },
        isFetching: true,
        isInvalidated: false,
      }

    case SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_OTHER:
      return {
        ...state,
        data: {
          ...state.data,
          other_files: formatReceiveDataOtherFiles(action),
        },
        page: {
          ...state.page,
          ...action.payload.page,
        },
        isFetching: false,
        isInvalidated: false,
      }
    case SESSION_PROFILE_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
