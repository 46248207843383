import * as Yup from "yup"

export default class CreateStudyModel {
  static options = {
    anonym_level: [{ value: "strong", label: "Strong" }],
    age_measure: [
      { value: "month", label: "Months" },
      { value: "year", label: "Years" },
    ],
    data_location: [
      { value: "us", label: "USA" },
      { value: "as", label: "Asia" },
      { value: "eu", label: "EU" },
      { value: "europe-west3", label: "Germany" },
    ],
    status: [
      { value: "active", label: "Active" },
      { value: "setup", label: "In Set Up" },
      { value: "archived", label: "Archived" },
    ],
  }

  static initialValues: any = {
    data_location: { value: "us", label: "USA" },
    anonym_level: { value: "strong", label: "Strong" },
    age_measure: { value: "month", label: "Months" },
    status: { value: "setup", label: "In Set Up" },
    abbr: "",
    name: "",
    from_date: new Date(),
    to_date: new Date(),
    description: "",
  }

  static ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Project name cannot be empty"),
    abbr: Yup.string()
      .required("Abbreviation cannot be empty")
      .max(7, "Maximum length is 7 characters"),
    description: Yup.string().max(240, "Maximum length is 240 characters"),
    to_date: Yup.mixed().test(
      "to-date-not-before",
      "Ending date cannot be before starting date",
      function (this: any) {
        const parent = this.options.parent
        return (
          parent.from_date < parent.to_date ||
          parent.from_date.getTime() === parent.to_date.getTime()
        )
      }
    ),
  })
}
