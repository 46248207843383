import * as React from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import { compose } from "recompose"
import "./add-collaborator-form.sass"
import { addNewCollaborator, findUserByEmail } from "../../../actions/apiAction"
import { renderDropdownList } from "../../common/render-dropdown"
import { renderField } from "../../common/render-field-form"
import { withSnackbar } from "notistack"
import {
  openCloseInviteModal,
  setCurrentCollaborator,
} from "../../../actions/collaboratorsAction"
import InviteCollaboratorModal from "../../common/dashboard/invite-collaborator-modal"
import { fetchSitesDispatch } from "../../../actions/sitesTable"
import { PROJECT_OWNER_ROLE_NAME } from "../../../constants/constants"

// function to validate email address in form
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined

class AddCollaboratorForm extends React.Component<any, any> {
  componentDidMount(): void {
    const { current } = this.props
    const formValues = {
      user_id: "",
      data_location: current.project.data.config.data_location,
      access: this.getRolesForAddingCollaborator()[0] || "",
    }
    this.props.initialize(formValues)

    const { fetchSites } = this.props

    const pageParams = { pageSize: 100, page: 0, sorted: "" }
    fetchSites(current.project.data._id, pageParams)
  }

  onSubmit(event) {
    event.preventDefault()
    const { current, form, valid } = this.props
    if (valid) {
      const formValues: any = {
        ...form.addCollaboratorForm.values,
        qa: 1,
        _pid: current.project.data._id,
        site: form.addCollaboratorForm.values.site
          ? form.addCollaboratorForm.values.site.short_name
          : "",
        data_location: form.addCollaboratorForm.values.data_location.value,
        access: form.addCollaboratorForm.values.access.value,
      }
      this.props.findUserByEmail({ email: formValues.user_id }).then((res) => {
        if (res.success === 1) {
          const updatedFormValues = JSON.parse(JSON.stringify(formValues)) // clone the object to leave teh form intact
          updatedFormValues.user_id = res.id // Update the user id with the user_id returend from call (as it may differ from email address)
          this.props
            .addNewCollaborator(updatedFormValues)

            .then((result) => {
              if (!result.error) {
                this.props.enqueueSnackbar(
                  "New collaborator was successfully created!",
                  { variant: "success" }
                )
                this.props.openCloseInviteModal(false)
                this.props.goToDashboard()
              } else {
                this.props.enqueueSnackbar(result.error, { variant: "error" })
              }
            })
        } else {
          this.props.openCloseInviteModal(true)
          this.props.setCurrentCollaborator(formValues)
        }
      })
    }
  }

  getRolesForAddingCollaborator() {
    const { current, projectConfig } = this.props
    let roleForCurrent = ""
    projectConfig.roles.map((role) => {
      if (current.project.data.name === role.project) {
        roleForCurrent = role.role_short_name
      }
    })
    let roles: any = []
    projectConfig.data.project_types.filter((role) => {
      if (
        role.value === current.project.data.project_type &&
        role.role_to_roles
      ) {
        roles = role.role_to_roles[roleForCurrent]
      } else if (
        role.value === current.project.data.project_type &&
        !role.role_to_roles
      ) {
        roles = role.roles.reduce((acc, curr) => {
          if (curr[6] !== PROJECT_OWNER_ROLE_NAME) {
            acc.push({ value: curr[0], label: curr[6] })
          }
          return acc
        }, [])
      }
    })
    return roles
  }

  render() {
    const { current, sites } = this.props
    const sitesData = [{ name: "No Site", short_name: "" }, ...sites.data]

    return (
      <React.Fragment>
        <form onSubmit={(event) => this.onSubmit(event)}>
          <div className="field column is-half">
            <label>Email</label>
            <Field
              id="add_collaborator.email_input"
              className={"input"}
              name="user_id"
              type="text"
              validate={email}
              component={renderField}
            />
            <p className="helper-text">
              If desired collaborator does not have an account at QMENTA an
              invitation will be sent to the e-mail. When a user signs up with
              the specified e-mail, he will be automatically added to the study.
            </p>
          </div>
          <div className="field column is-half">
            <label>Site</label>
            <Field
              id="add_collaborator.site_input"
              name="site"
              data={sitesData}
              valuefield="short_name"
              textField="name"
              defaultValue={current.project.data.sites[0]}
              component={renderDropdownList}
            />
          </div>
          <div className="field column is-half">
            <label>Role</label>
            <Field
              id="add_collaborator.role_input"
              className={"input"}
              name="access"
              data={this.getRolesForAddingCollaborator()}
              valuefield="value"
              textField="label"
              component={renderDropdownList}
            />
            <p className="helper-text">
              Please select the site the collaborator should have access to. All
              selected permissions will be given for the study and the selected
              sites
            </p>
          </div>
          <div className="buttons-container column is-half">
            <button
              id="add_collaboartor.cancel_button"
              className="btn tertiary"
              onClick={(e) => {
                e.preventDefault()
                this.props.history.goBack()
              }}
            >
              cancel
            </button>
            <button
              id="add_collaboartor.add_collaborator_button"
              className="btn primary"
            >
              add collaborator
            </button>
          </div>
        </form>
        <InviteCollaboratorModal open={this.props.collaborators.inviteModal} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => {
  return {
    addNewCollaborator: (id) => dispatch(addNewCollaborator(id)),
    setCurrentCollaborator: (data) => dispatch(setCurrentCollaborator(data)),
    findUserByEmail: (email) => dispatch(findUserByEmail(email)),
    openCloseInviteModal: (val) => dispatch(openCloseInviteModal(val)),

    fetchSites: (projectId, pageParams) =>
      dispatch(fetchSitesDispatch(projectId, pageParams)),
  }
}

export default compose(
  withSnackbar,
  reduxForm({
    form: "addCollaboratorForm",
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AddCollaboratorForm)
