import * as React from "react"
import GoogleAnalytics from "react-ga"

// Initialize Google Analytics with appropriate UID depending on the environment
let google_analytics_tracking_id = "foo"
if (process.env.NODE_ENV === "production") {
  // Set automatically when building (npm build)

  if (window.location.host.includes("dev")) {
    // "https://trials-dev.qmenta.com";
    google_analytics_tracking_id = "UA-102911882-8"
  } else {
    // production
    // "https://trials.qmenta.com";
    google_analytics_tracking_id = "UA-102911882-7"
  }
}

GoogleAnalytics.initialize(google_analytics_tracking_id)

// Create Component decorator for Route to emit analytics events when navigating to different locations of the application
// Reference: https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
const withGATracker = (WrappedComponent, options = {}) => {
  const trackPage = (page: string) => {
    GoogleAnalytics.set({
      page,
      ...options,
    })
    GoogleAnalytics.pageview(page)
  }

  interface Props {
    location: any
  }

  const HOC = class extends React.Component<Props> {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search
      trackPage(page)
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search
      const nextPage = this.props.location.pathname + this.props.location.search

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withGATracker
