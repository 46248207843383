import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import TextField from "@material-ui/core/TextField"
import { compose } from "recompose"
import "./collaborators-edit-form.sass"
import FormControl from "@material-ui/core/FormControl"
import DropdownList from "react-widgets/DropdownList"
import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import { addNewCollaborator } from "../../../actions/apiAction"
import { withSnackbar } from "notistack"

import { PROJECT_OWNER_ROLE_NAME } from "../../../constants/constants"
import { RootState } from "../../../reducers/mainReducer"
import { setCurrentCollaborator } from "../../../actions/collaboratorsAction"

const EditCollaboratorForm: React.FC = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  const [isLoading, isLoadingSet] = React.useState(false)
  const [isOwner, isOwnerSet] = React.useState<boolean>(false)
  const [site, siteSet] = React.useState<any>([])
  const [formValues, formValuesSet] = React.useState({})

  const [allRoles, setAllRoles] = React.useState<any>([])
  const [user, setUser] = React.useState<any>({})

  React.useEffect(() => {
    const currentCollaborator = state.current.project.data.users.filter(
      (collaborator) => {
        return collaborator.user_id === props.match.params.collaborator_id
      }
    )
    editCollaborator(currentCollaborator[0])
    getSitesData()
  }, [])

  const editCollaborator = (row) => {
    const value = {
      ...row,
      role_short_name: state.current.project.data.users.reduce((acc, curr) => {
        if (row.user_id === curr.user_id) {
          acc = curr.access.slice(-1)[0]
        }
        return acc
      }, {}),
    }
    state.project_config.data.project_types.map((type) => {
      type.roles.map((item) => {
        if (value.access[0] === item[0] || value.access[1] === item[0]) {
          value.role = item[6]
        }
      })
    })
    setUser(value)
    isOwnerSet(value.role === PROJECT_OWNER_ROLE_NAME)
    dispatch(setCurrentCollaborator(value))
  }

  const getSitesData = () => {
    state.current.project.data.sites.find((value) => {
      if (value.name === user.data_location) {
        return siteSet(value)
      } else {
        return siteSet({ name: "No Site", short_name: "" })
      }
    })
  }

  React.useEffect(() => {
    const rolesArray: any = []
    const rolesForProject = state.project_config.data.project_types.find(
      (project_types) =>
        project_types.value === state.current.project.data.project_type
    ).roles
    rolesForProject.map((role: any) => {
      return rolesArray.push({
        value: role[0],
        name: role[6],
      })
    })
    setAllRoles(rolesArray)
  }, [state.collaborators.current])

  const sendFormData = async (data) => {
    return dispatch(
      addNewCollaborator({
        ...data,
        qa: 0,
        _pid: state.current.project.data._id,
      })
    )
  }

  const onSubmit = (event) => {
    event.preventDefault()
    isLoadingSet(true)
    sendFormData({
      ...formValues,
      user_id: props.match.params.collaborator_id,
      site: site.short_name,
      access: user.role_short_name,
    }).then((result: any) => {
      isLoadingSet(false)
      if (!result.error) {
        props.enqueueSnackbar("The collaborator was successfully edited!", {
          variant: "success",
        })
      }
      props.history.goBack()
    })
  }

  const handleSiteChange = (object) => {
    siteSet(object)
    formValuesSet({
      ...formValues,
      site: object.short_name,
    })
  }

  const handleRoleChange = (object) => {
    setUser({
      ...user,
      role: object.name,
      role_short_name: object.value,
    })
    formValuesSet({
      ...formValues,
      access: object.value,
    })
  }

  return (
    <React.Fragment>
      <Grid container style={{ minHeight: "80vh" }}>
        {isLoading ? (
          <Grid container>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress style={{ color: "#2CA895" }} />
            </div>
          </Grid>
        ) : (
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="field column is-half">
              <label>Email</label>
              <TextField
                style={{ width: "100%", backgroundColor: "#fff" }}
                name="user_id"
                type="text"
                disabled={true}
                placeholder={user.user_id}
                variant="outlined"
              />
              <p className="helper-text">
                If desired collaborator does not have an account at QMENTA an
                invitation will be sent to the e-mail. When a user signs up with
                the specified e-mail, he will be automatically added to the
                study.
              </p>
            </div>
            <div>
              <FormControl variant="outlined" className="field column is-half">
                <label>Site</label>
                <DropdownList
                  data={state.current.project.data.sites}
                  placeholder="All Sites"
                  value={site.name}
                  textField="name"
                  filter="contains"
                  onChange={(selected) => handleSiteChange(selected)}
                />
              </FormControl>
            </div>
            <div>
              <FormControl variant="outlined" className="field column is-half">
                <label>Role</label>
                <DropdownList
                  data={allRoles}
                  placeholder="All Roles"
                  value={user.role}
                  textField="name"
                  filter="contains"
                  disabled={isOwner}
                  onChange={(selected) => handleRoleChange(selected)}
                />
                <p style={{ marginBottom: "1rem" }} className="helper-text">
                  Please select the site the collaborator should have access to.
                  All selected permissions will be given for the study and the
                  selected sites
                </p>
              </FormControl>
            </div>
            <div className="buttons-container column is-half">
              <button
                className="btn tertiary"
                onClick={(e) => {
                  e.preventDefault()
                  props.history.goBack()
                }}
              >
                cancel
              </button>
              <button
                className={user.role ? "btn primary" : "btn inactive primary"}
              >
                save changes
              </button>
            </div>
          </form>
        )}
      </Grid>
    </React.Fragment>
  )
}

export default compose(withSnackbar)(EditCollaboratorForm)
