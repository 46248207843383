import { API_CALL, API_CALL_PAGINATION } from "../constants/constants"
import { base } from "../utils/request"
import { stringify } from "query-string"
import axios, { AxiosPromise } from "axios"

import { NotificationsActions } from "../utils/Notifications/index"
import { sessionLogout, setSessionExpired } from "../actions/sessionAction"

const paramsSerializer = (params) => stringify(params).replace("%2B", "+")

export default function apiMiddleware() {
  return ({ getState, dispatch }) =>
    (next) =>
    (action) => {
      const handleError = (response) => {
        response = response || {}

        if (response.status === 440 && !getState().session.session_expired) {
          dispatch(setSessionExpired(true))
          dispatch(NotificationsActions.error("Your session was expired!"))
          dispatch(sessionLogout())
        }

        if (
          response.headers &&
          response.headers["content-type"].includes("image/png")
        ) {
          return {
            ...response,
            data: URL.createObjectURL(response.data),
          }
        } else {
          const htmlResponse =
            "headers" in response &&
            response.headers["content-type"].includes("text/html")

          if (
            !htmlResponse &&
            "data" in response &&
            "success" in response.data &&
            !response.data.success &&
            response.status !== 440 &&
            response.status !== 200
          ) {
            const errorMessage = response.data.error || response.statusText

            dispatch(NotificationsActions.error(errorMessage))

            throw response.data
          }

          if (
            !htmlResponse &&
            "status" in response &&
            response.status === 400 &&
            response.status !== 200
          ) {
            const errorMessage = "Connection error (status code 400)"
            dispatch(NotificationsActions.error(errorMessage))
            throw new Error(errorMessage)
          }

          if (!("status" in response)) {
            const errorMessage = "Connection error"
            dispatch(NotificationsActions.error(errorMessage))
            throw new Error(errorMessage)
          }
        }

        return response
      }

      const url = action.url
      const baseURL = base + url
      const instance = axios.create({
        baseURL,
        headers: {
          "Mint-Api-Call": "1",
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/x-www-form-urlencoded",
          ...action.headers,
        },
        withCredentials: true,
        responseType:
          action.url && action.url.indexOf("download_file_metadata") !== -1
            ? "blob"
            : undefined,
      })
      let promise: AxiosPromise<any>

      switch (action.type) {
        case API_CALL:
        case API_CALL_PAGINATION:
          if (action.method === "GET") {
            promise = instance.get(baseURL, {
              params: action.postData,
              paramsSerializer,
            })
          } else {
            promise = instance.post(baseURL, stringify(action.postData))
          }

          return promise
            .then(handleError)
            .then(({ data, ...result }) => {
              if (action.type === API_CALL) {
                return data
              }

              const range = {
                from: 0,
                to: 0,
                total: 0,
                pages: 0,
              }
              if ("content-range" in result.headers) {
                const rangeData = result.headers["content-range"]
                  .replace("items ", "")
                  .split("/")

                const rangeNumbers = rangeData[0].split("-")

                range.from = rangeNumbers[0]
                range.to = rangeNumbers[1]
                range.total = rangeData[1]
              }

              return {
                data,
                _page: range,
              }
            })
            .catch((e) => {
              if ("response" in e) {
                return handleError(e.response)
              }

              throw e
            })

        default:
          return next(action)
      }
    }
}
