import React from "react"
import { makeStyles, Grid } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import {
  submitAdjudication,
  getCurrentAdjTaskEndpoint,
} from "../../actions/CentralReviewAction"
import { useFormik } from "formik"
import "./work-list-viewer-form.sass"
import * as Yup from "yup"
import TextField from "@material-ui/core/TextField"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table/"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { RootState } from "../../reducers/mainReducer"
import { Link } from "react-router-dom"

const useStyles = makeStyles({
  container: {
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  titleDescription: {
    marginBottom: "10px",
  },
  title: {
    marginBottom: "8px",
  },
  paragraph: {
    marginBottom: "8px",
  },
  headerCell: {
    display: "flex",
    alignItems: "center",
  },
  linkContainer: {
    height: "25px",
    marginLeft: "5px",
  },
  linkImage: {
    height: "100%",
  },
  adjudicatorForm: {
    minHeight: "50vh",
    padding: "25px",
  },
  formItem: {
    marginTop: "32px",
  },
  formButtons: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "flex-end",

    "& > :first-child": {
      marginRight: "25px",
    },
  },
})

let projectManager = ""
let isManager

const AdjudicatorViewer: React.FC = (props: any) => {
  const classes = useStyles()
  const [columns, setColumns] = React.useState<any[]>([])
  const [keys, setKeys] = React.useState<any[]>([])
  const [dummyState, dummyStateSet] = React.useState(false)
  const [formHasErr, formHasErrSet] = React.useState(false)

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  const formik = useFormik({
    initialValues: {
      comment: "",
      selection: "",
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Required!"),
      selection: Yup.string().required("Required!"),
    }),
    validateOnChange: formHasErr,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values) => handleSubmit(values),
  })

  React.useEffect(() => {
    formHasErrSet(Object.keys(formik.errors).length !== 0)
  }, [formik.errors])

  React.useEffect(() => {
    isManager = state.session.user_data.isManager
    projectManager = state.current.project.data.name
    dispatch(getCurrentAdjTaskEndpoint(props.match.params))
  }, [state.current.project.data])

  React.useEffect(() => {
    // -------------------- SET COLUMNS -----------------------------

    const valuesToExclude = [
      "Patient Name",
      "Timepoint",
      "Projectset Id",
      "Task Id",
    ]

    const keysToReturn = Object.keys(
      state.CentralReview.currentAdjTask.related_reader_tasks
    ).filter((key) => {
      if (valuesToExclude.includes(key)) {
        return false
      }
      return key
    })
    setKeys(keysToReturn)

    const columnsArray: any[] = [{ id: "data", label: "Data", minWidth: 170 }]

    state.CentralReview.currentAdjTask.related_reader_tasks["Task Id"]?.map(
      (value, index) => {
        columnsArray.push({
          id: `reader_${index + 1}`,
          label: `Reader ${index + 1}`,
          minWidth: 170,
          align: "right",
          url: `/project/${props.match.params.project_id}/task/${value}`,
          format: (value: number) => value.toFixed(2),
        })
      }
    )
    setColumns(columnsArray)
  }, [state.CentralReview.currentAdjTask.related_reader_tasks])

  const handleSubmit = (values) => {
    const formData = {
      task_id: parseInt(props.match.params.task_id),
      projectset_id: parseInt(props.match.params.project_id),
      number_readers: columns.length - 1,
    }
    dispatch(
      submitAdjudication(props, {
        ...formData,
        ...values,
      })
    )
  }

  const buildForm: any = (key: any, index: number) => {
    if (key !== "task_id" && key !== "projectset_id") {
      if (
        state.CentralReview.currentAdjTask.form_fields[key].type === "string"
      ) {
        if ("enum" in state.CentralReview.currentAdjTask.form_fields[key]) {
          if (state.CentralReview.currentAdjTask.form_fields[key].enum.length) {
            return (
              <div key={index}>
                <div className="control has-icons-right">
                  <h4 style={{ textAlign: "left" }}>
                    {state.CentralReview.currentAdjTask.form_fields[key].title}
                  </h4>
                  <TextField
                    id={key}
                    name={key}
                    select
                    disabled={
                      state.CentralReview.currentAdjTask.task_info.status ===
                        "done" || state.session.user_data.isManager
                    }
                    defaultValue={
                      formik.values[key] ||
                      state.CentralReview.currentAdjTask.form_fields[key].value
                    }
                    value={
                      formik.values[key] ||
                      state.CentralReview.currentAdjTask.form_fields[key].value
                    }
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option disabled selected value={0}>
                      {" "}
                      -- select an option --{" "}
                    </option>
                    {state.CentralReview.currentAdjTask.form_fields[
                      key
                    ].enum.map(
                      (option, index) =>
                        !option.startsWith("reject") && (
                          <option key={index} value={option}>
                            {typeof option === "string" &&
                              `${option
                                .split("_")[0]
                                .charAt(0)
                                .toUpperCase()}${option
                                .split("_")[0]
                                .slice(1)} ${option.split("_")[1]}`}
                          </option>
                        )
                    )}
                  </TextField>
                  {((formik.touched[key] && formik.errors[key]) ||
                    !formik.isSubmitting) && (
                    <p
                      style={{
                        color: "#9B1A06",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {formik.errors[key]}
                    </p>
                  )}
                </div>
              </div>
            )
          }
        }
        if (key === "comment") {
          return (
            <div className="control has-icons-right">
              <h4 style={{ textAlign: "left" }}>
                {state.CentralReview.currentAdjTask.form_fields[key].title}
              </h4>
              <TextareaAutosize
                disabled={
                  state.CentralReview.currentAdjTask.task_info.status ===
                    "done" || state.session.user_data.isManager
                }
                id={key}
                name={key}
                placeholder=""
                defaultValue=""
                style={{
                  height: "130px",
                  width: "100%",
                  border: ".5px solid #666",
                }}
                minRows={3}
                className="input"
                value={
                  formik.values[key] ||
                  state.CentralReview.currentAdjTask.form_fields[key].value
                }
                onChange={formik.handleChange}
              ></TextareaAutosize>
              {((formik.touched[key] && formik.errors[key]) ||
                !formik.isSubmitting) && (
                <p
                  style={{
                    color: "#9B1A06",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors[key]}
                </p>
              )}
            </div>
          )
        }
      }
    }
  }

  return (
    <div>
      <Grid className={classes.container}>
        <Grid className={classes.titleDescription}>
          <Grid item xs={12} sm={12} md={12}>
            <h1 className={"h1 " + classes.title}>Adjudication</h1>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={2} md={2}>
              <div>
                <p className={"p " + classes.paragraph}>
                  <span>SubjectID: </span>
                  <span>
                    <b>
                      {state.CentralReview.currentAdjTask.related_reader_tasks[
                        "Patient Name"
                      ] &&
                        state.CentralReview.currentAdjTask.related_reader_tasks[
                          "Patient Name"
                        ][0]}
                    </b>
                  </span>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <div>
                <p className={"p " + classes.paragraph}>
                  <span>Time Point: </span>
                  <span>
                    <b>
                      {state.CentralReview.currentAdjTask.related_reader_tasks
                        .Timepoint &&
                        state.CentralReview.currentAdjTask.related_reader_tasks
                          .Timepoint[0]}
                    </b>
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <div className={classes.headerCell}>
                        {column.label}
                        {column.label !== "Data" &&
                          column.label !== "Adjudication" && (
                            <div className={classes.linkContainer}>
                              <Link
                                to={column.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img
                                  alt="link"
                                  className={classes.linkImage}
                                  src="./assets/icons/link.png"
                                />
                              </Link>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  ))}
                  <TableCell align="center" style={{ minWidth: 170 }}>
                    <div className={classes.headerCell}>Adjudication</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keys.map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell key={index}>{row}</TableCell>
                      {Object.values(
                        state.CentralReview.currentAdjTask.related_reader_tasks[
                          row
                        ]
                      ).map((value: any, index) => {
                        if (typeof value === "boolean") {
                          return (
                            <TableCell key={index}>
                              {value.toString()}
                            </TableCell>
                          )
                        }
                        if (typeof value === "object") {
                          if (value["$numberDecimal"]?.length) {
                            return (
                              <TableCell key={index}>
                                {value.$numberDecimal}
                              </TableCell>
                            )
                          }
                        } else {
                          return <TableCell key={index}>{value}</TableCell>
                        }
                      })}
                      {index === 0 ? (
                        <TableCell
                          align="center"
                          rowSpan={keys.length}
                          style={{ minWidth: 170 }}
                        >
                          <div>
                            <form
                              id="adj_form_view_form"
                              className={classes.adjudicatorForm}
                              onSubmit={formik.handleSubmit}
                              style={{ padding: "10px" }}
                            >
                              {Object.keys(
                                state.CentralReview.currentAdjTask.form_fields
                              ).length > 3 && (
                                <>
                                  {Object.keys(
                                    state.CentralReview.currentAdjTask
                                      .form_fields
                                  ).map((key, index) => (
                                    <div style={{ width: "100%" }} key={index}>
                                      {buildForm(key, index)}
                                    </div>
                                  ))}
                                </>
                              )}
                              <Grid>
                                {state.session.user_data.isManager ||
                                state.CentralReview.currentAdjTask.task_info
                                  .status === "done" ? (
                                  <Grid item xs={12}>
                                    <button
                                      className="btn tertiary"
                                      style={{ width: "100%" }}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.history.go(-1)
                                      }}
                                    >
                                      back
                                    </button>
                                  </Grid>
                                ) : (
                                  <div className="btn-group">
                                    <button
                                      id="adj_form_view.cancel_button"
                                      className="btn tertiary"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        props.history.goBack()
                                      }}
                                    >
                                      cancel
                                    </button>
                                    {Object.keys(formik.errors).length === 0 ? (
                                      <button
                                        id="add_collaboartor.add_collaborator_button"
                                        className={
                                          Object.keys(formik.errors).length
                                            ? "btn inactive denial"
                                            : "btn primary"
                                        }
                                        onClick={() =>
                                          dummyStateSet(!dummyState)
                                        }
                                      >
                                        Submit
                                      </button>
                                    ) : (
                                      <div className="btn inactive denial">
                                        Submit
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Grid>
                            </form>
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </div>
  )
}

export default AdjudicatorViewer
