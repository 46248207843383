import * as React from "react"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { compose } from "recompose"
import Tooltip from "@material-ui/core/Tooltip"
import "./study-qc-card.sass"

const styles = {
  card: {
    minWidth: 275,
    marginBottom: "20px",
  },
  subTitle: {
    color: "#279181",
    fontSize: "18px",
  },
  descriptionContainer: {
    marginTop: "20px",
  },
  error: {
    backgroundColor: "#9B1A06",
    color: "#FFFFFF",
    width: "61px",
    height: "24px",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  no_qc: {
    width: "61px",
    height: "24px",
    backgroundColor: "#F5C434",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pass: {
    width: "61px",
    height: "24px",
    backgroundColor: "#2CA895",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  total: {
    width: "61px",
    height: "24px",
    backgroundColor: "#292929",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyColumn: {
    color: "#666",
  },
  keyTotal: {
    fontWeight: 500,
  },
}

const toolTipText =
  "Quality control (QC) is a standard which is used to conduct a study determined by research requirements"

class StudyQcCard extends React.Component<any, any> {
  createQcData(project) {
    const qcData = project.sites.reduce(
      (acc, current) => {
        if (current.uploaded_data) {
          acc.pass += current.uploaded_data.pass
          acc.fail += current.uploaded_data.fail
          acc.no_qc += current.uploaded_data.no_qc
        }
        acc.total += current.target || 0
        return acc
      },
      { pass: 0, no_qc: 0, fail: 0, total: 0 }
    )
    return qcData
  }

  render() {
    const { classes, projectData } = this.props
    const qcData = this.createQcData(projectData)
    return (
      <Card className={classes.card}>
        <CardContent>
          <h3 className="weight-medium">
            Sessions by QC status&nbsp;
            {/* tooltip placement doesn't work the same way as the docs described
                            bottom-start and bottom-end seem to be reversed
                            https://material-ui.com/components/tooltips/#positioned-tooltips
                        */}
            <Tooltip title={toolTipText} placement="bottom-start">
              <i className="material-icons sm-icon">help</i>
            </Tooltip>
          </h3>

          <div className={classes.cardInfoContainer}>
            <div className="info-container">
              <p className={classes.keyColumn}>Accepted</p>
              <div className={classes.pass}>
                <span id="study_qc_card.qc_pass">{qcData.pass}</span>
              </div>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Pending</p>
              <p className={classes.no_qc}>
                <span id="study_qc_card.no_qc">{qcData.no_qc}</span>
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Rejected</p>
              <p className={classes.error}>
                <span id="study_qc_card.qc_fail">{qcData.fail}</span>
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn + " " + classes.keyTotal}>
                Total projected
              </p>
              <p className={classes.total}>
                <span id="study_qc_card.qc_fail">{qcData.total}</span>
              </p>
            </div>
          </div>

          <div className="button-right-container">
            <button
              id="study_activity_card.view_activity_log_button"
              className="ghost-btn"
              onClick={() => this.props.history.push("data")}
            >
              <i className="material-icons">visibility</i>
              View sessions
            </button>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default compose(withStyles(styles))(StudyQcCard)
