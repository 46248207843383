import React, { PureComponent } from "react"

const BreadcrumbContext = React.createContext({})

interface Item {
  addItem?: object
  removeItem?: object
  replaceItem?: object
}

class BreadcrumbContextProvider extends PureComponent<Item, any> {
  state = {
    items: [],
  }

  _find = (crumb, crumbs) => {
    for (let i = 0; i < crumbs.length; i++) {
      const { name, url } = crumbs[i]
      if (crumb.name === name && crumb.url === url) {
        return i
      }
    }

    return undefined
  }

  addItem = (item) => {
    this.setState(({ items: prevCrumbs }) => ({
      items: [...prevCrumbs, item],
    }))
  }

  removeItem = (crumbToRemove) => {
    this.setState(({ items: prevCrumbs }) => {
      const crumbIndex = this._find(crumbToRemove, prevCrumbs)

      let items

      if (crumbIndex !== undefined) {
        items = [...prevCrumbs]
        items.splice(crumbIndex, 1)
      } else {
        items = prevCrumbs
      }

      return { items }
    })
  }

  replaceItem = (crumbToReplace, crumbReplacement) => {
    this.setState(({ items: prevCrumbs }) => {
      const crumbIndex = this._find(crumbToReplace, prevCrumbs)

      let items

      if (crumbIndex !== undefined) {
        items = [...prevCrumbs]
        items.splice(crumbIndex, 1, crumbReplacement)
      } else {
        items = prevCrumbs
      }

      return { items }
    })
  }

  getItems = (): any[] => {
    return this.state.items
  }

  render() {
    const { children } = this.props

    return (
      <BreadcrumbContext.Provider
        value={{
          addItem: this.addItem,
          removeItem: this.removeItem,
          replaceItem: this.replaceItem,
          getItems: this.getItems,
        }}
      >
        {children}
      </BreadcrumbContext.Provider>
    )
  }
}

export const Consumer: any = BreadcrumbContext.Consumer

export default BreadcrumbContextProvider
