import React, { useState, useEffect } from "react"
import { getCurrentTask } from "../../actions/CentralReviewAction"
import { InputBase, Grid } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { useSelector, useDispatch } from "react-redux"
import DropdownList from "react-widgets/DropdownList"
import { RootState } from "../../reducers/mainReducer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table/"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Link } from "react-router-dom"
import "./work-list-page.sass"

interface Column {
  id: any
  cellName: any
  label: string
  width?: number
  minWidth?: number
  align?: "left"
  className?: string
  sortField: string
}

const columns: Column[] = [
  {
    label: "Status",
    minWidth: 170,
    id: "status",
    cellName: "status",
    sortField: "status",
  },
  {
    label: "Patient",
    minWidth: 170,
    id: "patient_secret_name",
    cellName: "patient_secret_name",
    sortField: "patient_secret_name",
  },
  {
    label: "Timepoint",
    minWidth: 170,
    id: "session_id",
    cellName: "session_id",
    sortField: "session_id",
  },
  {
    label: "Date Submitted",
    minWidth: 190,
    id: "timestamp",
    cellName: "timestamp",
    sortField: "timestamp",
  },
  {
    label: "Task type",
    minWidth: 190,
    id: "task_type",
    cellName: "task_type",
    sortField: "task_type",
  },
  {
    label: "Actions",
    minWidth: 190,
    id: "action",
    cellName: "action",
    sortField: "action",
  },
]

const styles = {
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 700,
  },
  open: {
    color: "#3C73B8",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  rejected: {
    color: "#9B1A06",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  completed: {
    color: "#2CA895",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  done: {
    color: "#2CA895",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
}

const WorkList = (props: any) => {
  const { classes } = props
  const [crTable, crTableSet] = useState<
    Array<{
      id: number
      key: string
      value: string
      task_type: string
      status: string
    }>
  >([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const [searchTerm, searchTermSet] = useState("")
  const [task_value, task_valueSet] = useState({
    id: 0,
    name: "Open",
    value: "open",
  })
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  useEffect(() => {
    crTableSet(state.CentralReview.workList)
    filteringStatus()
  }, [state.CentralReview.workList])

  const MyTableCell = (props: any) => {
    return <TableCell {...props} />
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const setCurrentSubject = (subject) => {
    dispatch(getCurrentTask(subject))
  }

  const handleClickSearch = () => {
    filteringStatus()
  }

  // we want to trigger the search if enter is clicked
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      filteringStatus()
    }
  }

  const handleSearchChange = (e) => {
    searchTermSet(e.target.value)
  }

  const onSubmit = (event) => {
    props.onSubmit()
    event.preventDefault()
  }

  const filteringStatus = (object = { id: 0, name: "Open", value: "open" }) => {
    task_valueSet(object)
    const filtered: any[] = []
    state.CentralReview.workList.filter((value) => {
      if (searchTerm.length) {
        if (
          value.status === object.value &&
          value.patient_secret_name.includes(searchTerm)
        ) {
          return filtered.push(value)
        }
      } else {
        if (value.status === object.value) {
          return filtered.push(value)
        }
      }
      return null
    })
    crTableSet(filtered)
  }

  const sitesData = [
    { id: 0, name: "Open", value: "open" },
    { id: 1, name: "Done", value: "done" },
  ]

  return (
    <>
      <Grid container className="container-worklist-page">
        <Grid item xs={12} sm={12} md={2}>
          <h1>
            Worklist &nbsp;
            {state.CentralReview.isFetching ? (
              <img
                className="base-margin-half"
                width="24px"
                height="24px"
                src="assets/icons/loading-image.gif"
                alt="loading"
              />
            ) : null}
          </h1>
          <form onSubmit={onSubmit}>
            <div className="field column is-half">
              <label>Select Worklist Status</label>
              <DropdownList
                data={sitesData}
                dataKey="id"
                textField="name"
                value={task_value}
                onChange={(object) => filteringStatus(object)}
              />
            </div>
          </form>
        </Grid>
        <Grid item xs={12}>
          {crTable.length ? (
            <>
              <Grid item xs={12}>
                <div className="button-row">
                  <label>
                    There are {crTable.length} activities within this study
                  </label>
                  <div className="search-bar">
                    <InputBase
                      type="text"
                      placeholder="Search by Patient"
                      onChange={handleSearchChange}
                      onKeyDown={keyPress}
                      className="search-input"
                      id="activity_log.search_input"
                      inputProps={{ value: searchTerm }}
                    />
                    <button
                      onClick={handleClickSearch}
                      id="activity_log.search_button"
                      className="btn tertiary"
                    >
                      search
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {state.CentralReview.isFetching ? (
                  <img
                    className="base-margin-half"
                    width="50px"
                    height="50px"
                    src="assets/icons/loading-image.gif"
                    alt="loading"
                    style={{ margin: "auto" }}
                  />
                ) : (
                  <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <MyTableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </MyTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {crTable
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, rowIndex) => {
                              return (
                                <TableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={rowIndex}
                                >
                                  {columns.map((column, cellIndex) => {
                                    const value = row[column.id]
                                    if (column.id === "action") {
                                      return (
                                        <TableCell
                                          key={cellIndex}
                                          align={column.align}
                                        >
                                          <Link
                                            to={
                                              row.task_type === "Adjudicate"
                                                ? `/project/${props.match.params.project_id}/adjudicate/${row.id}`
                                                : `/project/${props.match.params.project_id}/task/${row.id}`
                                            }
                                          >
                                            <button
                                              className="view"
                                              onClick={() =>
                                                setCurrentSubject(row)
                                              }
                                              id={"view-button--" + column.id}
                                            >
                                              {row.status === "Completed" ||
                                              row.status === "Rejected" ||
                                              row.status === "done"
                                                ? "View"
                                                : row.task_type === "Read"
                                                ? "Read"
                                                : "Adjudicate"}
                                            </button>
                                          </Link>
                                        </TableCell>
                                      )
                                    } else {
                                      return (
                                        <TableCell
                                          key={cellIndex}
                                          align={column.align}
                                        >
                                          <p className={classes[value]}>
                                            {value}
                                          </p>
                                        </TableCell>
                                      )
                                    }
                                  })}
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={crTable.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                container
                className="no-task"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <p>No task on {task_value.name} status</p>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(WorkList)
