import "./overview-page.sass"

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CentralReviewStatus from "../../components/common/dashboard/central-review-status"
import Grid from "@material-ui/core/Grid"
import StudyAcquisitionCard from "../../components/common/dashboard/study-acquisition-card"
import StudyActivityCard from "../../components/common/study-activity-card"
import StudyInfoCard from "../../components/common/dashboard/study-info-card"
import StudyQcCard from "../../components/common/dashboard/study-qc-card"
import { getActivityListNumber } from "../../actions/apiAction"
import { useFlags } from "launchdarkly-react-client-sdk"

const Overview = (props) => {
  const [activities, setActivities] = useState(0)

  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  const flags = useFlags()

  useEffect(() => {
    Promise.all([
      dispatch(getActivityListNumber(props.match.params.project_id)),
    ]).then((res: any) => {
      setActivities(res)
    })
  }, [])

  const Delayed = () => {
    setTimeout(() => {
      return <CentralReviewStatus history={props.history} />
    }, 1)
  }

  return (
    <Grid container className="container-study-overview-page" item xs={12}>
      <Grid item className="titleDescription">
        <h1 className="h1 title">Study Overview</h1>
        <p className="p paragraph">
          On this screen you can view the highlights of your study, e.g., study
          details, uploaded sessions and their Quality Control status as well as
          the number of completed activities.
        </p>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className="cards-left-container">
        <StudyInfoCard projectData={state.current.project.data} />
        {state.session.user_data.isManager
          ? flags.activityLog && <StudyActivityCard number={activities} />
          : ""}
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {flags.iscentralreview &&
          !state.session.user_data.isManager &&
          Delayed()}
        {!flags.iscentralreview && (
          <StudyQcCard projectData={state.current.project.data} />
        )}
        {state.session.user_data.isManager && (
          <StudyAcquisitionCard projectData={state.current.project.data} />
        )}
      </Grid>
    </Grid>
  )
}

export default Overview
