import { SET_CURRENT_SITE, SET_SITES } from "../constants/constants"

export const setCurrentSite = (val) => ({
  type: SET_CURRENT_SITE,
  payload: val,
})
export const setSites = (data) => ({
  type: SET_SITES,
  payload: data.data,
})
