import {
  GET_WORKLIST,
  GET_CURRENT_TASK,
  CLEAR_CURRENT_TASK_FORM,
  GET_CURRENT_TASK_ENDPOINT,
  GET_CURRENT_ADJ_TASK_ENDPOINT,
  SET_ADJUDICATOR,
  SET_READER_1,
  SET_READER_2,
  GET_CENTRAL_READING_PROGRESS,
  SET_MATRIX_DATA,
  SET_CURRENT_LINE_MEASUREMENT,
  CLEAR_CURRENT_MEASUREMENT,
} from "../constants/constants"

const initialState = {
  currentTask: {
    task_info: {},
    form_fields: [],
    previous_timepoints: [],
    historyTable: {},
    related_reader_tasks: [],
  },
  currentLineMeasurement: {},
  currentAdjTask: {
    form_fields: [],
    related_reader_tasks: [],
    task_info: {},
  },
  workList: [],
  progress: [],
  isFetching: true,
  isInvalidated: true,
  isEmpty: true,
  totalResult: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WORKLIST:
      return {
        ...state,
        workList: action.payload,
        isFetching: action.isFetching,
        isInvalidated: action.isInvalidated,
        isEmpty: action.isEmpty,
      }

    case SET_READER_1: {
      return {
        ...state,
        reader_1: action.payload,
      }
    }

    case SET_READER_2: {
      return {
        ...state,
        reader_2: action.payload,
      }
    }

    case SET_ADJUDICATOR: {
      return {
        ...state,
        adjudicator: action.payload,
      }
    }

    case GET_CENTRAL_READING_PROGRESS: {
      const checkFailPass = (qa) => {
        if (qa === "fail") {
          return "./assets/icons/fail.png"
        } else if (qa === "pass") {
          return "./assets/icons/pass.png"
        } else {
          return "./assets/icons/open.png"
        }
      }

      const checkReader1 = (reader1) => {
        if (reader1 === "not_needed") {
          return "./assets/icons/not_needed.png"
        } else if (reader1 === "open") {
          return "./assets/icons/open.png"
        } else if (reader1 === "not_assigned") {
          return "./assets/icons/assign.png"
        } else if (reader1 === "in_progress") {
          return "./assets/icons/in_progress.png"
        } else if (reader1 === "done") {
          return "./assets/icons/pass.png"
        } else {
          return "./assets/icons/fail.png"
        }
      }

      const checkReader2 = (reader2) => {
        if (reader2 === "not_needed") {
          return "./assets/icons/not_needed.png"
        } else if (reader2 === "open") {
          return "./assets/icons/open.png"
        } else if (reader2 === "not_assigned") {
          return "./assets/icons/assign.png"
        } else if (reader2 === "in_progress") {
          return "./assets/icons/in_progress.png"
        } else if (reader2 === "done") {
          return "./assets/icons/pass.png"
        } else {
          return "./assets/icons/fail.png"
        }
      }

      const checkAdjudicator = (adjudicator) => {
        if (adjudicator === "not_needed") {
          return "./assets/icons/not_needed.png"
        } else if (adjudicator === "open") {
          return "./assets/icons/open.png"
        } else if (adjudicator === "not_assigned") {
          return "./assets/icons/assign.png"
        } else if (adjudicator === "in_progress") {
          return "./assets/icons/in_progress.png"
        } else if (adjudicator === "done") {
          return "./assets/icons/pass.png"
        } else {
          return "./assets/icons/fail.png"
        }
      }

      const renderProgress = action.payload.data.map((t) => {
        return {
          ...t,
          ssid: t.ssid,
          unassigned_readers: t.unassigned_readers,
          qa_status: t.qa_status,
          qa_status_icon: checkFailPass(t.qa_status),
          reader_1_status: t.reader_1_status,
          reader_1_status_icon: checkReader1(t.reader_1_status),
          reader_2_status: t.reader_2_status,
          reader_2_status_icon: checkReader2(t.reader_2_status),
          patient_secret_name: t.patient_secret_name,
          diagnosis_adjudicator_status: t.diagnosis_adjudicator_status,
          diagnosis_adjudicator_status_icon: checkAdjudicator(
            t.diagnosis_adjudicator_status
          ),
        }
      })

      return {
        ...state,
        progress: renderProgress,
        isFetching: action.isFetching,
        isInvalidated: action.isInvalidated,
        isEmpty: action.isEmpty,
        totalResult: action.payload.total_result,
      }
    }

    case GET_CURRENT_TASK:
      return {
        ...state,
        currentTask: Object.assign(state.currentTask, action.payload),
      }

    case SET_CURRENT_LINE_MEASUREMENT:
      return {
        ...state,
        currentLineMeasurement: action.payload,
      }

    case CLEAR_CURRENT_TASK_FORM:
      return {
        ...state,
        currentLineMeasurement: {},
        currentTask: {
          ...state.currentTask,
          form_fields: [],
          // annotation: {}
        },
      }

    case CLEAR_CURRENT_MEASUREMENT:
      return {
        ...state,
        currentLineMeasurement: {},
        currentTask: {
          ...state.currentTask,
        },
      }

    case SET_MATRIX_DATA:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          historyTable: action.payload,
        },
        isFetching: action.isFetching,
        isInvalidated: action.isInvalidated,
        isEmpty: action.isEmpty,
      }

    case GET_CURRENT_TASK_ENDPOINT: {
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          task_info: action.payload.task_info,
          form_fields: action.payload.json_schema_form.properties,
          token: action.payload.token,
          annotation: action.payload.annotation,
          previous_timepoints: action.payload.previous_timepoints,
          related_reader_tasks: action.payload.related_reader_tasks,
        },
      }
    }

    case GET_CURRENT_ADJ_TASK_ENDPOINT: {
      return {
        ...state,
        currentAdjTask: {
          ...state.currentAdjTask,
          task_info: action.payload.task_info,
          form_fields: action.payload.json_schema_form.properties,
          related_reader_tasks: action.payload.related_reader_tasks,
        },
      }
    }

    default:
      return {
        ...state,
      }
  }
}
