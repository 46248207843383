import {
  SITES_TABLE_FETCHING_DATA,
  SITES_TABLE_RECEIVE_DATA,
  SITES_TABLE_INVALIDATE_DATA,
  DATE_FORMAT,
} from "../constants/constants"
import { format } from "date-fns"

const formatReceiveData = (action) => {
  return action.payload.data.data.map((el) => ({
    id: el._id || " - ",
    name: el.name || " - ",
    subjects: el.target || " - ",
    invited_date: format(el.time_created.$date, `${DATE_FORMAT}`) || " - ",
    invited_timestamp: el.time_created.$date,
  }))
}

const initialState = {
  columns: [
    {
      Header: "Site Name",
      maxWidth: 550,
      accessor: "name",
      id: "name",
      show: true,
      cellIdPrefix: "site-name-",
      sortField: "name",
    },
    {
      Header: "Subjects",
      maxWidth: 100,
      accessor: "subjects",
      id: "subjects",
      show: true,
      cellIdPrefix: "site-subjects-",
      sortField: "subjects",
    },
    {
      Header: "Invited Date",
      accessor: "invited_date",
      id: "invited_date",
      maxWidth: 150,
      show: true,
      cellIdPrefix: "site-invited_date-",
      sortField: "invited_timestamp",
      aggregate: (vals) =>
        vals.filter((el, idx, ref) => ref.indexOf(el) === idx).join(","),
    },
  ],
  filterPanel: false,
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SITES_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case SITES_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    case SITES_TABLE_INVALIDATE_DATA:
      return {
        ...state,
        isInvalidated: true,
      }

    default:
      return {
        ...state,
      }
  }
}
