import * as React from "react"
import history from "../../../utils/history"

import { connect } from "react-redux"
import { sessionLogout } from "../../../actions/sessionAction"
import "./tabs.sass"

class CollapseItem extends React.Component<any, any> {
  state = {
    expanded: true,
  }

  _expanded = (ev) => {
    ev.preventDefault()
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }))
  }

  render() {
    const {
      item: { name, items },
    } = this.props

    const path = history.location.pathname
    return (
      <div>
        <div
          onClick={this._expanded}
          style={{
            width: "100%",
            display: "block",
          }}
        >
          {name}

          <i
            className="material-icons"
            style={{
              verticalAlign: "middle",
              float: "right",
            }}
          >
            {this.state.expanded ? "arrow_drop_down" : "arrow_drop_up"}
          </i>
        </div>

        {this.state.expanded && (
          <ul>
            {items.map((item, idx) => {
              const { name, url, externalURL } = item

              return (
                <li
                  className={`tab-link ${
                    url && path.includes(url) ? "is-active" : ""
                  }`}
                  key={`tab-${idx}`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={externalURL ? url : null}
                    onClick={() =>
                      !externalURL &&
                      this.props.pushTab(url, this.props.logoutHandler)
                    }
                  >
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    )
  }
}

class Tab extends React.Component<any, any> {
  // listen for changes in the route,
  // so that we can highlight the correct item in the sidebar
  unlisten = () => {}
  UNSAFE_componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      this.setState({ path: history.location.pathname })
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  pushTab = (url, logoutHandler) => {
    this.setState({ path: url })
    if (!url) {
      logoutHandler()
    }
    if (history.location.pathname !== url) {
      history.push(url)
    }
  }

  state = {
    path: history.location.pathname,
  }

  renderSidemenuCategory = (name, elements) => {
    const { path } = this.state
    const { sessionLogout } = this.props
    return (
      <React.Fragment>
        <p className="sidepanel-category">{name}</p>
        {elements.map((el, idx) => {
          const { name, url, externalURL, items } = el

          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={externalURL ? url : null}
              onClick={() => !externalURL && this.pushTab(url, sessionLogout)}
              className={url && path.includes(url) ? "is-active" : ""}
              key={`tab ${name}`}
            >
              <li className="tab-link">{name}</li>
            </a>
          )
        })}
      </React.Fragment>
    )
  }

  renderSidemenu = () => {
    const { dashboard, elements, sessionLogout } = this.props
    const { path } = this.state

    if (dashboard) {
      return elements.map((el, idx) => {
        const { name, url, externalURL, items } = el
        return (
          <li
            className={`tab-link ${
              url && path.includes(url) ? "is-active" : ""
            }`}
            key={`tab ${name}`}
          >
            {!items && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={externalURL ? url : null}
                onClick={() => !externalURL && this.pushTab(url, sessionLogout)}
              >
                {name}{" "}
                {externalURL ? (
                  <i className="material-icons sm-icon">open_in_new</i>
                ) : null}
              </a>
            )}

            {items && (
              <CollapseItem
                item={el}
                pushTab={this.pushTab}
                logoutHandler={sessionLogout}
              />
            )}
          </li>
        )
      })
    } else {
      return (
        <React.Fragment>
          {this.renderSidemenuCategory("Study", elements.study)}
          {elements.setup.length
            ? this.renderSidemenuCategory("Setup", elements.setup)
            : null}
        </React.Fragment>
      )
    }
  }

  render() {
    return (
      <nav className="side-menu">
        <ul>{this.renderSidemenu()}</ul>
      </nav>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  sessionLogout: () => dispatch(sessionLogout()),
})

export default connect(null, mapDispatchToProps)(Tab)
