import {
  SET_ACTIVATION_CODE,
  SET_DATA_FOR_REGISTRATION,
} from "../constants/constants"

const INITIAL_STATE = {
  data: {},
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DATA_FOR_REGISTRATION:
      return {
        data: action.payload,
      }
    case SET_ACTIVATION_CODE:
      return {
        data: {
          ...state.data,
          code: action.payload,
        },
      }
    default:
      return state
  }
}
