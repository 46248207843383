import { NotificationsActionTypes } from ".."

const enqueueSnackbar = (notification, type, timeout) => ({
  type: NotificationsActionTypes.ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message: notification,
    options: {
      variant: type,
      autoHideDuration: timeout,
    },
  },
})

export const hide = (key) => ({
  type: NotificationsActionTypes.REMOVE_SNACKBAR,
  key,
})

export const error =
  (message, timeout = 3000) =>
  (dispatch) =>
    dispatch(enqueueSnackbar(message, NotificationsActionTypes.ERROR, timeout))

export const success =
  (message, timeout = 3000) =>
  (dispatch) =>
    dispatch(
      enqueueSnackbar(message, NotificationsActionTypes.SUCCESS, timeout)
    )

export const warning =
  (message, timeout = 3000) =>
  (dispatch) =>
    dispatch(
      enqueueSnackbar(message, NotificationsActionTypes.WARNING, timeout)
    )

export default {
  success,
  error,
  warning,
  hide,
}
