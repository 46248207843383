import { SUBJECT_SET_CURRENT, SUBJECT_SET_DATA } from "../constants/constants"

const SUBJECT_INITIAL_STATE = {
  current: {},
  data: [],
}

export default function (state = SUBJECT_INITIAL_STATE, action) {
  switch (action.type) {
    case SUBJECT_SET_CURRENT:
      return { ...state, current: action.payload }
    case SUBJECT_SET_DATA:
      return { ...state, data: action.payload }
    default:
      return state
  }
}
