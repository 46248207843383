import CreateStudyModel from "../../../models/dashboard/create-study-model"
import * as React from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import { useSelector, useDispatch } from "react-redux"
import { NotificationsActions } from "../../../utils/Notifications/index"
import { useFormik } from "formik"
import * as Yup from "yup"

import { reduxForm, Field, change, reset } from "redux-form"
import { compose } from "recompose"
import { renderTextarea } from "../../common/render-textarea"
import "./edit-study-form.sass"
import Select from "@material-ui/core/Select"

import { createNewProject } from "../../../actions/ProjectListAction"
import { Grid } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"

import { renderAgeRadioButtons } from "../../common/render-radio-buttons"

import "./create-study-form.sass"
import { RootState } from "../../../reducers/mainReducer"
import { format, endOfDay } from "date-fns"
import { DATE_FORMAT } from "../../../constants/constants"

declare global {
  interface Date {
    parseDate(): string
  }
}

Date.prototype.parseDate = function () {
  const dateFormatted = this
  const day = dateFormatted.getDate()
  const month = dateFormatted.getMonth() + 1 // January is 0!
  const yyyy = String(dateFormatted.getFullYear())
  let dd = String(day)
  let mm = String(month)
  if (day < 10) {
    dd = "0" + String(day)
  }
  if (month < 10) {
    mm = "0" + String(month)
  }
  return yyyy + "-" + mm + "-" + dd
}

const CreateStudyForm: React.FC = (props: any) => {
  const [checked, checkedSet] = React.useState<boolean>(false)
  const [fromDate, fromDateSet] = React.useState<any>(new Date())
  const [toDate, toDateSet] = React.useState<any>(new Date())
  const [formHasErr, formHasErrSet] = React.useState(false)

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  React.useEffect(() => {
    dispatch(reset("createStudyForm"))
    const formInitial = {
      data_location: { value: "us", label: "USA" },
      anonym_level: { value: "strong", label: "Strong" },
      age_measure: { value: "month", label: "Months" },
      status: { value: "setup", label: "In Set Up" },
      name: "",
      from_date: fromDate,
      to_date: toDate,
      description: "",
      owner: state.session.user_data.name,
      creation_date: format(endOfDay(new Date()), `${DATE_FORMAT}`),
      num_readers: 0,
      num_diagnosis_adjudicators: 0,
      number_expected_tm_per_subject: 0,
    }
    props.initialize(formInitial)
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      // abbr: "",
      data_location: "",
      form_id: "",
      num_readers: "",
      num_diagnosis_adjudicators: "",
      number_expected_tm_per_subject: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 50 characters or less!")
        .required("Required!")
        .matches(
          /^[aA-zZ0-9\s]+$/,
          "Only letters and numbers are allowed for this field !"
        ),
      // abbr: Yup.string()
      //   .max(7, "Must be only 7 or less!")
      //   .required("Required!"),
      data_location: Yup.string().required("Required!"),
      form_id: Yup.string().required("Required!"),
      num_readers: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      num_diagnosis_adjudicators: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      number_expected_tm_per_subject: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: formHasErr,
    validateOnBlur: false,
    validateOnMount: false,
  })

  React.useEffect(() => {
    formHasErrSet(Object.keys(formik.errors).length !== 0)
  }, [formik.errors])

  const onSubmit = (values) => {
    const { form } = state
    const formValues = form.createStudyForm.values
    const params = {
      ...formValues,
      ...values,
      owner: state.session.user_data.name,
      status: formValues.status.value,
      to_date: toDate.parseDate(),
      from_date: fromDate.parseDate(),
      age_measure: formValues.age_measure.value,
      anonym_level: formValues.anonym_level.value,
      password_verify: checked ? 1 : 0
      // project_type: "central_reading",
    }

    const max_num_diagnosis_adjudicators = 1
    if (
      parseInt(params.num_diagnosis_adjudicators) >
      max_num_diagnosis_adjudicators
    ) {
      dispatch(
        NotificationsActions.warning(
          `The number of adjudicators must be between 0 and ${max_num_diagnosis_adjudicators}!`
        )
      )
    } else if (fromDate.getTime() > toDate.getTime()) {
      dispatch(
        NotificationsActions.warning(
          "The start date must be before the end date!"
        )
      )
    } else {
      if (!Object.keys(formik.errors).length) {
        dispatch(createNewProject(params))
      }
    }
  }

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkedSet(event.target.checked)
  }

  const _onChangeStartDate = (currentStartDate: any) => {
    fromDateSet(currentStartDate)
    dispatch(change("createStudyForm", "from_date", fromDate))
  }

  const _onChangeEndDate = (currentEndDate: any) => {
    if (currentEndDate.getTime() >= fromDate.getTime()) {
      toDateSet(currentEndDate)
      dispatch(change("createStudyForm", "to_date", toDate))
    }
  }

  const { submitting, form } = props

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className="formContainer">
          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={12}>
              <label>Study Name *</label>
              <input
                className={"input"}
                name="name"
                id="study_name"
                autoComplete="off"
                placeholder="Study Name e.g 'My Test Study'"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {((formik.touched.name && formik.errors.name) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.name}
                </p>
              )}
            </Grid>
          </Grid>

          {/* <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <label>Abbreviation *</label>
              <input
                className={"input"}
                maxLength={7}
                name="abbr"
                id="study_abbr"
                placeholder="Abbreviation e.g 'TEST'"
                type="text"
                value={formik.values.abbr}
                onChange={formik.handleChange}
              />
              {((formik.touched.abbr && formik.errors.abbr) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.abbr}
                </p>
              )}
            </Grid>
          </Grid> */}

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={12}>
              <label>Data Location *</label>
              <Select
                id={"study_data_location"}
                name={"data_location"}
                className=""
                defaultValue={CreateStudyModel.initialValues.data_location}
                style={{ width: "80%", height: "40px", background: "#fff" }}
                placeholder="Select one response"
                onChange={formik.handleChange}
              >
                <option value="" hidden>
                  {" "}
                  -- select an option --{" "}
                </option>
                {CreateStudyModel.options.data_location.map((value, index) => {
                  return (
                    <option key={index} value={`${value.value}`}>
                      {value.label}
                    </option>
                  )
                })}
              </Select>
              {((formik.touched.data_location && formik.errors.data_location) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.data_location}
                </p>
              )}
              <div>
                <br />
                <label className="description-text">
                  The generic study's data location won't be changable after
                  completing this step
                </label>
              </div>
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={12}>
              <label>Form Type *</label>
              <Select
                id={"study_form_id"}
                name={"form_id"}
                className=""
                defaultValue={""}
                style={{ width: "80%", height: "40px", background: "#fff" }}
                placeholder="Select one form type"
                onChange={formik.handleChange}
              >
                <option value="" hidden>
                  {" "}
                  -- select an option --{" "}
                </option>
                {state.project_config?.formTypes?.map((value, index) => {
                  return (
                    <option key={index} value={`${value[1]}`}>
                      {value[0]}
                    </option>
                  )
                })}
              </Select>
              {((formik.touched.form_id && formik.errors.form_id) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.form_id}
                </p>
              )}
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item>
              <label>Age units *</label>
              <Field
                name="age_measure"
                id="study_age_measure"
                data={CreateStudyModel.options.age_measure}
                component={renderAgeRadioButtons}
                editStudyForm={form.createStudyForm}
                defaultValue="month"
              />
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <div className="control has-icons-right">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    name="from_date"
                    id="from_date"
                    label="Start Date *"
                    format="yyyy-MM-dd"
                    value={fromDate}
                    onChange={_onChangeStartDate}
                    disablePast={true}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="control has-icons-right">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="normal"
                    name="to_date"
                    id="to_date"
                    label="End Date *"
                    format="yyyy-MM-dd"
                    value={toDate}
                    onChange={_onChangeEndDate}
                    disablePast={true}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={12}>
              <label>Description</label>
              <Field
                data-gramm="false"
                className={"textarea"}
                name="description"
                id="study_description"
                placeholder="Desciption of the study (max. 240 characters)"
                component={renderTextarea}
              />
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <label>Number of readers *</label>
              <input
                className={"input"}
                maxLength={2}
                name="num_readers"
                id="study_num_readers"
                type="number"
                value={formik.values.num_readers}
                onChange={formik.handleChange}
              />
              {((formik.touched.num_readers && formik.errors.num_readers) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.num_readers}
                </p>
              )}
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <label>Number of diagnosis adjudicators *</label>
              <input
                className={"input"}
                maxLength={2}
                name="num_diagnosis_adjudicators"
                id="study_num_diagnosis_adjudicators"
                type="number"
                value={formik.values.num_diagnosis_adjudicators}
                onChange={formik.handleChange}
              />
              {((formik.touched.num_diagnosis_adjudicators &&
                formik.errors.num_diagnosis_adjudicators) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.num_diagnosis_adjudicators}
                </p>
              )}
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <label>Number of expected timepoints per subject *</label>
              <input
                className={"input"}
                maxLength={2}
                name="number_expected_tm_per_subject"
                id="study_number_expected_tm_per_subject"
                type="number"
                value={formik.values.number_expected_tm_per_subject}
                onChange={formik.handleChange}
              />
              {((formik.touched.number_expected_tm_per_subject &&
                formik.errors.number_expected_tm_per_subject) ||
                !formik.isSubmitting) && (
                <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                  {formik.errors.number_expected_tm_per_subject}
                </p>
              )}
            </Grid>
          </Grid>

          <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={6}>
              <label>e-signature / password verify</label>
              <Checkbox
                checked={checked}
                onChange={handleChangeCheck}
                color="primary"
                name="password_verify"
                id="study_password_verify"
                inputProps={{ "aria-label": "secondary checkbox" }}
                style={{ marginBottom: "26px" }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="buttonsContainer">
          <button
            className={"btn quaternary is-block btn"}
            onClick={(e) => {
              e.preventDefault()
              props.history.goBack()
            }}
          >
            Cancel
          </button>
          <button
            className={
              Object.keys(formik.errors).length
                ? "btn inactive denial"
                : "btn primary is-block"
            }
            type="submit"
            disabled={submitting}
          >
            Create Study
          </button>
        </Grid>
      </form>
    </>
  )
}

export default compose(
  reduxForm({
    form: "createStudyForm",
  })
)(CreateStudyForm)
