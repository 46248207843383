import * as React from "react"
import { connect } from "react-redux"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import "./delete-modal.sass"
import { compose } from "recompose"
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid } from "@material-ui/core"
import { verifyPassword } from "../../../actions/CentralReviewAction"
import history from "./../../../utils/history"

const styles = {
  buttonContainer: {
    justifyContent: "space-between",
    padding: "0 16px 16px",
  },
}

class SubmitModal extends React.Component<any, any> {
  handleSubmit(event) {
    event.preventDefault()
    const data = new FormData(event.target)
    this.props.verifyPassword(
      { password: data.get("password") },
      this.props,
      history
    )
  }

  render() {
    const { classes } = this.props

    return (
      <Dialog open={this.props.open} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          {this.props.title} &nbsp;
          {this.props.CentralReview.isFetching ? (
            <img
              className="base-margin-half"
              width="24px"
              height="24px"
              src="assets/icons/loading-image.gif"
              alt="loading"
            />
          ) : null}
        </DialogTitle>
        <DialogContent>
          <p>The current Project is configured to use e-signature</p>
          <p>
            Please confirm this action with the username and password of your
            QMENTA account
          </p>
          <Grid container className="submit-container">
            <Grid item xs={6} sm={6} md={6}>
              <h4>Subject ID</h4>
              <h3>
                <b>{this.props.subjectId}</b>
              </h3>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <h4>Session ID</h4>
              <h3>
                <b>{this.props.sessionId}</b>
              </h3>
            </Grid>
          </Grid>
          <form
            id="very_password"
            className="submit-signature"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="field column">
              <label>Email or Username</label>
              <div>
                <input
                  placeholder={"Email or username"}
                  type={"text"}
                  className={"input"}
                  value={this.props.session.user_data.username}
                  disabled
                />
              </div>
            </div>
            <div className="field column">
              <label>Password</label>
              <div>
                <input
                  placeholder={"Password"}
                  name={"password"}
                  type={"password"}
                  className={"input"}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <button
            id="delete-modal-cancel"
            onClick={this.props.onClose}
            color="primary"
            className={
              this.props.disabled ? "btn quaternary inactive" : "btn quaternary"
            }
            disabled={this.props.disabled ? this.props.disabled : undefined}
          >
            Cancel
          </button>
          <button
            form="very_password"
            type="submit"
            color="primary"
            className={
              this.props.disabled ? "btn denial inactive" : "btn primary"
            }
            disabled={this.props.disabled ? this.props.disabled : undefined}
          >
            {this.props.submitButton}
          </button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ session, CentralReview }) => ({
  session,
  CentralReview,
})

const mapDispatchToProps = (dispatch) => {
  return {
    verifyPassword: (data, props, history) =>
      dispatch(verifyPassword(data, props, history)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SubmitModal)
