import * as React from "react"
import { compose } from "recompose"
/* REFACTOR TABLE PENDING */
// import Table from "../../components/common/Table/table";
import { connect } from "react-redux"
import { setCurrentProjectId } from "../../actions/sessionAction"
import "./collaborators-page.sass"
import { setCurrentCollaborator } from "../../actions/collaboratorsAction"
import { removeCollaborator } from "../../actions/collaboratorsTable"
import EmptyTableMessage from "../../utils/empty-table-message"

class CollaboratorsPage extends React.Component<any, any> {
  _deleteCollaborator(user_id) {
    return this.props.removeCollaborator({
      _pid: this.props.current.project.data._id,
      user_id,
    })
  }

  _getDeleteId(row) {
    return `collaborator-delete-button--user_id-${row.user_id}`
  }

  _getEditId(row) {
    return `collaborator-edit-button--user_id-${row.user_id}`
  }

  redirectToEditCollaborator(row) {
    const currentCollaborator = this.props.current.project.data.users.filter(
      (collaborator) => {
        return collaborator.user_id === row.user_id
      }
    )
    this.props.setCurrentCollaborator(currentCollaborator[0])

    this.props.history.push("collaborators/" + row.user_id + "/edit")
  }

  setCurrentCollaborator(row) {
    const value = {
      ...row,
      role_short_name: this.props.current.project.data.users.reduce(
        (acc, curr) => {
          if (row.user_id === curr.user_id) {
            acc = curr.access.slice(-1)[0]
          }
          return acc
        },
        {}
      ),
    }
    this.props.setCurrentCollaborator(value)
  }

  render() {
    const { collaboratorsTable } = this.props
    return (
      <div className="container">
        <h1>
          Collaborators &nbsp;
          {this.props.projects.isFetching ? (
            <img
              className="base-margin-half"
              width="24px"
              height="24px"
              src="assets/icons/loading-image.gif"
              alt="loading"
            />
          ) : null}
        </h1>
        <div className="button-row">
          <button
            className="btn primary"
            onClick={() => this.props.history.push("collaborators/add")}
          >
            <span>+ Add Collaborator</span>
          </button>
        </div>
        {/* <Table
                    resizable={false}
                    sortable={false}
                    withSelection={false}
                    filterable={false}
                    dynamicColumn={false}
                    hasRoles={true}
                    getRowOnClicked={row => this.setCurrentCollaborator(row)}
                    hide={collaboratorsTable.empty}
                    columns={collaboratorsTable.columns}
                    pages={collaboratorsTable.page.pages}
                    loading={collaboratorsTable.isFetching}
                    data={this.props.current.project.data.users}
                    config={this.props.project_config}
                    hidePagination={true}

                    // source={{
                    //     model: collaboratorsTable,
                    //     onFetchData: this._fetchData(this.props.match.params.project_id)
                    // }}

                    actions={{
                        onDelete: (row) => this._deleteCollaborator(row.user_id),
                        getDeleteId : (row) => this._getDeleteId(row),
                        onEdit: (row) => this.redirectToEditCollaborator(row),
                        getEditId: (row) => this._getEditId(row)
                    }}

                    deleteModalSubmitButton={"Remove Collaborator"}
                    deleteModalTitle={"Remove Collaborator"}
                    deleteModalDescription={"You are about to remove a collaborator from your study. Are you sure you want to remove this collaborator?"}
                    tableId="collaborators_table"
                /> */}
        <div
          className="align-center"
          style={
            collaboratorsTable.empty
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <EmptyTableMessage
            fetching={collaboratorsTable.isFetching}
            title="collaborators list"
            text={`Please add a collaborator on the "+ ADD COLLABORATOR" button on the
            top left of the screen`}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentProjectId: (id) => dispatch(setCurrentProjectId(id)),
    removeCollaborator: (val) => dispatch(removeCollaborator(val)),
    setCurrentCollaborator: (val) => dispatch(setCurrentCollaborator(val)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CollaboratorsPage
)
