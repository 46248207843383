import {
  RECEIVE_PROJECT_CONFIG,
  RECEIVE_FORMATED_PROJECT_CONFIG,
  GET_FORM_TYPES,
} from "../constants/constants"

const PROJECTS_INITIAL_STATE = {
  roles: [],
  data: {},
  formTypes: [],
  isFetched: false,
}

export default function (state = PROJECTS_INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVE_PROJECT_CONFIG:
      return {
        ...state,
        data: action.payload,
        isFetched: true,
      }

    case RECEIVE_FORMATED_PROJECT_CONFIG:
      return {
        ...state,
        roles: action.roles,
      }

    case GET_FORM_TYPES:
      return {
        ...state,
        formTypes: action.payload
      }

    default:
      return {
        ...state,
      }
  }
}
