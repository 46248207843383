import { SET_CURRENT_SITE, SET_SITES } from "../constants/constants"

const SITES_INITIAL_STATE = {
  current: {},
  data: [],
}

export default function (state = SITES_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_SITE:
      return {
        ...state,
        current: action.payload,
      }
    case SET_SITES:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
