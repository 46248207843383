import {
  PROJECT_LIST_RECEIVE_DATA,
  PROJECT_LIST_CLEAR_LIST,
  PROJECT_LIST_FETCHING_DATA,
} from "../constants/constants"

const checkQC = (project) => {
  return project.sites?.some((site) =>
    site.uploaded_data ? site.uploaded_data.fail > 0 : null
  )
}

const formatReceiveData = (action) => {
  const projects = action.payload.data.filter((project) => {
    return (
      project.project_type === "central_reading" ||
      project.project_type === "clinical_trial"
    )
  })

  const roles = action.payload.roles
  const data = projects.map((el) => {
    const roleIdx = roles.findIndex((role) => role.project === el.name)
    const QC = checkQC(el)

    return {
      state: el.state,
      status: el.state === "setup" ? "In " + el.state : el.state,
      name: el.name,
      qc: QC,
      my_access: roleIdx in roles ? roles[roleIdx].role : "",
      uniqid: +new Date(),
      id: el._id,
    }
  })

  return data
}

const initialState = {
  columns: [
    {
      Header: "Site Name",
      maxWidth: 550,
      accessor: "name",
      id: "name",
      show: true,
      cellIdPrefix: "site-name-",
      sortField: "name",
    },
    {
      Header: "Subjects",
      maxWidth: 100,
      accessor: "subjects",
      id: "subjects",
      show: true,
      cellIdPrefix: "site-subjects-",
      sortField: "subjects",
    },
    {
      Header: "Invited Date",
      accessor: "invited_date",
      id: "invited_date",
      maxWidth: 150,
      show: true,
      cellIdPrefix: "site-invited_date-",
      sortField: "invited_timestamp",
      aggregate: (vals) =>
        vals.filter((el, idx, ref) => ref.indexOf(el) === idx).join(","),
    },
  ],
  filterPanel: false,
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROJECT_LIST_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case PROJECT_LIST_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    case PROJECT_LIST_CLEAR_LIST:
      return {
        ...state,
        data: [],
      }

    default:
      return {
        ...state,
      }
  }
}
