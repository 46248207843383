import * as React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import "./invite-collaborator-modal.sass"
import { compose } from "recompose"
import { connect } from "react-redux"
import { inviteUserByEmail } from "../../../actions/apiAction"
import { openCloseInviteModal } from "../../../actions/collaboratorsAction"
import { withSnackbar } from "notistack"

class InviteCollaboratorModal extends React.Component<any, any> {
  sendInvitation() {
    const { collaborators } = this.props
    const data = { email: collaborators.current.user_id }
    this.props
      .inviteUserByEmail(data)
      .then((result) => {
        this.props.enqueueSnackbar("Email was sent to new user!", {
          variant: "success",
        })
        this.props.openCloseInviteModal(false)
        this.props.history.goBack()
      })
      .catch((error) => {
        this.props.openCloseInviteModal(false)
        return error
      })
  }

  render() {
    const { fullScreen, collaborators } = this.props

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Invite user"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Invite this email to registration?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.props.openCloseInviteModal(!collaborators.inviteModal)
              }
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={() => this.sendInvitation()} color="default">
              Invite user
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => {
  return {
    openCloseInviteModal: (val) => dispatch(openCloseInviteModal(val)),
    inviteUserByEmail: (val) => dispatch(inviteUserByEmail(val)),
  }
}

export default compose(
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps)
)(InviteCollaboratorModal)
