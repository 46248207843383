import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { compose } from "recompose"
import { connect } from "react-redux"
import { format } from "date-fns"
import "./study-info-card.sass"
import { Link } from "react-router-dom"
import { DATE_FORMAT } from "../../../constants/constants"

const styles: any = {
  card: {
    minWidth: 275,
  },
  subTitle: {
    color: "#279181",
    fontSize: "18px",
  },

  descriptionContainer: {
    margin: "10px 0px",
    padding: "10px 0px",
    borderBottom: "1px solid #CCC",
  },

  studyStatus: {
    textTransform: "capitalize",
  },

  keyColumn: {
    color: "#666",
  },
  descKey: {
    margin: "0",
  },
}

class StudyInfoCard extends React.Component<any, any> {
  createInfoData(project) {
    return {
      ...project,
      state: project.state,
      to_date: project.to_date
        ? format(project.to_date.$date, `${DATE_FORMAT}`)
        : "",
      estimated_enrollment:
        project.sites.length !== 0
          ? project.sites.reduce((acc, curr) => (acc += curr.target), 0)
          : 0,
    }
  }

  render() {
    const { classes, projectData } = this.props
    const projectInfo = this.createInfoData(projectData)

    return (
      <Card className={classes.card}>
        <CardContent>
          <h3 className="weight-medium">Study details</h3>

          <div className={classes.cardInfoContainer}>
            <div className="info-container">
              <p className={classes.keyColumn}>Name</p>
              <p id="study_info_card.abreviation">{projectInfo.name}</p>
            </div>

            {/* <div className="info-container">
              <p className={classes.keyColumn}>Abbreviation</p>
              <p
                id="study_info_card.abreviation"
                className={classes.valueColumn}
              >
                {projectInfo.abbreviation}
              </p>
            </div> */}

            <div className="info-container">
              <p className={classes.keyColumn}>Study ID</p>
              <p id="study_info_card.study_id" className={classes.valueColumn}>
                {projectInfo._id}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Study Status</p>
              <div
                id="study_info_card.study_status"
                className={
                  "is-" + projectInfo.state + " " + classes.studyStatus
                }
              >
                <span>
                  {projectInfo.state === "setup"
                    ? "In " + projectInfo.state
                    : projectInfo.state}
                </span>
              </div>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Owner</p>
              <p id="study_info_card.owner" className={classes.valueColumn}>
                {projectInfo.user_id}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Data location</p>
              <p id="study_info_card.study_id" className={classes.valueColumn}>
                {projectInfo.config.data_location}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Age units</p>
              <p id="study_info_card.age_units" className={classes.valueColumn}>
                {projectInfo.config.age_measure}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Start Date</p>
              <p id="study_info_card.from_date" className={classes.valueColumn}>
                {format(projectInfo.from_date.$date, `${DATE_FORMAT}`)}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>End Date</p>
              <p id="study_info_card.to_date" className={classes.valueColumn}>
                {projectInfo.to_date}
              </p>
            </div>

            <div className={classes.descriptionContainer}>
              <p className={classes.keyColumn + " " + classes.descKey}>
                Description
              </p>
              <p className={"info-description"}>
                {projectInfo.description === ""
                  ? "No description"
                  : projectInfo.description}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Number of readers</p>
              <p
                id="study_info_card.num_readers"
                className={classes.valueColumn}
              >
                {projectInfo.config.num_readers}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Number of adjudicators</p>
              <p
                id="study_info_card.num_diagnosis_adjudicators"
                className={classes.valueColumn}
              >
                {projectInfo.config.num_diagnosis_adjudicators}
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>
                Number of expected timepoints per subject
              </p>
              <p
                id="study_info_card.number_expected_tm_per_subject"
                className={classes.valueColumn}
              >
                {projectInfo.config.number_expected_tm_per_subject}
              </p>
            </div>
          </div>

          {this.props.session.user_data.isManager && (
            <div className="button-right-container">
              <Link to={"information/edit"}>
                <button className="ghost-btn">
                  <i className="material-icons clickable">edit</i>
                  Edit study information
                </button>
              </Link>
            </div>
          )}
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = ({ current, session }) => {
  return { current, session }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(StudyInfoCard)
