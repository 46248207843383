import "./central-review-status.sass"

import * as React from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { NotificationsActions } from "./../../../utils/Notifications/index"
import Tooltip from "@material-ui/core/Tooltip"
import { compose } from "recompose"
import { connect } from "react-redux"
import { getWorklist } from "../../../actions/CentralReviewAction"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  card: {
    minWidth: 275,
    marginBottom: "20px",
  },
  subTitle: {
    color: "#279181",
    fontSize: "18px",
  },
  descriptionContainer: {
    marginTop: "20px",
  },
  rejected: {
    backgroundColor: "#9B1A06",
    color: "#FFFFFF",
    width: "61px",
    height: "24px",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  open: {
    width: "61px",
    height: "24px",
    backgroundColor: "#3c74b8",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  completed: {
    width: "61px",
    height: "24px",
    backgroundColor: "#2ca895",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  total: {
    width: "61px",
    height: "24px",
    backgroundColor: "#292929",
    color: "#FFFFFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  keyColumn: {
    color: "#666",
  },
  keyTotal: {
    fontWeight: 500,
  },
}

const toolTipText =
  "Quality control (QC) is a standard which is used to conduct a study determined by research requirements"

class CentralReviewStatus extends React.Component<any, any> {
  componentDidMount(): void {
    const data = { projectset_id: this.props.current.project.data._id }
    if (!this.props.session.user_data.isManager) this.props.getWorklist(data)
    if (this.props.session.user_data.isManager) this.props.notification403()
  }

  render() {
    const { classes } = this.props
    const qcData = this.props.CentralReview.workList.reduce(
      (acc, current) => {
        if (current.status) {
          acc.open += current.status === "open"
          acc.completed += current.status === "done"
          acc.done += current.status === "rejected"
          acc.total = this.props.CentralReview.workList.length
        }

        return acc
      },
      {
        open: 0,
        completed: 0,
        rejected: 0,
        total: this.props.CentralReview.workList.length,
      }
    )

    return (
      <Card className={classes.card}>
        <CardContent>
          <h3 className="weight-medium">
            Central Review Status&nbsp;
            {/* tooltip placement doesn't work the same way as the docs described
                            bottom-start and bottom-end seem to be reversed
                            https://material-ui.com/components/tooltips/#positioned-tooltips
                        */}
            <Tooltip title={toolTipText} placement="bottom-start">
              <i className="material-icons sm-icon">help</i>
            </Tooltip>
          </h3>

          <div className={classes.cardInfoContainer}>
            <div className="info-container">
              <p className={classes.keyColumn}>Open</p>
              <div className={classes.open}>
                <span id="study_qc_card.qc_pass">{qcData.open}</span>
              </div>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Completed</p>
              <p className={classes.completed}>
                <span id="study_qc_card.no_qc">{qcData.completed}</span>
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn}>Rejected</p>
              <p className={classes.rejected}>
                <span id="study_qc_card.qc_fail">{qcData.rejected}</span>
              </p>
            </div>

            <div className="info-container">
              <p className={classes.keyColumn + " " + classes.keyTotal}>
                Total projected
              </p>
              <p className={classes.total}>
                <span id="study_qc_card.qc_fail">{qcData.total}</span>
              </p>
            </div>
          </div>

          <div className="button-right-container">
            {!this.props.session.user_data.isManager && (
              <button
                id="study_activity_card.view_activity_log_button"
                className="ghost-btn"
                onClick={() => this.props.history.push("worklist")}
              >
                <i className="material-icons">visibility</i>
                View worklist
              </button>
            )}
          </div>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = ({ current, CentralReview, session }) => ({
  current,
  CentralReview,
  session,
})
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (id) => dispatch(getWorklist(id)),
    notification403: () =>
      dispatch(
        NotificationsActions.warning("You are not part of this project!")
      ),
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CentralReviewStatus)
