import "./sidepanel.sass"

import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { DashboardSidePanel } from "../DashboardSidePanel"
import { ProjectSidePanel } from "../ProjectSidePanel"
import { updateUserSessionAccess } from "../../../actions/sessionAction"
import { useFlags } from "launchdarkly-react-client-sdk"

const SidePanel = (props) => {
  const [elementsDashboard, setElementsDashboard] = useState([
    { url: "/project", id: 1, name: "My studies" },
    {
      url: "https://qmenta.atlassian.net/wiki/spaces/QTG/pages/1176502459/Getting+started",
      id: 4,
      name: "Documentation",
      externalURL: true,
    },
    {
      url: process.env.REACT_APP_PROFILE_URL,
      id: 5,
      name: "My Account",
      externalURL: true,
    },
    { url: "", id: 6, name: "Logout" },
  ])

  const flags = useFlags()
  const state = useSelector((state) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (state.current.project.data.users && state.session.user_data.username) {
      return dispatch(
        updateUserSessionAccess(
          state.current.project.data.users.find(
            (user) => user.user_id === state.session.user_data.username
          )
        )
      )
    }
  }, [state.current.project.data.users])

  const elementsProject = (currProj) => {
    const setupElements = []
    if (flags.sitesAndCollaborators) {
      if (state.session.user_data.isManager) {
        setupElements.push(
          {
            url: `/project/${currProj}/information/edit`,
            id: 4,
            name: "Information",
          },
          { url: `/project/${currProj}/sites`, id: 6, name: "Sites" },
          {
            url: `/project/${currProj}/collaborators`,
            id: 7,
            name: "Collaborators",
          }
        )
      }
    }
    /* Pushing elements into an array. */
    if (flags.protocolSetup) {
      setupElements.push({
        url: `/project/${currProj}/protocol`,
        id: 8,
        name: "Protocol / QC",
      })
    }
    const studyElements = [
      { url: `/project/${currProj}/overview`, id: 1, name: "Overview" },
    ]
    if (flags.iscentralreview) {
      switch (true) {
        case state.session.user_data.isManager:
          // isManager
          studyElements.push({
            url: `/project/${currProj}/monitoring`,
            id: 9,
            name: "Monitoring",
          })
          break
        default:
          // isReader
          studyElements.push({
            url: `/project/${currProj}/worklist`,
            id: 2,
            name: "Worklist",
          })
          break
      }
    } else {
      studyElements.push()
      // studyElements.push(
      // 	{ url: `/project/${currProj}/data`, id: 3, name: 'Sessions' },
      // 	{ url: `/project/${currProj}/study-monitoring`, id: 4, name: 'Progress monitoring' },
      // )
    }
    if (flags.activityLog && state.session.user_data.isManager) {
      studyElements.push({
        url: `/project/${currProj}/activity`,
        id: 5,
        name: "Activity log",
      })
    }
    const elements = {
      study: studyElements,
      setup: setupElements,
    }

    return elements
  }

  return (
    <Switch>
      <Route
        path={"/project"}
        exact
        render={() => (
          <DashboardSidePanel elementsDashboard={elementsDashboard} />
        )}
      />
      <Route
        path={"/project/create"}
        exact
        render={() => (
          <DashboardSidePanel elementsDashboard={elementsDashboard} />
        )}
      />

      <Route
        path={"/project"}
        render={() => (
          <ProjectSidePanel
            elementsProject={elementsProject(
              props.location.pathname.split("/")[2]
            )}
          />
        )}
      />

      <Route
        render={() => (
          <DashboardSidePanel elementsDashboard={elementsDashboard} />
        )}
      />
    </Switch>
  )
}

export default SidePanel
