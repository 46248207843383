import * as React from "react"
import CreateStudyForm from "../../components/forms/dashboard/create-study-form"
import "./create-new-project.sass"
import { Grid } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { getFormTypes } from "../../actions/apiAction"
import { RootState } from "../../reducers/mainReducer"

const CreateNewProject: React.FC = (props: any) => {

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  React.useEffect(() => {
    dispatch(getFormTypes())
  }, [])

  return (
    <div className="containerForm">
      <h1 className="h1">Create Study</h1>
      <Grid item xs={12} sm={6} md={6}>
        <CreateStudyForm {...props} />
      </Grid>
    </div>
  )
}

export default CreateNewProject
