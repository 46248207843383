import {
  SET_CAN_UPLOAD_DATA,
  SET_CURRENT_ANALYSIS,
  SET_CURRENT_BREADCRUMB,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_ACTIVITY_LOG,
  SET_CURRENT_PROJECT_FETCHING,
  UPDATE_TAGS,
  SUCCESS_CHANGE_PROJECT,
} from "../constants/constants"

const CURRENT_INITIAL_STATE = {
  breadcrumb: "project",
  can_upload_data: false,
  project: {
    data: {
      abbreviation: "",
      analysis_credits: null,
      description: "",
      config: {
        data_location: "us",
      },
      free_credits: null,
      from_date: { $date: 1551398400000 },
      has_analysis_credits: null,
      message: "",
      name: "",
      owner_name: "",
      project_type: "",
      published: 0,
      roles: [],
      state: "",
      success: null,
      sites: [],
      tags: [],
      to_date: { $date: 1561939200000 },
      user_id: "",
      _id: null,
    },
    analysis: {
      qc_status: "",
      state: "",
      analysis_list: {
        tags: [],
      },
      loaded: false,
    },
    activity_log: [],
    isFetching: false,
    isInvalidated: false,
  },
}

export default function (state = CURRENT_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload,
      }

    case SET_CAN_UPLOAD_DATA:
      return {
        ...state,
        can_upload_data: action.payload,
      }

    case SET_CURRENT_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          data: {
            ...action.payload,
          },
          isInvalidated: false,
          isFetching: false,
        },
      }

    case SET_CURRENT_PROJECT_FETCHING:
      return {
        ...state,
        project: {
          ...state.project,
          isFetching: action.payload,
        },
      }

    case SUCCESS_CHANGE_PROJECT: {
      return {
        ...state,
        project: {
          ...state.project,
          isInvalidated: true,
        },
      }
    }
    case SET_CURRENT_PROJECT_ACTIVITY_LOG: {
      return {
        ...state,
        project: {
          ...state.project,
          activity_log: action.payload,
        },
      }
    }
    case SET_CURRENT_ANALYSIS: {
      return {
        ...state,
        project: {
          ...state.project,
          analysis: { ...action.payload, loaded: action.loaded },
        },
      }
    }
    case UPDATE_TAGS: {
      return {
        ...state,
        project: {
          ...state.project,
          analysis: {
            ...state.project.analysis,
            analysis_list: {
              ...state.project.analysis.analysis_list,
              tags: action.payload,
            },
          },
        },
      }
    }
    default:
      return state
  }
}
