import * as React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import "./delete-modal.sass"
import { compose } from "recompose"
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
  buttonContainer: {
    justifyContent: "space-between",
    padding: "0 16px 16px",
  },
  name: {
    display: "flex",
    "justify-content": "space-between",
    background: "#f2f2f2",
  },
  nameField: {
    cursor: "pointer",
  },
}

const AddUserModal: React.FC = (props: any) => {
  const [assignSelectedId, assignSelectedIdSet] = React.useState()

  const close = (res: any) => {
    props.onClose(res)
  }

  const selectUser = (e) => {
    assignSelectedIdSet(props.listOfUsers[e.target.id])
  }

  const { classes } = props

  return (
    <Dialog open={props.open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            maxHeight: "300px",
            minWidth: "200px",
            overflowY: "scroll",
          }}
        >
          {props.listOfUsers.map((value, index) => {
            return (
              <span style={{ display: "block" }} key={index}>
                <span
                  id={index}
                  className={classes.nameField}
                  onClick={(e) => selectUser(e)}
                >
                  {value}
                </span>
                {assignSelectedId === value ? (
                  <img
                    alt="pass"
                    style={{ float: "right", width: "25px", height: "auto" }}
                    src="./assets/icons/pass.png"
                  />
                ) : (
                  <></>
                )}
              </span>
            )
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <button
          id="delete-modal-cancel"
          onClick={close}
          color="primary"
          className={
            props.disabled ? "btn quaternary inactive" : "btn quaternary"
          }
          disabled={props.disabled ? props.disabled : undefined}
        >
          Cancel
        </button>
        <button
          id="delete-modal-confirm"
          onClick={() => props.onSubmitAssignModal(assignSelectedId)}
          color="primary"
          className={props.disabled ? "btn denial inactive" : "btn primary"}
          disabled={props.disabled ? props.disabled : undefined}
        >
          {props.submitButton}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default compose(withStyles(styles))(AddUserModal)
