import { combineReducers } from "redux"
import sessionReducer from "./sessionReducer"
import currentReducer from "./currentReducer"
import ProjectListReducer from "./ProjectListReducer"
import { reducer as reduxFormReducer } from "redux-form"
import projectConfigReducer from "./projectConfigReducer"
import sitesReducer from "./sitesReducer"
import sessionsTableReducer from "./sessionTable"
import activityTableReducer from "./activityTable"
import sitesTableReducer from "./sitesTable"
import collaboratorsTableReducer from "./collaboratorsTable"
import collaboratorsReducer from "./collaboratorsReducer"

import { NotificationsReducer } from "../utils/Notifications/index"
import subjectTable from "./subjectTable"
import subject from "./subject"
import sessionProfileTable from "./sessionProfileTable"
import qcHistoryTableReducer from "./qcHistoryTable"
import ProgressMonitoringTableReducer from "./progressMonitoringTable"
import registrationReducer from "./registration"

import patientData from "./patientData"
import protocols from "./protocols"
import analysisInfoTable from "./analysisInfoTable"
import listResultFilesTable from "./listResultFilesTable"
import listResultFiles from "./listResultFiles"
import analysisHistoryTable from "./analysisHistoryTable"

import CentralReview from "./CentralReviewReducer"

export const rootReducer = () =>
  combineReducers({
    form: reduxFormReducer,
    session: sessionReducer,
    current: currentReducer,
    project_config: projectConfigReducer,
    sites: sitesReducer,
    collaborators: collaboratorsReducer,
    projects: ProjectListReducer,
    sessionTable: sessionsTableReducer,
    sessionProfileTable,
    sitesTable: sitesTableReducer,
    subjectTable,
    subject,
    collaboratorsTable: collaboratorsTableReducer,
    activityTable: activityTableReducer,
    protocols,
    notifications: NotificationsReducer,
    patientData,
    qcHistoryTable: qcHistoryTableReducer,
    progressMonitoringTable: ProgressMonitoringTableReducer,
    analysisInfoTable,
    registration: registrationReducer,
    listResultFiles: listResultFilesTable,
    listResult: listResultFiles,
    analysisHistory: analysisHistoryTable,
    CentralReview,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
