import React from "react"
import { Logo } from "./Logo"
import Tabs from "./dashboard/tabs"
export const DashboardSidePanel = ({ elementsDashboard }) => (
  <aside className={"dashboard-side-panel"}>
    <Logo color={"#FFFFFF"} />

    <Tabs elements={elementsDashboard} dashboard={true} />
  </aside>
)
