import { NotificationsActions } from "../utils/Notifications/index"
import { getAnalysisProgress } from "./apiAction"
import { PROGRESS_MONITORING_TABLE_RECEIVE_DATA } from "../constants/constants"

export const fetchProgress = (projectId, pageParams = null) => {
  return (dispatch) => {
    return dispatch(getAnalysisProgress(projectId, pageParams))
      .then((response) => {
        const empty = response.data ? response.data.length === 0 : true
        return dispatch({
          type: PROGRESS_MONITORING_TABLE_RECEIVE_DATA,
          payload: response,
          emptyTable: empty,
        })
      })
      .catch(() => {
        dispatch(NotificationsActions.error("Something went wrong!"))
      })
  }
}
