import {
  OPEN_CLOSE_INVITE_MODAL,
  SET_CURRENT_COLLABORATOR,
} from "../constants/constants"

const SITES_INITIAL_STATE = {
  current: {},
  inviteModal: false,
}

export default function (state = SITES_INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_COLLABORATOR:
      return {
        ...state,
        current: action.payload,
      }
    case OPEN_CLOSE_INVITE_MODAL:
      return {
        ...state,
        inviteModal: action.payload,
      }
    default:
      return state
  }
}
