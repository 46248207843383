import * as React from "react"

export const renderField = ({
  input,
  label,
  className,
  disabled,
  placeholder,
  maxLength,
  onInput,
  submitOnEnter,
  type,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className={touched && error ? className + " is-danger" : className}
          disabled={disabled}
          maxLength={maxLength}
          onInput={onInput}
          onKeyPress={(e) => {
            if (type === "integer" && isNaN(Number(e.key))) {
              e.preventDefault()
            }

            if (e.key === "Enter" && !submitOnEnter) {
              e.preventDefault()
            }
          }}
        />
        {touched && error && <p className="help is-danger">{error}</p>}
      </div>
    </div>
  )
}
