import CreateStudyModel from "../../../models/dashboard/create-study-model"
import * as React from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import { format } from "date-fns"
import { useSelector, useDispatch } from "react-redux"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

import { reduxForm, Field, change, reset } from "redux-form"
import { compose } from "recompose"
import { renderDropdownList } from "../../common/render-dropdown"
import { NotificationsActions } from "../../../utils/Notifications/index"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./edit-study-form.sass"

import { replace } from "../../../actions/ProjectListAction"
import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Checkbox from "@material-ui/core/Checkbox"

import { renderAgeRadioButtons } from "../../common/render-radio-buttons"
import { RootState } from "../../../reducers/mainReducer"
import { getProjectData } from "../../../actions/apiAction"
import { DATE_FORMAT } from "../../../constants/constants"

declare global {
  interface Date {
    parseDate(): string
  }
}

Date.prototype.parseDate = function (): string {
  const dateFormatted = this
  const day = dateFormatted.getDate()
  const month = dateFormatted.getMonth() + 1 // January is 0!
  const yyyy = String(dateFormatted.getFullYear())
  let dd = String(day)
  let mm = String(month)
  if (day < 10) {
    dd = "0" + String(day)
  }
  if (month < 10) {
    mm = "0" + String(month)
  }
  return yyyy + "-" + mm + "-" + dd
}

const EditStudyForm: React.FC = (props: any) => {
  const [checked, checkedSet] = React.useState<boolean>(false)
  const [fromDate, fromDateSet] = React.useState<any>(new Date())
  const [toDate, toDateSet] = React.useState<any>(new Date())
  const [formHasErr, formHasErrSet] = React.useState(false)
  const [isLoading, isLoadingSet] = React.useState(false)

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  React.useEffect(() => {
    dispatch(reset("editStudyForm"))
    const { current } = state
    const formInitial = {
      data_location:
        CreateStudyModel.options.data_location.find(
          (item) => item.value === current.project.data.config.data_location
        ) || CreateStudyModel.initialValues.data_location,
      anonym_level:
        CreateStudyModel.options.anonym_level.find(
          (item) => item.value === current.project.data.config.anonym_level
        ) || CreateStudyModel.initialValues.anonym_level,
      age_measure:
        CreateStudyModel.options.age_measure.find(
          (item) => item.value === current.project.data.config.age_measure
        ) || CreateStudyModel.initialValues.age_measure,
      status:
        CreateStudyModel.options.status.find(
          (item) => item.value === current.project.data.state
        ) || CreateStudyModel.initialValues.status,
      abbr: current.project.data.abbreviation,
      name: current.project.data.name,
      from_date:
        current.project.data.from_date && current.project.data.from_date.$date
          ? format(current.project.data.from_date.$date, `${DATE_FORMAT}`)
          : fromDate,
      to_date:
        current.project.data.to_date && current.project.data.to_date.$date
          ? format(current.project.data.to_date.$date, `${DATE_FORMAT}`)
          : toDate,
      description: current.project.data.description,
      owner: current.project.data.owner_name,
      creation_date: format(
        current.project.data.config.time_created.$date,
        `${DATE_FORMAT}`
      ),
      num_readers: current.project.data.config.num_readers,
      num_diagnosis_adjudicators:
        current.project.data.config.num_diagnosis_adjudicators,
      number_expected_tm_per_subject:
        current.project.data.config.number_expected_tm_per_subject,
      // password_verify: 1,
    }
    fromDateSet(
      current.project.data.from_date &&
        current.project.data.from_date.$date &&
        current.project.data.from_date.$date
    )
    toDateSet(
      current.project.data.to_date &&
        current.project.data.to_date.$date &&
        current.project.data.to_date.$date
    )
    props.initialize(formInitial)
  }, [])

  React.useEffect(() => {
    if (state.current.project.data.config.esignatures) {
      checkedSet(state.current.project.data.config.esignatures.includes("task"))
    }
  }, [state.current.project.data.config.esignatures])

  const formik = useFormik({
    initialValues: {
      name: state.current.project.data.name,
      // abbr: state.current.project.data.abbreviation,
      description: state.current.project.data.description,
      form_id: state.current.project.data.config.form_id,
      num_readers: state.current.project.data.config.num_readers,
      num_diagnosis_adjudicators:
        state.current.project.data.config.num_diagnosis_adjudicators,
      number_expected_tm_per_subject:
        state.current.project.data.config.number_expected_tm_per_subject,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(25, "Must be only 25 or less!")
        .required("Required!")
        .matches(
          /^[aA-zZ0-9\s]+$/,
          "Only letters and numbers are allowed for this field !"
        ),
      // abbr: Yup.string()
      //   .max(7, "Must be only 7 or less!")
      //   .required("Required!"),
      num_readers: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      num_diagnosis_adjudicators: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      number_expected_tm_per_subject: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: formHasErr,
    validateOnBlur: false,
    validateOnMount: false,
  })

  React.useEffect(() => {
    formHasErrSet(Object.keys(formik.errors).length !== 0)
  }, [formik.errors])

  const onSubmit = (values) => {
    const { form } = state
    const formValues = form.editStudyForm.values
    const params: any = {
      ...formValues,
      ...values,
      id: state.current.project.data._id,
      owner: state.session.user_data.name,
      status: formValues.status.value,
      age_measure: formValues.age_measure.value,
      anonym_level: formValues.anonym_level.value,
      task_esignature: checked ? 1 : 0,
      project_type: "central_reading",
    }

    const paramsWithParsedDates = {
      ...params,
      to_date:
        state.current.project.data.to_date.$date !== toDate
          ? toDate.parseDate()
          : (() => new Date(toDate).parseDate())(),
      from_date:
        state.current.project.data.from_date.$date !== fromDate
          ? fromDate.parseDate()
          : (() => new Date(fromDate).parseDate())(),
    }

    const max_num_diagnosis_adjudicators = 1
    if (
      parseInt(values.num_diagnosis_adjudicators) >
      max_num_diagnosis_adjudicators
    ) {
      dispatch(
        NotificationsActions.warning(
          `The number of adjudicators must be between 0 and ${max_num_diagnosis_adjudicators}!`
        )
      )
    } else if (fromDate > toDate) {
      dispatch(
        NotificationsActions.warning(
          "The start date must be before the end date!"
        )
      )
    } else {
      if (!Object.keys(formik.errors).length) {
        isLoadingSet(true)
        Promise.all([dispatch(replace(paramsWithParsedDates))]).then(() => {
          isLoadingSet(false)
          dispatch(getProjectData(state.current.project.data._id))
        })
      }
    }
  }

  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkedSet(event.target.checked)
  }

  const _onChangeStartDate = (currentStartDate: any) => {
    if (currentStartDate <= toDate) {
      fromDateSet(currentStartDate)
      dispatch(change("editStudyForm", "from_date", fromDate))
    }
  }

  const _onChangeEndDate = (currentEndDate: any) => {
    if (currentEndDate >= fromDate) {
      toDateSet(currentEndDate)
      dispatch(change("editStudyForm", "to_date", toDate))
    }
  }

  const { submitting } = props
  const { form, current } = state

  return (
    <Grid container style={{ minHeight: "80vh" }}>
      <div className="study-info">
        Creation date:{" "}
        <span className="">
          {current.project.data
            ? format(
                current.project.data.config.time_created.$date,
                `${DATE_FORMAT}`
              )
            : ""}
        </span>
        ; Owner:{" "}
        <span>
          {current.project.data ? current.project.data.owner_name : ""}
        </span>
        ; Data location:{" "}
        <span>
          {current.project.data
            ? CreateStudyModel.options.data_location.find(
                (item) =>
                  item.value === current.project.data.config.data_location
              )?.label
            : ""}
        </span>
      </div>

      {isLoading ? (
        <Grid container>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress style={{ color: "#2CA895" }} />
          </div>
        </Grid>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container className="formContainer">
            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={12}>
                <label>Study Name</label>
                <input
                  className={"input"}
                  name="name"
                  id="study_name"
                  autoComplete="off"
                  placeholder="Study Name e.g 'My Test Study'"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {((formik.touched.name && formik.errors.name) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors.name}
                  </p>
                )}
              </Grid>
            </Grid>

            {/* <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <label>Abbreviation</label>
                <input
                  className={"input"}
                  name="abbr"
                  id="study_abbr"
                  placeholder="Abbreviation e.g 'TEST'"
                  type="text"
                  value={formik.values.abbr}
                  onChange={formik.handleChange}
                />
                {((formik.touched.abbr && formik.errors.abbr) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors.abbr}
                  </p>
                )}
              </Grid>
            </Grid> */}

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={12}>
                <label>Study status</label>
                <Field
                  name="status"
                  data={CreateStudyModel.options.status}
                  component={renderDropdownList}
                  disabled={!true}
                  valuefield="value"
                  textField="label"
                  defaultValue={CreateStudyModel.initialValues.status}
                />
              </Grid>
            </Grid>

            <Grid container className="formItem">
            <Grid item xs={12} sm={12} md={12}>
              <label>Form Type</label>
              <input
                  className={"input"}
                  name="form_id"
                  id="study_form_id"
                  type="text"
                  value={formik.values.form_id}
                  onChange={formik.handleChange}
                  disabled
                />
            </Grid>
          </Grid>

            <Grid container className="formItem">
              <Grid item>
                <label>Age units *</label>
                <Field
                  name="age_measure"
                  data={CreateStudyModel.options.age_measure}
                  component={renderAgeRadioButtons}
                  editStudyForm={form.editStudyForm}
                  defaultValue={state.current.project.data.config.age_measure}
                />
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <div className="control has-icons-right">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      margin="normal"
                      name="from_date"
                      id="from_date"
                      label="Start Date"
                      format="yyyy-MM-dd"
                      value={fromDate}
                      onChange={_onChangeStartDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6} className="field">
                <div className="control has-icons-right">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      margin="normal"
                      name="to_date"
                      id="to_date"
                      label="End Date"
                      format="yyyy-MM-dd"
                      value={toDate}
                      onChange={_onChangeEndDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={12} className="field">
                <label>Description</label>
                <textarea
                  name="description"
                  id="study_desc"
                  placeholder="Desciption of the study (max. 240 characters)"
                  // defaultValue=""
                  style={{ height: "130px" }}
                  className="input"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                ></textarea>
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <label>Number of readers *</label>
                <input
                  className={"input"}
                  maxLength={2}
                  name="num_readers"
                  id="study_num_readers"
                  type="number"
                  value={formik.values.num_readers}
                  onChange={formik.handleChange}
                  disabled={state.current.project.data.state !== "active"}
                />
                {((formik.touched.num_readers && formik.errors.num_readers) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors.num_readers}
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <label>Number of diagnosis adjudicators *</label>
                <input
                  className={"input"}
                  maxLength={2}
                  name="num_diagnosis_adjudicators"
                  id="study_num_diagnosis_adjudicators"
                  type="number"
                  value={formik.values.num_diagnosis_adjudicators}
                  disabled={state.current.project.data.state !== "active"}
                  onChange={formik.handleChange}
                />
                {((formik.touched.num_diagnosis_adjudicators &&
                  formik.errors.num_diagnosis_adjudicators) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors.num_diagnosis_adjudicators}
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <label>Number of expected timepoints per subject *</label>
                <input
                  className={"input"}
                  maxLength={2}
                  name="number_expected_tm_per_subject"
                  id="study_number_expected_tm_per_subject"
                  type="number"
                  value={formik.values.number_expected_tm_per_subject}
                  onChange={formik.handleChange}
                />
                {((formik.touched.number_expected_tm_per_subject &&
                  formik.errors.number_expected_tm_per_subject) ||
                  !formik.isSubmitting) && (
                  <p style={{ color: "#9B1A06", fontSize: "14px" }}>
                    {formik.errors.number_expected_tm_per_subject}
                  </p>
                )}
              </Grid>
            </Grid>

            <Grid container className="formItem">
              <Grid item xs={12} sm={12} md={6}>
                <label>Task e-signature</label>
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheck}
                  color="primary"
                  name="task_esignature"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  style={{ marginBottom: "26px" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container className="buttonsContainer">
            <button
              className="btn quaternary is-block"
              onClick={(e) => {
                e.preventDefault()
                props.history.goBack()
              }}
            >
              Cancel
            </button>
            <button
              className={
                Object.keys(formik.errors).length
                  ? "btn inactive denial"
                  : "btn primary is-block"
              }
              type="submit"
              disabled={submitting}
            >
              Save Changes
            </button>
          </Grid>
        </form>
      )}
    </Grid>
  )
}

export default compose(
  reduxForm({
    form: "editStudyForm",
  })
)(EditStudyForm)
