import { removeSite, fetchSites } from "./apiAction"
import {
  SITES_TABLE_FETCHING_DATA,
  SITES_TABLE_RECEIVE_DATA,
  SITES_TABLE_INVALIDATE_DATA,
} from "../constants/constants"
import { setSites } from "./sites"

export const fetchSitesDispatch =
  (projectId, pageParams = null) =>
  (dispatch) => {
    dispatch({
      type: SITES_TABLE_FETCHING_DATA,
    })
    return dispatch(fetchSites(projectId, pageParams))
      .then((response) => {
        if (!response.error) {
          dispatch(setSites(response.data))
          const empty = response.data.data.length === 0
          return dispatch({
            type: SITES_TABLE_RECEIVE_DATA,
            payload: response,
            emptyTable: empty,
          })
        }
      })
      .catch((error) => error)
  }

export const deleteSite = (id) => (dispatch) =>
  dispatch(removeSite(id)).then(() =>
    dispatch({
      type: SITES_TABLE_INVALIDATE_DATA,
    })
  )
