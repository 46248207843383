import {
  getProjectData,
  getProjectSetList,
  postProject,
  projectSetModuleConfig,
} from "./apiAction"
import { setCurrentProjectFetching } from "./currentAction"

import {
  PROJECT_LIST_RECEIVE_DATA,
  PROJECT_LIST_CLEAR_LIST,
  PROJECT_LIST_FETCHING_DATA,
  SUCCESS_CHANGE_PROJECT,
  SET_CURRENT_PROJECT,
} from "../constants/constants"

// const tableActions = ResponseActionsFactory(STUDY_TABLE);

export const fetchList = () => (dispatch) => {
  dispatch({
    type: PROJECT_LIST_CLEAR_LIST,
  })
  dispatch({
    type: PROJECT_LIST_FETCHING_DATA,
  })
  return dispatch(getProjectSetList()).then((data) => {
    return dispatch(projectSetModuleConfig(data.data)).then((action) => {
      return dispatch({
        type: PROJECT_LIST_RECEIVE_DATA,
        payload: {
          data: data.data,
          roles: action.roles,
        },
        emptyTable: true,
      })
    })
  })
}

export const fetchItem = (values) => (dispatch) => {
  dispatch(setCurrentProjectFetching(true))
  return dispatch(getProjectData(values))
    .then((data) =>
      dispatch({
        type: SET_CURRENT_PROJECT,
        payload: data,
      })
    )
    .catch((error) => error)
}

const sucessChangeProject = () => ({
  type: SUCCESS_CHANGE_PROJECT,
})
export const createNewProject = (values) => (dispatch) =>
  dispatch(postProject(values))

export const replace = (values) => {
  const isEdit = true
  return (dispatch) =>
    dispatch(postProject(values, isEdit)).then(() => {
      return dispatch(sucessChangeProject())
    })
}

export const clearList = () => (dispatch) => {
  return dispatch({
    type: PROJECT_LIST_CLEAR_LIST,
  })
}
