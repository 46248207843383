import "./LoggedApp.sass"

import * as React from "react"

import { Route, Switch } from "react-router-dom"

import CreateNewProject from "../containers/dashboard/create-new-project"
import Navigation from "./common/dashboard/Navigation"
import ProjectList from "../containers/dashboard/project-list-page"
import SidePanel from "./common/dashboard/sidepanel"
import StudyContainer from "../containers/dashboard/Study"
import { connect } from "react-redux"

const NotFoundPage = React.lazy(() => import("./common/notFound"))
const FileInfo = React.lazy(() => import("../containers/fileInfo"))

interface Props {
  location: any
  session: any
  table: any
  match: any
}

class LoggedApp extends React.Component<Props> {
  _isLogged() {
    const { session } = this.props
    return !!session.success
  }

  render() {
    return (
      <div className="columns">
        <SidePanel {...this.props} />

        <div
          className={
            window.location.href.includes("task") ||
            window.location.href.includes("history")
              ? "app-container-no-sidebar"
              : "app-container"
          }
        >
          <Navigation />
          <Switch>
            <Route
              path={"/project"}
              exact={true}
              render={(props) => <ProjectList {...props} />}
            />
            <Route path={"/project/create"} component={CreateNewProject} />
            <Route
              path={"/project/:project_id"}
              render={(props) => <StudyContainer {...props} />}
            />
            <Route path={"/file_manager/:file_info"} component={FileInfo} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ session, match }) => ({
  session,
  match,
})
export default connect(mapStateToProps)(LoggedApp)
