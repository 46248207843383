import React, { useState, useEffect } from "react"
import { Grid, withStyles } from "@material-ui/core"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../reducers/mainReducer"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table/"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { compose } from "recompose"
import { format } from "date-fns"
import "./activity-page.sass"
import { DATE_FORMAT } from "../../constants/constants"

import { getActivityList } from "../../actions/apiAction"

interface Column {
  id: any
  cellName: any
  label: string
  width?: number
  align?: "left"
  className?: string
  sortField: string
}

const columns: Column[] = [
  {
    label: "Date & time",
    id: "timestamp",
    cellName: "timestamp",
    sortField: "timestamp",
  },
  {
    label: "Username",
    id: "user_name",
    cellName: "user_name",
    sortField: "user_name",
  },
  {
    label: "Activity name",
    id: "name",
    cellName: "name",
    sortField: "name",
  },
]

const styles = {
  root: {
    width: "100%",
  },
  container: {
    paddingLeft: "2%",
    paddingRight: "2%",
  },
}

let studyName = ""

const ActivityPage = (props: any) => {
  const { classes } = props
  const [crTable, crTableSet] = useState([])

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  useEffect(() => {
    studyName = state.current.project.data.name.toUpperCase()
    dispatch(getActivityList(state.current.project.data._id))
  }, [])

  useEffect(() => {
    crTableSet(state.activityTable.data)
  }, [state.activityTable.data])

  const MyTableCell = (props: any) => {
    return <TableCell {...props} />
  }

  return (
    <div className={classes.container}>
      <Grid container className="container-worklist-page">
        <Grid item xs={12} sm={12} md={2}>
          <h1>
            Activity log &nbsp;
            {state.activityTable.isFetching ? (
              <img
                className="base-margin-half"
                width="24px"
                height="24px"
                src="assets/icons/loading-image.gif"
                alt="loading"
              />
            ) : null}
          </h1>
        </Grid>
        <Grid item xs={12}>
          {crTable.length ? (
            <>
              <Grid item xs={12}>
                <div className="button-row">
                  {!state.activityTable.isFetching && (
                    <label>
                      There are {crTable.length} activities within this study
                    </label>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                {state.activityTable.isFetching ? (
                  <></>
                ) : (
                  <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <MyTableCell key={index} align={column.align}>
                                <div className={classes.headerCell}>
                                  {column.label}
                                </div>
                              </MyTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {crTable.map((row, rowIndex) => {
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={rowIndex}
                              >
                                {columns.map((column, cellIndex) => {
                                  let value: any
                                  if (column.id === "timestamp") {
                                    value =
                                      format(
                                        // @ts-ignore
                                        row[column.id].$date,
                                        `${DATE_FORMAT}`
                                      ) || " - "
                                  } else {
                                    value = row[column.id]
                                  }
                                  return (
                                    <TableCell
                                      key={cellIndex}
                                      align={column.align}
                                    >
                                      <p className={classes[value]}>{value}</p>
                                    </TableCell>
                                  )
                                })}
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}
              </Grid>
            </>
          ) : (
            !state.activityTable.isFetching && (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  className="no-task"
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <p>Your activity list is currently empty</p>
                </Grid>
              </>
            )
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default compose(withStyles(styles))(ActivityPage)
