import {
  ACTIVITY_TABLE_FETCHING_DATA,
  ACTIVITY_TABLE_RECEIVE_DATA,
  DATE_FORMAT,
} from "../constants/constants"

const initialState = {
  data: [],
  isFetching: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIVITY_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      }

    case ACTIVITY_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
