import {
  ADD_PROTOCOL_ACTION,
  ADD_PROTOCOL_IMAGE,
  ADD_PROTOCOL_IMAGE_CONDITION,
  CHANGE_GUIDANCE_TEXT,
  CHANGE_IMAGE_CONDITION,
  CHANGE_PROTOCOL_ITEM_NAME,
  CHANGE_PROTOCOL_ITEM_VALUE,
  EDIT_PROTOCOL_ITEM,
  REMOVE_PROTOCOL_IMAGE_CONDITION,
  SET_PROTOCOL_DATA,
} from "../constants/constants"

const PROTOCOLS_INITIAL = {
  data: {
    images: [],
    actions: [],
  },
  guidance_text: "",
}

export default function (state: any = PROTOCOLS_INITIAL, action: any) {
  switch (action.type) {
    case SET_PROTOCOL_DATA:
      return { ...state, data: action.payload }

    case ADD_PROTOCOL_IMAGE:
      action.payload.id = "new-" + state.data.images.length // change id to new-1, new-2, new-3, etc, for predictable testing ids
      return {
        ...state,
        data: { ...state.data, images: [...state.data.images, action.payload] },
      }

    case ADD_PROTOCOL_ACTION:
      action.payload.id = "new-" + state.data.actions.length // change id to new-1, new-2, new-3, etc, for predictable testing ids
      return {
        ...state,
        data: {
          ...state.data,
          actions: [...state.data.actions, action.payload],
        },
      }

    case ADD_PROTOCOL_IMAGE_CONDITION:
      return { ...state, data: { ...state.data, images: [...action.payload] } }

    case REMOVE_PROTOCOL_IMAGE_CONDITION:
      return { ...state, data: { ...state.data, images: [...action.payload] } }

    case CHANGE_IMAGE_CONDITION:
      return { ...state, data: { ...state.data, images: [...action.payload] } }

    case EDIT_PROTOCOL_ITEM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.data]: [...action.payload.temp],
        },
      }

    case CHANGE_PROTOCOL_ITEM_NAME:
      return {
        ...state,
        data: { ...state.data, [action.payload.data]: action.payload.temp },
      }

    case CHANGE_PROTOCOL_ITEM_VALUE:
      return { ...state, data: { ...state.data, images: [...action.payload] } }

    case CHANGE_GUIDANCE_TEXT:
      return { ...state, guidance_text: action.payload }

    default:
      return state
  }
}
