import * as React from "react"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { compose } from "recompose"
import "./study-activity-card"

const styles = {
  card: {
    minWidth: 275,
    marginTop: "20px",
    marginBottom: "20px",
  },
  keyColumn: {
    color: "#666",
  },
}

class StudyActivityCard extends React.Component<any, any> {
  render() {
    const { classes } = this.props
    return (
      <Card className={classes.card}>
        <CardContent>
          <div>
            <h3 className="weight-medium">Study activity log</h3>
          </div>

          <div className="info-container">
            <p className={classes.keyColumn}>Activities completed</p>
            <div id="study_activity_card.activities_completed">
              {this.props.number}
            </div>
          </div>

          <div className="button-right-container">
            <button
              id="study_activity_card.view_activity_log_button"
              className="ghost-btn"
              onClick={() => this.props.history.push("activity")}
            >
              <i className="material-icons">visibility</i>
              View activity
            </button>
          </div>
        </CardContent>
      </Card>
    )
  }
}

export default compose(withStyles(styles))(StudyActivityCard)
