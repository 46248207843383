import * as ApiActions from "./apiAction"

import {
  API_CALL,
  SET_CURRENT_PROJECT_ID,
  SET_SESSION,
  SET_SESSION_EXPIRATION,
  SET_SESSION_EXPIRED,
  UPDATE_USER_SESSION_ACCESS,
} from "../constants/constants"

const callAPI = (url, method = "GET", postData = null, headers = {}) => ({
  type: API_CALL,
  url,
  method,
  postData,
  headers,
})

export const setCurrentProjectId = (currentProjectId) => ({
  type: SET_CURRENT_PROJECT_ID,
  payload: currentProjectId,
})

export const setSession = (val) => ({
  type: SET_SESSION,
  payload: val,
})

export const updateUserSessionAccess = (val) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_SESSION_ACCESS,
      payload: {
        isManager:
          val.access.includes("role_clinical_trial_project_admin") ||
          val.access.includes("role_clinical_trial_project_manager") ||
          val.access.includes("role_clinical_trial_owner"),
        access: val.access,
      },
    })
  }
}

export const deleteSession = (data) => (dispatch) =>
  dispatch(ApiActions.deleteSession(data))

export const setSessionExpiration = (val) => ({
  type: SET_SESSION_EXPIRATION,
  payload: val,
})

export const setSessionExpired = (val) => ({
  type: SET_SESSION_EXPIRED,
  payload: val,
})

export const sessionLogout = () => {
  return (dispatch) => {
    return dispatch(callAPI("/logout", "POST"))
      .then(() => {
        window.location.replace(`${process.env.REACT_APP_LOGIN_URL}`)
      })
      .catch((error) => error)
  }
}
