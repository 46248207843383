import { useDispatch, useSelector } from "react-redux"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import React from "react"
import Typography from "@material-ui/core/Typography"
import { withRouter } from "react-router-dom"

const MuiBreadCrumbs = (props) => {
  const {
    history,
    location: { pathname },
  } = props
  const pathnames = pathname.split("/").filter((x) => x)
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  const customizeNames = (name: string) => {
    if (name === "overview")
      return `Study overview (${state.current.project.data.name})`
    if (name === "add-files") return "Add files"
    if (name === "sites") return `Sites (${state.current.project.data.name})`
    if (name === "add" && pathnames[pathnames.length - 2] === "data")
      return "Data Add"
    if (name === "monitoring")
      return `Study progress monitoring (${state.current.project.data.name})`
    if (name === "worklist")
      return `Worklist (${state.current.project.data.name})`
    if (name === "information")
      return `Study information (${state.current.project.data.name})`
    if (name === "edit" && pathnames[pathnames.length - 2] === "information")
      return "Edit Study"
    if (name === "edit" && pathnames[pathnames.length - 3] === "sites")
      return "Edit Site"
    if (name === "edit" && pathnames[pathnames.length - 3] === "collaborators")
      return "Edit collaborator"
    if (name === "create" && pathnames[pathnames.length - 2] === "project")
      return "Create study"
    if (name === "create" && pathnames[pathnames.length - 2] === "sites")
      return "Add new site"
    if (name === "activity")
      return `Study (${state.current.project.data.name}) log`
    if (name === "collaborators") return "Collaborators"
    if (name === "progress" && pathnames[pathnames.length - 2] === "analysis")
      return "Full analysis progress"
    if (name === "task")
      return `${
        state.session.user_data.isManager ? "Monitoring" : "Worklist"
      } (${state.current.project.data.name})`
    if (name === "adjudicate")
      return `${
        state.session.user_data.isManager ? "Monitoring" : "Worklist"
      } (${state.current.project.data.name})`
    if (!isNaN(parseInt(name))) return `Task ${name}`
    // return name
  }

  const filterRoutes = (name, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
    if (!isNaN(name)) return
    if (name === "project") return
    if (name === "information")
      return (
        <Link
          onClick={() =>
            history.push(`/project/${state.current.project.data._id}/overview`)
          }
          style={{ cursor: "pointer", color: "#000" }}
        >
          {customizeNames(name)}
        </Link>
      )
    if (
      pathnames[pathnames.length - 2] === "task" ||
      pathnames[pathnames.length - 2] === "adjudicate"
    )
      return (
        <Link
          onClick={() =>
            history.push(
              `/project/${state.current.project.data._id}/${
                state.session.user_data.isManager ? "monitoring" : "worklist"
              }`
            )
          }
          style={{ cursor: "pointer", color: "#000" }}
        >
          {customizeNames(name)}
        </Link>
      )
    return (
      <Link
        onClick={() => history.push(routeTo)}
        style={{ cursor: "pointer", color: "#000" }}
      >
        {customizeNames(name)}
      </Link>
    )
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        onClick={() => history.push("/")}
        style={{ cursor: "pointer", color: "#000" }}
      >
        My studies
      </Link>
      {pathnames.map((name, index) => {
        const isLast = index === pathnames.length - 1
        if (isLast) {
          return <Typography key={index}>{customizeNames(name)}</Typography>
        } else {
          return filterRoutes(name, index)
        }
      })}
    </Breadcrumbs>
  )
}

export default withRouter(MuiBreadCrumbs)
