import React from "react"
import { PROGRESS_MONITORING_TABLE_RECEIVE_DATA } from "../constants/constants"
import MoreButton from "../components/common/dashboard/moreButton"
import AnalysisRender from "../components/common/analysis-render"

const formatReceiveData = (action) => {
  const maxAnalysisNum = action.payload.data.reduce(
    (acc, curr) => Math.max(curr.analysis_list.length, acc),
    0
  )
  return action.payload.data.map((el) => {
    const analysisData = {}
    for (let i = 0; i < maxAnalysisNum; i++) {
      analysisData["analysis_data_" + i] = el.analysis_list[i]
        ? el.analysis_list[i]
        : { full_script_name: "", qa_status: "", _id: "" }
      // analysis_data["analysis_data_" + i] = el.analysis_list[i].qa_status
    }
    return {
      id: el._id || " - ",
      ssid: el.ssid || " - ",
      patient_secret_name: el.patient_secret_name || " - ",
      container_id: el.container_id,
      qa_status: el.qa_status || " - ",
      ...analysisData,
    }
  })
}

const initialState = {
  columns: [
    {
      Header: "Subject",
      maxWidth: 250,
      accessor: "patient_secret_name",
      cellIdPrefix: "subject",
      show: true,
    },
    {
      Header: "Timepoint",
      maxWidth: 250,
      accessor: "ssid",
      cellIdPrefix: "session",
      show: true,
    },
    {
      Header: "QA",
      accessor: "qa_status",
      maxWidth: 550,
      show: true,
      Cell: (row) => {
        const iconIdStr =
          "qa-icon--subject-" +
          row.patient_secret_name +
          "--session-" +
          row.ssid
        const buttonIdStr =
          "qa-more-button--subject-" +
          row.patient_secret_name +
          "--session-" +
          row.ssid

        let iconElement = (
          <i
            id={iconIdStr}
            className="material-icons is-pending"
            title={row.qa_status}
          >
            hourglass_empty
          </i>
        ) // default to pending
        if (row.qa_status.toLowerCase() === "pass") {
          iconElement = (
            <i id={iconIdStr} className="material-icons is-okay">
              check_circle_outline
            </i>
          )
        } else if (row.qa_status.toLowerCase() === "fail") {
          iconElement = (
            <i id={iconIdStr} className="material-icons is-warning">
              warning
            </i>
          )
        }

        return (
          <div className="more-button-container">
            {iconElement}
            <MoreButton
              option={{ name: "View session files" }}
              data={row}
              id={buttonIdStr}
              type={"qc"}
            />
          </div>
        )
      },
    },
  ],
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRESS_MONITORING_TABLE_RECEIVE_DATA:
      const maxAnalysisNum = action.payload.data.reduce(
        (acc, curr) => Math.max(curr.analysis_list.length, acc),
        0
      )
      const analysis_columns: any = []
      const receivedData = formatReceiveData(action)

      for (let i = 0; i < maxAnalysisNum; i++) {
        const tool_details = receivedData[0]["analysis_data_" + i]
        analysis_columns.push({
          Header: tool_details.short_name,
          maxWidth: 250,
          accessor: `analysis_data_${i}`,
          Cell: (row) => {
            const buttonIdStr =
              "analysis-more-button--column-" +
              i +
              "--subject-" +
              row.patient_secret_name +
              "--session-" +
              row.ssid

            return (
              <div className="more-button-container">
                <AnalysisRender row={row[`analysis_data_${i}`]} idx={i} />
                <MoreButton
                  option={{
                    name: "View analysis information",
                    url: `monitoring/${
                      row[`analysis_data_${i}`].analysis_id
                    }/analysis`,
                  }}
                  data={row[`analysis_data_${i}`]}
                  type={"analysis"}
                  id={buttonIdStr}
                />
              </div>
            )
          },
          show: true,
          tooltip: (
            <div>
              <div>{tool_details.short_name}</div>
              <div>{tool_details.code + ": v" + tool_details.version}</div>
            </div>
          ),
        })
      }

      return {
        ...state,
        data: receivedData,
        columns: [...initialState.columns, ...analysis_columns],
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    default:
      return {
        ...state,
      }
  }
}
