import * as React from "react"
import { connect } from "react-redux"
import { setCurrentProjectFetching } from "../../actions/currentAction"

import { compose } from "recompose"
import EditStudyForm from "../../components/forms/dashboard/edit-study-form"
import { Grid } from "@material-ui/core"

class EditStudy extends React.Component<any, any> {
  render() {
    const { current } = this.props
    return current.project.isFetching || !current.project.data._id ? (
      <div></div>
    ) : (
      <Grid container className="overview-container container">
        <Grid item xs={12} sm={6} md={6}>
          <h1 className="h1">Edit study information</h1>
          <EditStudyForm {...this.props} />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = ({ current }) => ({
  current,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentProjectFetching: (val) =>
      dispatch(setCurrentProjectFetching(val)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditStudy)
