import * as React from "react"
import "./collaborators-add.sass"
import AddCollaboratorForm from "../../components/forms/dashboard/add-collaborator-form"

class CollaboratorsAdd extends React.Component<any, any> {
  render() {
    return (
      <div className="container">
        <h1 className="h1">Add collaborator</h1>
        <div className="columns">
          <AddCollaboratorForm />
        </div>
      </div>
    )
  }
}

export default CollaboratorsAdd
