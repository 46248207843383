import * as React from "react"

import { Route, Switch } from "react-router-dom"

import NotFoundPage from "../../components/common/notFound"
import WorkList from "./work-list-page"
import { compose } from "recompose"
import { connect } from "react-redux"
import { getWorklist } from "../../actions/CentralReviewAction"

class WorkListSession extends React.Component<any, any> {
  componentDidMount(): void {
    const data = { projectset_id: this.props.match.params.project_id }
    this.props.getWorklist(data)
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path={"/project/:project_id/worklist"} component={WorkList} />
          <Route component={NotFoundPage} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (id) => dispatch(getWorklist(id)),
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkListSession
)
