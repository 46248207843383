import * as React from "react"
import { connect } from "react-redux"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import "./study-acquisition-card.sass"
import Tooltip from "@material-ui/core/Tooltip"

const styles: any = {
  card: {
    minWidth: 275,
    marginBottom: "20px",
  },
  subTitle: {
    color: "#279181",
    fontSize: "18px",
  },
  descriptionContainer: {
    marginTop: "30px",
  },
  progressContainerInfo: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    marginTop: "4px",
  },
  progressBar: {
    width: "100%",
  },
  cardAcquisitionContainer: {
    marginBottom: "20px",
  },

  sitesInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  perSite: {
    width: "70px",
    height: "auto",
    textAlign: "center",
    marginLeft: "10px",
  },
  keyColumn: {
    color: "#666",
  },
}

const mockSites = [
  {
    name: "Amsterdam",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 30,
  },
  {
    name: "Barcelona",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 45,
  },
  {
    name: "Chicago",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 45,
  },
]

const toolTipText =
  "Data acquisition shows the progress of data uploaded per site towards the target number of sessions"

class StudyAcquisitionCard extends React.Component<any, any> {
  componentDidMount(): void {
    const { current } = this.props
    this.createDataAcquisition(current.project.data)
  }

  // _createDataAcquisition() {
  //     const { current } = this.props;
  //     return this.createDataAcquisition(current.project.data);
  // }

  checkData = (data: any) => {
    if (data.length) return data
    if (!data.length) return mockSites
  }

  createDataAcquisition(project) {
    const { sites } = project

    // fill in missing values
    project.sites.map((curr) => {
      if (!("target" in curr) || curr.target == null) {
        curr.target = 0
      }

      if (!("uploaded_data" in curr) || curr.uploaded_data == null) {
        curr.uploaded_data = {
          pass: 0,
          fail: 0,
        }
      }
    })

    const dataAcquisiton = {
      subject: this.calculateDataAcquisition(project),
      target: this.checkData(project.sites).reduce((acc, curr) => {
        return (acc += curr.target)
      }, 0),
    }

    return {
      sites: [...sites],
      acquisiton: { ...dataAcquisiton },
    }
  }

  calculateDataAcquisition(project) {
    if (project.sites.length) {
      return project.sites.reduce((acc, current) => {
        return (acc += current.uploaded_data ? current.uploaded_data.pass : 0)
      }, 0)
    } else {
      return mockSites.reduce((acc, current) => {
        return (acc += current.uploaded_data ? current.uploaded_data.pass : 0)
      }, 0)
    }
  }

  calculateSubjectPercentFromTargetPercent(subject, target) {
    return (subject || target) === 0 ? 0 : Math.floor((subject / target) * 100)
  }

  render() {
    const { classes, projectData } = this.props
    const dataAcquisition = this.createDataAcquisition(projectData)

    return (
      <Card className={classes.card}>
        <CardContent>
          <h3 className="weight-medium">
            Data Acquisition&nbsp;
            {/* tooltip placement doesn't work the same way as the docs described
                            bottom-start and bottom-end seem to be reversed
                            https://material-ui.com/components/tooltips/#positioned-tooltips
                        */}
            <Tooltip title={toolTipText} placement="bottom-start">
              <i className="material-icons sm-icon">help</i>
            </Tooltip>
          </h3>
          <div className={classes.outerCardAcquisitionContainer}>
            <div className={classes.cardAcquisitionContainer}>
              <div className={classes.progressBarContainer}>
                <progress
                  id="study_acquisition_card.progress_bar"
                  value={dataAcquisition.acquisiton.subject}
                  max={dataAcquisition.acquisiton.target}
                  className={classes.progressBar}
                />
              </div>
              <div className={classes.progressContainerInfo}>
                <label id="study_acquisition_card.subject">{`${
                  dataAcquisition.acquisiton.subject
                } Timepoints (${this.calculateSubjectPercentFromTargetPercent(
                  dataAcquisition.acquisiton.subject,
                  dataAcquisition.acquisiton.target
                )}%)`}</label>
                <label id="study_acquisition_card.target">{`Target: ${dataAcquisition.acquisiton.target}`}</label>
              </div>
            </div>
            {this.checkData(dataAcquisition.sites).map((site, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="info-container">
                    <p
                      id={"study_acquisition_card.site-" + index + ".name"}
                      className={classes.keyColumn}
                    >
                      {" "}
                      {site.name}
                    </p>
                    <div className={classes.sitesInfo}>
                      {site.uploaded_data && site.uploaded_data.fail !== 0 ? (
                        <i
                          id={
                            "study_acquisition_card.site-" + index + ".qc_icon"
                          }
                          className="material-icons is-warning"
                        >
                          warning
                        </i>
                      ) : (
                        <i
                          id={
                            "study_acquisition_card.site-" + index + ".qc_icon"
                          }
                          className="material-icons is-okay"
                        >
                          check_circle_outline
                        </i>
                      )}
                      <progress
                        id={
                          "study_acquisition_card.site-" +
                          index +
                          ".uploaded_data"
                        }
                        value={site.uploaded_data ? site.uploaded_data.pass : 0}
                        max={site.target}
                      />
                      <div className={classes.perSite}>
                        {site.uploaded_data ? (
                          <span
                            id={
                              "study_acquisition_card.site-" +
                              index +
                              ".pass_target"
                            }
                          >{`${site.uploaded_data.pass} / ${site.target}`}</span>
                        ) : (
                          <span
                            id={
                              "study_acquisition_card.site-" +
                              index +
                              ".pass_target"
                            }
                          >{`0 / ${site.target}`}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  {dataAcquisition.sites.length !== index + 1 ? null : null}
                </React.Fragment>
              )
            })}
          </div>
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(StudyAcquisitionCard)
