import {
  API_CALL,
  GET_WORKLIST,
  GET_CURRENT_TASK,
  CLEAR_CURRENT_TASK_FORM,
  GET_CURRENT_TASK_ENDPOINT,
  GET_CURRENT_ADJ_TASK_ENDPOINT,
  SET_ADJUDICATOR,
  SET_READER_1,
  SET_READER_2,
  GET_CENTRAL_READING_PROGRESS,
  SET_MATRIX_DATA,
  SET_CURRENT_LINE_MEASUREMENT,
  CLEAR_CURRENT_MEASUREMENT,
} from "../constants/constants"
import { format } from "date-fns"
import { DATE_FORMAT } from "../constants/constants"

import { NotificationsActions } from "../utils/Notifications/index"

const callAPI = (url, method = "GET", postData = null, headers = {}) => ({
  type: API_CALL,
  url,
  method,
  postData,
  headers,
})

export const getWorklist = (data) => {
  return (dispatch) => {
    return dispatch(
      callAPI("/central_reading_manager/get_worklist", "GET", data)
    )
      .then((response) => {
        const variable = response.map((el) => {
          const task_type = String(el.task_type).startsWith("reading_")
            ? "Read"
            : "Adjudicate"

          return {
            id: el._id || "-",
            container_id: el.container_id || "-",
            patient_secret_name: el.patient_secret_name || "-",
            project_id: el.projectset_id || "-",
            session_id: el.ssid || "-",
            status: el.status || "-",
            task_type: task_type || "-",
            site: el.site || "-",
            time_created: el.time_created
              ? format(el.time_created.$date, `${DATE_FORMAT}`)
              : "-",
            timestamp: el.time_modified
              ? format(el.time_modified.$date, `${DATE_FORMAT}`)
              : "-",
            age_at_scan: el.age_at_scan || "-",
            viewoption: el.viewoption || "true",
          }
        })
        dispatch({
          type: GET_WORKLIST,
          payload: variable,
          isFetching: false,
          isInvalidated: false,
          isEmpty: false,
        })
        return variable
      })
      .catch((error: any) => error)
  }
}

export const getCentralReadingProgress = (data) => {
  // added new field to avoid axios cache
  // TO-DO: evaluate how to include no-cache in some requests
  data["now"] = Date.now()
  return (dispatch) => {
    return dispatch(
      callAPI(
        "/central_reading_manager/get_project_manager_overview",
        "GET",
        data
      )
    )
      .then((res: any[]) => {
        dispatch({
          type: GET_CENTRAL_READING_PROGRESS,
          payload: res,
          isFetching: false,
          isInvalidated: false,
          isEmpty: false,
        })
      })
      .catch((err: any) => err)
  }
}

export const setAssignUser = (values, data) => {
  return (dispatch) => {
    return dispatch(
      callAPI("/central_reading_manager/assign_reader", "POST", values)
    )
      .then(() => dispatch(getCentralReadingProgress(data)))
      .catch((error) => {
        dispatch(getCentralReadingProgress(data))
        throw error
      })
  }
}

export const setUnassignUser = (values, data) => {
  return (dispatch) => {
    return dispatch(
      callAPI("/central_reading_manager/unassign_reader", "POST", values)
    )
      .then(() => dispatch(getCentralReadingProgress(data)))
      .catch((error) => {
        dispatch(getCentralReadingProgress(data))
        throw error
      })
  }
}

export const setNotNeededAdjudicator = (values) => {
  return (dispatch) => {
    return dispatch(
      callAPI("/central_reading_manager/assign_not_needed", "POST", values)
    ).catch((error) => error)
  }
}

export const setReader_1 = (val) => {
  return (dispatch) => {
    dispatch({
      type: SET_READER_1,
      payload: val,
    })
  }
}

export const setReader_2 = (val) => {
  return (dispatch) => {
    dispatch({
      type: SET_READER_2,
      payload: val,
    })
  }
}

export const setAdjudicator = (val) => {
  return (dispatch) => {
    dispatch({
      type: SET_ADJUDICATOR,
      payload: val,
    })
  }
}

export const getCurrentTask = (subject) => {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_TASK,
      payload: subject,
    })
  }
}

export const clearCurrentTaskForm = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CURRENT_TASK_FORM,
    })
  }
}

export const clearCurrentMeasurement = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CURRENT_MEASUREMENT,
    })
  }
}

export const getCurrentTaskEndpoint = (params) => {
  return (dispatch) => {
    dispatch(
      callAPI(
        `/central_reading_manager/load_reader_task?task_id=${params.task_id}&projectset_id=${params.project_id}`,
        "GET"
      )
    )
      .then((res: any[]) => {
        dispatch({
          type: GET_CURRENT_TASK_ENDPOINT,
          payload: res,
        })
      })
      .catch((error) => error)
  }
}

export const getCurrentAdjTask = (task, params) => {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_TASK,
      payload: task,
    })
    dispatch(getCurrentAdjTaskEndpoint(params))
  }
}

export const getCurrentAdjTaskEndpoint = (params) => {
  return (dispatch) => {
    dispatch(
      callAPI(
        `/central_reading_manager/load_adjudicator_task?task_id=${params.task_id}&projectset_id=${params.project_id}`,
        "GET"
      )
    ).then((res: any[]) => {
      dispatch({
        type: GET_CURRENT_ADJ_TASK_ENDPOINT,
        payload: res,
      })
    })
  }
}

export const submitAdjudication = (props, formData) => {
  return (dispatch) => {
    dispatch(
      callAPI("/central_reading_manager/submit_adjudicator", "POST", formData)
    )
      .then(() => {
        props.history.goBack()
        dispatch(
          NotificationsActions.success("Form was submitted successfully!")
        )
      })
      .catch((error) => error)
  }
}

export const submitTask = (props, formData) => {
  return (dispatch) => {
    dispatch(
      callAPI("/central_reading_manager/submit_reader_task", "POST", formData)
    )
      .then(() => {
        props.history.goBack()
        dispatch(
          NotificationsActions.success("Form was submitted successfully!")
        )
      })
      .catch((error) => {
        throw error
      })
  }
}

export const setCurrentLineMeasurement = (measurement) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_LINE_MEASUREMENT,
      payload: measurement,
    })
  }
}

export const verifyPassword = (data, props, history) => {
  return (dispatch) => {
    dispatch(callAPI("/a_user_manager/verify_password", "POST", data))
      .then((response) => {
        if (response.success) {
          Promise.all([dispatch(submitTask(props, props.formData))])
            .then(() => {
              history.goBack()
              dispatch(
                NotificationsActions.success("Form was submitted successfully!")
              )
            })
            .catch((error) => {
              throw error
            })
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export const getHistory = (data) => {
  return (dispatch) => {
    dispatch(callAPI("/central_reading_manager/get_history", "GET", data))
      .then((response) => {
        dispatch({
          type: SET_MATRIX_DATA,
          payload: response,
          isFetching: false,
          isInvalidated: false,
          isEmpty: false,
        })
      })
      .catch((error) => {
        throw error
      })
  }
}
