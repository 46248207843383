import React from "react"
import {
  SESSION_TABLE_FETCHING_DATA,
  SESSION_TABLE_RECEIVE_DATA,
  DATE_FORMAT,
} from "../constants/constants"
import { format } from "date-fns"

const formatReceiveData = (action) => {
  return action.payload.data.map((el) => ({
    id: el._id,
    container_id: el.container_id,
    patient_secret_name: el.patient_secret_name,
    session_id: el.ssid,
    qa_status: el.qa_status,
    processing_files_state: el.processing_files_state,
    site: el.site || "-",
    time_created: el.config.time_created
      ? format(el.config.time_created.$date, `${DATE_FORMAT}`)
      : "-",
    timestamp: el.config.time_created ? el.config.time_created.$date : 0,
    age_at_scan: el.age_at_scan || "-",
  }))
}

const initialState = {
  columns: [
    {
      maxWidth: 60,
      Header: "QC",
      accessor: "qa_status",
      id: "qa_status",
      className: "qc-col",
      toggled: true,
      show: true,
      sortField: "qa_status",
      Cell: (row) => {
        if (row.qa_status === "pass") {
          return (
            <i title="Pass" className="material-icons is-okay">
              check_circle_outline
            </i>
          )
        } else if (row.qa_status === "") {
          return (
            <i title="Not available" className="material-icons is-pending">
              hourglass_empty
            </i>
          )
        } else {
          // Fail
          return (
            <i title="Warning" className="material-icons is-warning">
              warning
            </i>
          )
        }
      },
    },
    {
      Header: "Subject",
      maxWidth: 350,
      accessor: "patient_secret_name",
      id: "patient_secret_name",
      show: true,
      sortField: "patient_secret_name",
    },
    {
      Header: "Timepoint",
      maxWidth: 100,
      accessor: "session_id",
      id: "session_id",
      show: true,
      sortField: "session_id",
    },
    {
      Header: "Site",
      accessor: "site",
      id: "site",
      maxWidth: 200,
      show: true,
      toggled: true,
      sortField: "site",
      aggregate: (vals) =>
        vals.filter((el, idx, ref) => ref.indexOf(el) === idx).join(","),
    },
    {
      Header: "Upload Date",
      accessor: "time_created",
      id: "time_created",
      show: true,
      toggled: true,
      sortField: "config.time_created",
      aggregate: (vals) =>
        vals.filter((el, idx, ref) => ref.indexOf(el) === idx).join(","),
    },
    {
      Header: "Age",
      accessor: "age_at_scan",
      id: "age_at_scan",
      show: false,
      toggled: true,
      aggregate: (vals) =>
        vals.filter((el, idx, ref) => ref.indexOf(el) === idx).join(","),
    },
  ],
  filterPanel: false,
  empty: false,
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case SESSION_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
