import React from "react"

const EmptyTableMessage = ({ fetching, title, text }) => {
  if (fetching) {
    return <div>loading...</div>
  } else {
    return (
      <div>
        <p>{`Your ${title} is currently empty`}</p>
        <p>{text}</p>
      </div>
    )
  }
}

export default EmptyTableMessage
