import * as React from "react"
import "./collaborators-edit.sass"
import EditCollaboratorForm from "../../components/forms/dashboard/collaborators-edit-form"

class CollaboratorsEdit extends React.Component<any, any> {
  render() {
    return (
      <div className="container">
        <h1 className="h1">Edit collaborator</h1>
        <div className="columns">
          <EditCollaboratorForm {...this.props} />
        </div>
      </div>
    )
  }
}

export default CollaboratorsEdit
