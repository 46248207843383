export const PUSH_BREADCRUMB = "PUSH_BREADCRUMB"
export const CLEAR_BREADCRUMB = "CLEAR_BREADCRUMB"
export const REMOVE_BREADCRUMB = "REMOVE_BREADCRUMB"
export const SET_CURRENT_PROJECT_ID = "SET_CURRENT_PROJECT_ID"
export const SET_CURRENT_BREADCRUMB = "SET_CURRENT_BREADCRUMB"
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT"
export const SET_CURRENT_ANALYSIS = "SET_CURRENT_ANALYSIS"
export const SET_CAN_UPLOAD_DATA = "SET_CAN_UPLOAD_DATA"
export const SET_PROJECTS = "SET_PROJECTS"
export const SET_SESSION = "SET_SESSION"
export const UPDATE_USER_SESSION_ACCESS = "UPDATE_USER_SESSION_ACCESS"
export const SET_SESSION_EXPIRATION = "SET_SESSION_EXPIRATION"
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED"
export const SET_ANALYSIS_LIST = "SET_ANALYSIS_LIST"

export const API_CALL = "API_CALL"
export const API_CALL_PAGINATION = "API_CALL_PAGINATION"

// FORMS
export const FORGOT_SET_ERROR = "FORGOT_SET_ERROR"
export const FORGOT_SET_SUCCESS = "FORGOT_SET_SUCCESS"
export const FORGOT_SET_INITIAL = "FORGOT_SET_INITIAL"
export const REGISTRATION_SET_SUCCESS = "REGISTRATION_SET_SUCCESS"
export const REGISTRATION_SET_ERROR = "REGISTRATION_SET_ERROR"
export const REGISTRATION_SET_INITIAL = "REGISTRATION_SET_INITIAL"
export const RESET_PASSWORD_SET_ERROR = "RESET_PASSWORD_SET_ERROR"
export const RESET_PASSWORD_SET_SUCCESS = "RESET_PASSWORD_SET_SUCCESS"
export const RESET_PASSWORD_SET_INITIAL = "RESET_PASSWORD_SET_INITIAL"

export const RECEIVE_PROJECT_CONFIG = "RECEIVE_PROJECT_CONFIG"
export const RECEIVE_FORMATED_PROJECT_CONFIG = "RECEIVE_FORMATED_PROJECT_CONFIG"

export const SET_CURRENT_PROJECT_FETCHING = "SET_CURRENT_PROJECT_FETCHING"
export const SET_CURRENT_PROJECT_ACTIVITY_LOG =
  "SET_CURRENT_PROJECT_ACTIVITY_LOG"
export const SET_READER_1 = "SET_READER_1"
export const SET_READER_2 = "SET_READER_2"
export const SET_ADJUDICATOR = "SET_ADJUDICATOR"
export const GET_CENTRAL_READING_PROGRESS = "GET_CENTRAL_READING_PROGRESS"

export const SET_CURRENT_SITE = "SET_CURRENT_SITE"
export const SET_SITES = "SET_SITES"
export const DELETE_SITE = "DELETE_SITE"
export const EDIT_SITE = "EDIT_SITE"
export const SET_SITES_FETCHING = "SET_SITES_FETCHING"
export const SET_LOADING = "SET_LOADING"

export const SET_COLLABORATORS = "SET_COLLABORATORS"
export const SET_CURRENT_COLLABORATOR = "SET_CURRENT_COLLABORATOR"
export const UPDATE_TAGS = "UPDATE_TAGS"
export const OPEN_CLOSE_INVITE_MODAL = "OPEN_CLOSE_INVITE_MODAL"

export const SUBJECT_SET_CURRENT = "OPEN_CLOSE_INVITE_MODAL"
export const SUBJECT_SET_DATA = "SUBJECT_SET_DATA"

export const SET_PROTOCOL_DATA = "SET_PROTOCOL_DATA"
export const ADD_PROTOCOL_IMAGE = "ADD_PROTOCOL_IMAGES"
export const ADD_PROTOCOL_ACTION = "ADD_PROTOCOL_ACTIONS"
export const EDIT_PROTOCOL_ITEM = "EDIT_PROTOCOL_ITEM"
export const CHANGE_PROTOCOL_ITEM_NAME = "CHANGE_PROTOCOL_ITEM_NAME"
export const CHANGE_PROTOCOL_ITEM_VALUE = "CHANGE_PROTOCOL_ITEM_VALUE"
export const ADD_PROTOCOL_IMAGE_CONDITION = "ADD_PROTOCOL_IMAGE_CONDITION"
export const REMOVE_PROTOCOL_IMAGE_CONDITION = "ADD_PROTOCOL_IMAGE_CONDITION"
export const CHANGE_IMAGE_CONDITION = "CHANGE_IMAGE_CONDITION"
export const CHANGE_GUIDANCE_TEXT = "CHANGE_GUIDANCE_TEXT"
export const SET_DATA_FOR_REGISTRATION = "SET_DATA_FOR_REGISTRATION"
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE"

export const SET_FILE = "SET_FILE"

export const GET_WORKLIST = "GET_WORKLIST"
export const GET_CURRENT_TASK = "GET_CURRENT_TASK"
export const CLEAR_CURRENT_TASK_FORM = "CLEAR_CURRENT_TASK_FORM"
export const CLEAR_CURRENT_MEASUREMENT = "CLEAR_CURRENT_MEASUREMENT"
export const SET_CURRENT_LINE_MEASUREMENT = "SET_CURRENT_LINE_MEASUREMENT"
export const GET_CURRENT_TASK_ENDPOINT = "GET_CURRENT_TASK_ENDPOINT"
export const GET_CURRENT_ADJ_TASK_ENDPOINT = "GET_CURRENT_ADJ_TASK_ENDPOINT"
export const SET_MATRIX_DATA = "SET_MATRIX_DATA"

/* PROJECTS */

export const RECEIVE_DATA = "RECEIVE_DATA"
export const CLEAR_LIST = "CLEAR_LIST"
export const FETCHING_DATA = "FETCHING_DATA"
export const INVALIDATE_DATA = "INVALIDATE_DATA"
export const RECEIVE_SESSION_PROFILE_DATA_IMAGES =
  "RECEIVE_SESSION_PROFILE_DATA_IMAGES"
export const RECEIVE_SESSION_PROFILE_DATA_OTHER =
  "RECEIVE_SESSION_PROFILE_DATA_OTHER"
export const SUCCESS_CHANGE_PROJECT = "SUCCESS_CHANGE_PROJECT"
export const GET_FORM_TYPES = "GET_FORM_TYPES"

/* CONSTANTS FOR TABLES */

/* ACTIVITY_TABLE */

export const ACTIVITY_TABLE_FETCHING_DATA = "ACTIVITY_TABLE_FETCHING_DATA"
export const ACTIVITY_TABLE_RECEIVE_DATA = "ACTIVITY_TABLE_RECEIVE_DATA"

/* ANALYSIS_HISTORY_TABLE */

export const ANALYSIS_HISTORY_TABLE_FETCHING_DATA =
  "ANALYSIS_HISTORY_TABLE_FETCHING_DATA"
export const ANALYSIS_HISTORY_TABLE_RECEIVE_DATA =
  "ANALYSIS_HISTORY_TABLE_RECEIVE_DATA"

/* ANALYSIS_INFO_TABLE */

export const ANALYSIS_INFO_TABLE_FETCHING_DATA =
  "ANALYSIS_INFO_TABLE_FETCHING_DATA"
export const ANALYSIS_INFO_TABLE_RECEIVE_DATA =
  "ANALYSIS_INFO_TABLE_RECEIVE_DATA"

/* QC_HISTORY_TABLE */

export const CHANGE_QC_HISTORY_TABLE_RECEIVE_DATA =
  "CHANGE_QC_HISTORY_TABLE_RECEIVE_DATA"

/* COLLABORATORS_TABLE_ */

export const COLLABORATORS_TABLE_INVALIDATE_DATA =
  "COLLABORATORS_TABLE_INVALIDATE_DATA"

/* LIST_RESULT_FILES_TABLE */

export const LIST_RESULT_FILES_TABLE_FETCHING_DATA =
  "LIST_RESULT_FILES_TABLE_FETCHING_DATA"
export const LIST_RESULT_FILES_TABLE_RECEIVE_DATA =
  "LIST_RESULT_FILES_TABLE_RECEIVE_DATA"

/* PROGRESS_MONITORING_TABLE */

export const PROGRESS_MONITORING_TABLE_RECEIVE_DATA =
  "PROGRESS_MONITORING_TABLE_RECEIVE_DATA"

/* QC_HISTORY_TABLE */

export const QC_HISTORY_TABLE_FETCHING_DATA = "QC_HISTORY_TABLE_FETCHING_DATA"
export const QC_HISTORY_TABLE_RECEIVE_DATA = "QC_HISTORY_TABLE_RECEIVE_DATA"

/* SESSION_PROFILE_TABLE */

export const SESSION_PROFILE_TABLE_RECEIVE_DATA =
  "SESSION_PROFILE_TABLE_RECEIVE_DATA"
export const SESSION_PROFILE_TABLE_FETCHING_DATA =
  "SESSION_PROFILE_TABLE_FETCHING_DATA"
export const SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_IMAGES =
  "SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_IMAGES"
export const SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_OTHER =
  "SESSION_PROFILE_TABLE_RECEIVE_SESSION_PROFILE_DATA_OTHER"

/* SESSION_TABLE */

export const SESSION_TABLE_RECEIVE_DATA = "SESSION_TABLE_RECEIVE_DATA"
export const SESSION_TABLE_FETCHING_DATA = "SESSION_TABLE_FETCHING_DATA"

/* SUBJECT_TABLE */

export const SUBJECT_TABLE_RECEIVE_DATA = "SUBJECT_TABLE_RECEIVE_DATA"
export const SUBJECT_TABLE_FETCHING_DATA = "SUBJECT_TABLE_FETCHING_DATA"

/* SITES_TABLE */

export const SITES_TABLE_RECEIVE_DATA = "SITES_TABLE_RECEIVE_DATA"
export const SITES_TABLE_FETCHING_DATA = "SITES_TABLE_FETCHING_DATA"
export const SITES_TABLE_INVALIDATE_DATA = "SITES_TABLE_INVALIDATE_DATA"

/* PROJECT_LIST */

export const PROJECT_LIST_RECEIVE_DATA = "PROJECT_LIST_RECEIVE_DATA"
export const PROJECT_LIST_FETCHING_DATA = "PROJECT_LIST_FETCHING_DATA"
export const PROJECT_LIST_CLEAR_LIST = "PROJECT_LIST_CLEAR_LIST"

export const PROJECT_OWNER_ROLE_NAME = "Clinical Trial Owner"

/* DATE FORMATS */

export const DATE_FORMAT = "yyyy-MM-dd"
export const DATE_FORMAT_WITH_SECONDS = "yyyy-MM-dd'T'HH:mm:ss"
