import { NotificationsActionTypes } from ".."
import { IEnqueueSnackbar, IRemoveSnackbar } from "../constants"

const initialState = {
  notifications: [],
}

interface IInitialState {
  notifications: any[]
}

type Action = IRemoveSnackbar | IEnqueueSnackbar

const messages = (state: IInitialState = initialState, action: Action) => {
  switch (action.type) {
    case NotificationsActionTypes.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      }

    case NotificationsActionTypes.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      }

    default:
      return state
  }
}

export default messages
