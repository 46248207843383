import * as React from "react"

export const renderTextarea = ({
  input,
  label,
  className,
  disabled,
  placeholder,
  maxLength,
  type,
  meta: { touched, error },
}) => (
  <div>
    <label>{label}</label>
    <div>
      <textarea
        {...input}
        placeholder={placeholder}
        className={touched && error ? className + " is-danger" : className}
        disabled={disabled}
        maxLength={maxLength}
      />
      {touched && error && <p className="help is-danger">{error}</p>}
    </div>
  </div>
)
