import * as React from "react"
import Grid from "@material-ui/core/Grid"
import { compose } from "recompose"
import { connect } from "react-redux"
import { Card, CardContent, withStyles } from "@material-ui/core"
/* REFACTOR TABLE PENDING */
// import Table from "../../components/common/Table/table";
import { fetchProgress } from "../../actions/progressMonitoringTable"
import "./progress-monitoring.sass"
import iconSet from "../../styles/fonts/selection.json"
import IcomoonReact from "icomoon-react"
import EmptyTableMessage from "../../utils/empty-table-message"

const styles = (theme) => ({
  title: {
    marginBottom: "8px",
  },
  iconDescriptionContainer: {
    marginTop: "24px",
  },
  iconDescriptionContainerRow: {
    marginBottom: "8px",
  },
  iconDescriptionContainerCell: {
    display: "flex",
  },
  iconCard: {
    paddingBottom: "16px",
  },
  row: {
    cursor: "not-allowed",
  },
})

class ProgressMonitoringPage extends React.Component<any, any> {
  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<any>,
    nextContext: any
  ): void {
    const {
      current,
      progressMonitoringTable: { isInvalidated, isFetching },
    } = nextProps
    if (isInvalidated && !isFetching) {
      this._fetchData(current.project.data._id)()
    }
  }

  _fetchData = (projectId) => {
    return (pageParams: any = null) => {
      if (pageParams === null) {
        pageParams = {
          pageSize: this.props.progressMonitoringTable.page.pageSize,
          page: 0,
          sorted: [],
        }
      }
      return this.props.fetchProgress(projectId, pageParams)
    }
  }
  render() {
    const { classes, progressMonitoringTable } = this.props
    return (
      <Grid container className="container">
        <Grid item xs={12}>
          <h1 className={"h1 " + classes.title}>
            Progress monitoring &nbsp;
            {progressMonitoringTable.isFetching ? (
              <img
                className="base-margin-half"
                width="24px"
                height="24px"
                src="assets/icons/loading-image.gif"
                alt="loading"
              />
            ) : null}
          </h1>
          <label>
            You are monitoring {progressMonitoringTable.data.length} active
            sessions
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {/* <Table
                        resizable={false}
                        sortable={false}
                        withSelection={false}
                        filterable={false}
                        dynamicColumn={false}
                        hide={progressMonitoringTable.empty}

                        columns={progressMonitoringTable.columns}
                        pages={progressMonitoringTable.page.pages}
                        loading={progressMonitoringTable.isFetching}
                        data={progressMonitoringTable.data}

                        source={{
                            model: progressMonitoringTable,
                            onFetchData: this._fetchData(this.props.match.params.project_id)
                        }}

                        tableId="progress_monitoring_table"
                        clickableRow={false}
                    /> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className="align-center"
          style={
            progressMonitoringTable.empty
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <EmptyTableMessage
            fetching={progressMonitoringTable.isFetching}
            title="monitoring list"
            text={`Your monitoring list is currently empty`}
          />
        </Grid>

        <Grid item xs={12} className={classes.iconDescriptionContainer}>
          <h2 className={classes.title + " h2"}>Icon description</h2>
          <Card>
            <CardContent className={classes.iconCard}>
              <Grid container className={classes.iconDescriptionContainerRow}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <i className="material-icons is-okay">check_circle_outline</i>
                  Quality Control has passed
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    color="#2CA895"
                    size={24}
                    icon="screen-accept"
                  />
                  Analysis has finished
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    color="#2CA895"
                    size={24}
                    icon="user-accept"
                  />
                  Analysis has been approved by the user
                </Grid>
              </Grid>
              <Grid container className={classes.iconDescriptionContainerRow}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <i className="material-icons is-warning">warning</i>
                  Quality Control has failed
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    color="#9B1A06"
                    size={24}
                    icon="screen-reject"
                  />
                  Analysis could not be completed
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  className={classes.iconDescriptionContainerCell}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    color="#9B1A06"
                    size={24}
                    icon="user-reject"
                  />
                  Analysis has been rejected by the user
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = ({ current, progressMonitoringTable }) => ({
  current,
  progressMonitoringTable,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProgress: (project_id, pageParams) =>
      dispatch(fetchProgress(project_id, pageParams)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ProgressMonitoringPage)
