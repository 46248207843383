export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR"
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR"

export const SUCCESS = "success"
export const ERROR = "error"
export const WARNING = "warning"

export interface IEnqueueSnackbar {
  type: "ENQUEUE_SNACKBAR"
  notification: any
}

export interface IRemoveSnackbar {
  type: "REMOVE_SNACKBAR"
  key: number
}
