import * as React from "react"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

export const renderAgeRadioButtons = ({
  input,
  data,
  defaultValue,
  editStudyForm,
}) => {
  function handleChange(event: React.ChangeEvent<unknown>, value: string) {
    editStudyForm.values.age_measure = data.find((labelAndVal) => {
      return labelAndVal.value === value
    })
  }

  return (
    <RadioGroup
      aria-label="age unit"
      name={input.name}
      defaultValue={defaultValue}
      onChange={handleChange}
      row
    >
      <FormControlLabel
        value="month"
        control={<Radio color="default" />}
        label="Months"
      />
      <div className={"base-margin-double-right"} />

      <FormControlLabel
        value="year"
        control={<Radio color="default" />}
        label="Years"
      />
    </RadioGroup>
  )
}
