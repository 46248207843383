import React from "react"
import * as ReactDOM from "react-dom"
import "./index.sass"

import configureStore from "./store"
import { Provider } from "react-redux"
import { Route, Router } from "react-router-dom"
import history from "./utils/history"
import withGATracker from "./utils/analytics"
import { getLaunchDarklyUser, ldClientSideId } from "./utils/launch-darkly"

import AuthorizationApp from "./components/AuthorizationApp"
import { SnackbarProvider } from "notistack"

const appStore = configureStore()

ReactDOM.render(
  <React.Fragment>
    <Provider store={appStore}>
      <SnackbarProvider maxSnack={3}>
        <Router history={history}>
          <Route component={withGATracker(AuthorizationApp)} />
        </Router>
      </SnackbarProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
)
