import {
  SET_CAN_UPLOAD_DATA,
  SET_CURRENT_ANALYSIS,
  SET_CURRENT_BREADCRUMB,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_ACTIVITY_LOG,
  SET_CURRENT_PROJECT_FETCHING,
  UPDATE_TAGS,
} from "../constants/constants"

export const setCurrentBreadcrumb = (currentBreadcrumbId) => ({
  type: SET_CURRENT_BREADCRUMB,
  payload: currentBreadcrumbId,
})

export const setCanUploadData = (can_upload_data) => ({
  type: SET_CAN_UPLOAD_DATA,
  payload: can_upload_data,
})
export const setCurrentProject = (project) => ({
  type: SET_CURRENT_PROJECT,
  payload: project,
})

export const setCurrentProjectFetching = (val) => ({
  type: SET_CURRENT_PROJECT_FETCHING,
  payload: val,
})

export const setCurrentProjectActivityLog = (val) => ({
  type: SET_CURRENT_PROJECT_ACTIVITY_LOG,
  payload: val,
})

export const setCurrentAnalysis = (data) => ({
  type: SET_CURRENT_ANALYSIS,
  payload: data,
  loaded: true,
})

export const updateTagsReducer = (tags) => ({
  type: UPDATE_TAGS,
  payload: tags,
})
