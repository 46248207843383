import {
  OPEN_CLOSE_INVITE_MODAL,
  SET_COLLABORATORS,
  SET_CURRENT_COLLABORATOR,
} from "../constants/constants"

export const setCollaborators = (data) => ({
  type: SET_COLLABORATORS,
  payload: data,
})
export const setCurrentCollaborator = (data) => ({
  type: SET_CURRENT_COLLABORATOR,
  payload: data,
})
export const openCloseInviteModal = (data) => ({
  type: OPEN_CLOSE_INVITE_MODAL,
  payload: data,
})
