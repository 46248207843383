import * as React from "react"
import linkAutoActive from "../../../assets/meddream-icons/link-a-active.svg"
import linkAuto from "../../../assets/meddream-icons/link-a.svg"

import "../../icons/styling-icons.sass"

interface ILinkInteractions {
  linkIcon: boolean
  handleActiveIcon: any
}

const LinkIcon = (props: ILinkInteractions) => {
  const { linkIcon, handleActiveIcon } = props
  const globalViewport: any = global

  const handleIcon = () => {
    const containerIds: any = []
    globalViewport.viewportsCore.getViewportsList().forEach((viewport) => {
      containerIds.push(viewport.containerId)
    })
    if (linkIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.removeFromSync(containerIds)
      globalViewport.viewportsCore.disableScrollLock()
    } else {
      handleActiveIcon("link")
      globalViewport.viewportsCore.addToSync(containerIds)
      const checkIfActiveViewportIsGood =
        globalViewport.viewportsCore.canCreateScrollLockGroupWithActiveViewport()
      if (checkIfActiveViewportIsGood)
        globalViewport.viewportsCore.enableScrollLock(
          checkIfActiveViewportIsGood
        )
      globalViewport.viewportsCore.enableSync(
        (functionName: string, parameters: any) => {
          if (functionName === "zoom-changed") {
            globalViewport.viewportsCore.applyZoomForLockedViews(
              parameters.containerId,
              parameters.scale,
              parameters.point
            )
          }
          if (functionName === "image-position-changed") {
            globalViewport.viewportsCore.applyPanForLockedViews(
              parameters.containerId,
              parameters.imagePosition["x"],
              parameters.imagePosition["y"]
            )
          }
        }
      )
    }
  }

  return (
    <div className={`container-icons ${linkIcon && "active"}`}>
      <div className="study-top-btn" onClick={() => handleIcon()}>
        <img
          width="30px"
          height="30px"
          src={linkIcon ? linkAutoActive : linkAuto}
          alt="pan"
        />
        <h5 style={{ color: linkIcon ? "#2CA895" : "#666" }}>Link</h5>
      </div>
    </div>
  )
}

export default LinkIcon
