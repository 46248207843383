import {
  SUBJECT_TABLE_FETCHING_DATA,
  SUBJECT_TABLE_RECEIVE_DATA,
} from "../constants/constants"

const formatReceiveData = (action) => {
  return action.payload.data.data.containers.map((el) => ({
    session_id: el.ssid,
    name: el.name || "-",
    type: el.type_name || "-",
    scan_date: el.date
      ? new Date(el.date.$date).toISOString().substring(0, 10)
      : "-",
  }))
}

const initialState = {
  columns: [
    {
      Header: "ID",
      maxWidth: 50,
      accessor: "session_id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Upload Date",
      maxWidth: 150,
      accessor: "scan_date",
    },
  ],
  filterPanel: false,
  empty: false,
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBJECT_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case SUBJECT_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
