import { SESSION_PROFILE_TABLE_RECEIVE_DATA } from "../constants/constants"

const initialState = {
  item: {
    ssid: "",
    patient_secret_name: "",
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_PROFILE_TABLE_RECEIVE_DATA:
      return {
        ...state,
        item: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
