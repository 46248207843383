import * as React from "react"
import Breadcrumbs from "./breadcrumb"
import MuiBreadCrumbs from "./../../../components/common/breadcrumbsContext/materialBreadcrumb"
import history from "../../../utils/history"
import "./navigation.sass"
class Navigation extends React.Component<any, any> {
  render() {
    const path = history.location.pathname
    const isDashboard = path === "/project" || path.includes("profile")

    return (
      <React.Fragment>
        <div style={{ padding: "16px" }}>
          {!isDashboard ? (
            <div className="container-breadcrumb">
              <MuiBreadCrumbs />
            </div>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default Navigation
