import { NotificationsActions } from ".."

export default (select = ({ meta }) => meta.notification) =>
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const returnValue = next(action)

    if (!action || !action.meta) {
      return returnValue
    }

    const event = select(action)
    if (!event) {
      return returnValue
    }

    const actionType = NotificationsActions[event.type](event.message)

    dispatch(actionType)

    return returnValue
  }
