import {
  API_CALL,
  API_CALL_PAGINATION,
  RECEIVE_PROJECT_CONFIG,
  RECEIVE_FORMATED_PROJECT_CONFIG,
  SET_ANALYSIS_LIST,
  ACTIVITY_TABLE_FETCHING_DATA,
  ACTIVITY_TABLE_RECEIVE_DATA,
  GET_FORM_TYPES,
} from "../constants/constants"
import { setSession } from "./sessionAction"
import { setCanUploadData } from "./currentAction"
import { setGuidanceText, setProtocolData } from "./protocols"
import { reset } from "redux-form"
import { NotificationsActions } from "./../utils/Notifications/index"
import history from "../utils/history"

const callAPI = (url, method = "GET", postData = null, headers = {}) => ({
  type: API_CALL,
  url,
  method,
  postData,
  headers,
})

const callPaginationAPI = (
  url,
  method = "GET",
  postData = null,
  headers = {}
) => ({
  type: API_CALL_PAGINATION,
  url,
  method,
  postData,
  headers,
})

const fetchPage =
  (url, { pageSize, page, sorted }) =>
  (dispatch) => {
    const range = {
      from: pageSize * page,
      to: pageSize * page + pageSize - 1,
    }

    if (sorted && sorted.id !== "" && sorted.order !== "") {
      url +=
        (url.indexOf("?") === -1 ? "?" : "&") +
        "sort(" +
        (sorted.order === "desc" ? "-" : "+") +
        sorted.id +
        ")"
    }

    return dispatch(
      callPaginationAPI(url, "GET", null, {
        "X-Range": "items=" + range.from + "-" + range.to,
      })
    )
      .then(({ _page, data }) => {
        const page = _page
        page.pages = Math.ceil(page.total / pageSize)
        return {
          data,
          page,
        }
      })
      .catch((error) => error)
  }

export const getMe = () => {
  return (dispatch) => {
    return dispatch(callAPI("/me", "GET"))
      .then((data) => {
        localStorage.setItem("qmenta-global-state", data.user_data)
        return dispatch(setSession(data))
      })
      .catch((error) => error)
  }
}

export const postProject = (values, isEdit?) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/trials_project_create", "POST", values))
    .then(() => {
      if (isEdit) {
        dispatch(reset("editStudyForm"))
        dispatch(
          NotificationsActions.success("Your project was successfully updated!")
        )
      } else {
        dispatch(reset("createStudyForm"))
        dispatch(
          NotificationsActions.success("Your project was successfully created!")
        )
        history.push("/project")
      }
    })
    .catch((error) => error)

export const createSite = (values) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/upsert_site", "POST", values)).catch(
    (error) => error
  )

export const changeUserData = (values) => (dispatch) =>
  dispatch(callAPI("/a_user_manager/change_user_data", "POST", values))
    .then((data) => {
      return data
    })
    .catch((error) => error)

export const getNiftiViewerFile = (values) => (dispatch) =>
  dispatch(
    callAPI("/file_manager/get_nifti_viewer_file", "POST", values)
  ).catch((error) => error)

export const fetchPatients = (projectId, pageParams) => (dispatch) =>
  dispatch(
    fetchPage("/patient_manager/get_patient_list?_pid=" + projectId, pageParams)
  ).catch((error) => error)

export const getProjectSetList = () => (dispatch) =>
  dispatch(callAPI("/projectset_manager/get_projectset_list")).catch(
    (error) => error
  )

export const getFormTypes = () => {
  return (dispatch) => 
    dispatch(
      callAPI("/projectset_manager/load_create_trials_project")
    )
    .then(({data}) => {
      dispatch({
        type: GET_FORM_TYPES,
        payload: data.properties.form_id?.enum
      })
    })
    .catch((error) => {
      dispatch(NotificationsActions.error("Something went wrong!"))
      throw error
    })
}

export const getProjectData = (id: any) => {
  return (dispatch) =>
    dispatch(
      callAPI("/projectset_manager/get_project_data?project_id=" + id)
    ).catch((error) => {
      history.push("/project")
      dispatch(NotificationsActions.error("You are not part of this project!"))
      throw error
    })
}

export const getProjectModuleConfig = () => (dispatch) =>
  dispatch(callAPI("/projectset_manager/module_config"))
    .then((data) =>
      dispatch({
        type: RECEIVE_PROJECT_CONFIG,
        payload: data,
      })
    )
    .catch((error) => error)

export const projectSetModuleConfig = (projectData) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/module_config"))
    .then((response) => {
      const data: any = []
      projectData = projectData.filter((project) => {
        return (
          project.project_type === "central_reading" ||
          project.project_type === "clinical_trial"
        )
      })
      projectData.map((project) => {
        data.push(
          response.project_types.reduce((acc, current) => {
            if (current.value === project.project_type) {
              current.roles.map((role) => {
                if (project.access.some((item) => item === role[0])) {
                  acc = { project: project.name, role: role[6] }
                }
              })
            }
            return acc
          }, {})
        )
      })
      return dispatch({
        type: RECEIVE_FORMATED_PROJECT_CONFIG,
        roles: data,
      })
    })
    .catch((error) => error)

export const getPatientProfileData = (projectId, pageParams) => (dispatch) =>
  dispatch(
    fetchPage(
      `/patient_manager/get_patient_profile_data?_pid=${projectId._pid}&patient_id=${projectId.patient_id}`,
      pageParams
    )
  ).catch((error) => error)

export const getPatientData = (projectId, patientId) => (dispatch) =>
  dispatch(
    callAPI(
      "/patient_manager/get_patient_list?_pid=" +
        projectId +
        "&patient_id=" +
        patientId
    )
  ).catch((error) => error)

export const setSubscriptionState = (values) => (dispatch) =>
  dispatch(callAPI("/a_user_manager/set_subscription_state", "POST", values))
    .then((response) => response.data)
    .catch((error) => error)

export const getSubscriptionState =
  (callback = (result) => result) =>
  (dispatch) =>
    dispatch(callAPI("/a_user_manager/get_subscription_state", "POST"))
      .then((data) => {
        callback(data)
      })
      .catch((error) => error)

export const getSessionFiles = (containerId, pageParams) => {
  return (dispatch) =>
    dispatch(
      fetchPage(
        `/file_manager/get_container_files?container_id=${containerId}`,
        pageParams
      )
    )
      .then((res) => {
        let filtered_data = res.data.data.meta.reduce(
          (acc, curr) => {
            if (
              curr.metadata.format === "nifti" ||
              curr.metadata.format === "dicom"
            ) {
              acc.images.push(curr)
            } else {
              acc.other_files.push(curr)
            }
            return acc
          },
          { images: [], other_files: [] }
        )
        filtered_data = { ...filtered_data, page: { ...res.page } }
        return filtered_data
      })
      .catch((error) => error)
}

export const getListResultFiles = (containerId, pageParams) => {
  return (dispatch) =>
    dispatch(
      fetchPage(
        `/file_manager/get_container_files?container_id=${containerId}`,
        pageParams
      )
    )
      .then((res) => {
        return {
          data: res.data.data.meta,
          page: { ...res.page },
        }
      })
      .catch((error) => error)
}

export const changeUserPreference = (values) => (dispatch) =>
  dispatch(
    callAPI("/a_user_manager/change_user_preferences", "POST", values)
  ).catch((error) => error)
export const fetchSites = (projectId, pageParams) => (dispatch) =>
  dispatch(
    fetchPage("/projectset_manager/get_sites?_pid=" + projectId, pageParams)
  ).catch((error) => error)

export const removeSite = (id) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/remove_site", "POST", id)).catch(
    (error) => error
  )

export const addNewCollaborator = (collab_data) => (dispatch) =>
  dispatch(
    callAPI("/projectset_manager/upsert_project_member", "POST", collab_data)
  )
    .then((res) => res)
    .catch((error) => error)

export const removeCollaboratorApi = (val) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/remove_project_member", "POST", val))
    .then((res) => res.data)
    .catch((error) => error)
export const findUserByEmail = (val) => (dispatch) =>
  dispatch(callAPI("/a_user_manager/find_user_by_mail", "POST", val)).catch(
    (e) => {
      return {
        success: 0,
      }
    }
  )
export const inviteUserByEmail = (val) => (dispatch) =>
  dispatch(callAPI("/a_user_manager/invite_user_by_mail", "POST", val)).then(
    (res) => {
      return res.data
    }
  )

export const getAnalysisConfig = () => (dispatch) =>
  dispatch(callAPI("/analysis_manager/module_config", "GET"))
    .then((res) => {
      const data = res.analysis_types.reduce((acc, curr) => {
        if (curr.type === "data" || curr.default === 1) {
          // FIXME The one with default === 1 should be selected in the dropdown
          // as default value
          acc.push({
            label: curr.label,
            value: curr.full_code,
            default: curr.default,
          })
        }
        return acc
      }, [])
      dispatch({
        type: SET_ANALYSIS_LIST,
        payload: data,
      })
    })
    .catch((error) => error)

export const saveQASessionRequirements = (project_id, val: any) => (dispatch) =>
  dispatch(
    callAPI(
      "/projectset_manager/set_session_qa_requirements?project_id=" +
        project_id,
      "POST",
      val
    )
  ).catch((error) => error)

export const setPatientInfo = (data) => (dispatch) =>
  dispatch(callAPI("/patient_manager/upsert_patient", "POST", data)).catch(
    (error) => error
  )

export const old_getActivityList = (projectId, pageParams) => (dispatch) =>
  dispatch(
    fetchPage(
      "/projectset_manager/get_activity_list?_pid=" + projectId,
      pageParams
    )
  ).catch((error) => error)

export const getActivityList = (projectId) => (dispatch) => {
  dispatch({
    type: ACTIVITY_TABLE_FETCHING_DATA,
  })
  return dispatch(
    callAPI("/projectset_manager/get_activity_list?_pid=" + projectId)
  )
    .then((response) => {
      dispatch({
        type: ACTIVITY_TABLE_RECEIVE_DATA,
        payload: response,
      })
    })
    .catch((error) => error)
}

export const getQCHistoryList = (item_id, pageParams) => (dispatch) =>
  dispatch(
    fetchPage(
      `/projectset_manager/get_qa_changes?item_id=${item_id}&param=qa&entity=patients`,
      pageParams
    )
  ).catch((error) => error)

export const getActivityListNumber = (projectId) => (dispatch) =>
  dispatch(
    callAPI("/projectset_manager/get_activity_list?_pid=" + projectId, "POST")
  )
    .then((res) => {
      return res.length
    })
    .catch((error) => error)

export const deleteSession = (data) => (dispatch) =>
  dispatch(callAPI("/patient_manager/delete_patient", "POST", data)).catch(
    (error) => error
  )

export const getSessionQARequirements = (id) => (dispatch) =>
  dispatch(
    callAPI("/projectset_manager/get_session_qa_requirements", "POST", id)
  )
    .then((res) => {
      const filteredData = res.data.rules.reduce(
        (acc, curr) => {
          curr.edit = false
          if (curr.conditions.length > 0) {
            acc.images.push({
              edit: curr.edit,
              id: curr._id,
              name: curr.name,
              conditions: [...curr.conditions],
            })
          } else {
            acc.actions.push({
              edit: curr.edit,
              id: curr._id,
              name: curr.name,
              conditions: [...curr.conditions],
            })
          }
          return acc
        },
        { images: [], actions: [] }
      )
      dispatch(setGuidanceText(res.data.guidance_text))
      dispatch(setProtocolData(filteredData))
      return res.data.rules
    })
    .catch((error) => error)

export const changeQcStatusApi = (data) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/set_qa_status", "POST", data)).catch(
    (error) => error
  )

export const getFileInfoImage = (data) => (dispatch) =>
  dispatch(
    callAPI(
      `/file_manager/download_file_metadata?type=mosaic&container_id=${data.container_id}&filename=${data.imageName}&x_session_verify=${data.session_token}`,
      "GET"
    )
  ).catch((error) => error)

export const getFileInfo = (containerId) => (dispatch) =>
  dispatch(
    callAPI(
      `/file_manager/get_container_files?container_id=${containerId}`,
      "POST"
    )
  ).catch((error) => error)

export const deleteFile = (data) => (dispatch) =>
  dispatch(
    callAPI(
      `/file_manager/delete_files?container_id=${data.container_id}&files=${data.delete_files}`,
      "POST"
    )
  )
    .then((res) => {
      return res
    })
    .catch((res) => {
      return res
    })

export const getAnalysisProgress = (projectId, pageParams) => {
  return (dispatch) =>
    dispatch(
      fetchPage(
        `/analysis_manager/get_analysis_progress?project_id=${projectId}`,
        pageParams
      )
    ).catch((error) => error)
}

export const getAnalysisComments = (itemId, pageParams) => {
  return (dispatch) =>
    dispatch(
      fetchPage(
        `/comment_manager/get_comments?type=analysis&item_id=${itemId}`,
        pageParams
      )
    ).catch((error) => error)
}

export const getAnalysisList = (projectId) => {
  return (dispatch) =>
    dispatch(
      callAPI(
        `/analysis_manager/get_analysis_progress?project_id=${projectId}`,
        "POST"
      )
    ).catch((error) => error)
}

export const addNewComment = (item_id, comment) => {
  return (dispatch) =>
    dispatch(
      callAPI(
        `/comment_manager/insert_comment?item_id=${item_id}&comment=${comment}&type=analysis`,
        "POST"
      )
    ).catch((error) => error)
}

export const updateTags = (data) => {
  return (dispatch) =>
    dispatch(
      callAPI("/analysis_manager/edit_analysis", "POST", { ...data })
    ).catch((error) => error)
}

export const getStudyProgress = (data) => {
  return (dispatch) =>
    dispatch(
      callAPI("/analysis_manager/edit_analysis", "POST", { ...data })
    ).catch((error) => error)
}

export const getAnalysisHistoryList = (item_id, pageParams) => (dispatch) =>
  dispatch(
    fetchPage(
      `/projectset_manager/get_qa_changes?item_id=${item_id}&entity=analysis&param=qa`,
      pageParams
    )
  ).catch((error) => error)

export const changeAnalysisStatus = (data) => (dispatch) =>
  dispatch(callAPI("/projectset_manager/set_qa_status", "POST", data)).catch(
    (error) => error
  )

export const updateFile = (data) => {
  return (dispatch) =>
    dispatch(callAPI("/file_manager/edit_file", "POST", { ...data })).catch(
      (error) => error
    )
}

export const hasReport = (_pid, project_id) => {
  return (dispatch) =>
    dispatch(
      callAPI(
        `/analysis_manager/has_report?_pid=${_pid}&project_id=${project_id}&script_name=colinkingswoodqmentacom_my_tool`,
        "POST"
      )
    ) // TODO, this looks wrong - hard coded tool name
      .catch((error) => error)
}

export const downloadReport = (data) => {
  return (dispatch) =>
    dispatch(callAPI("/file_manager/download_file", "POST", data)).catch(
      (error) => error
    )
}

export const getTransformedResults = (container_id, filename) => {
  return (dispatch) =>
    dispatch(
      callAPI(
        "/file_manager/get_transformed_result_file?container_id=" +
          container_id +
          "&name=" +
          filename,
        "GET"
      )
    ).then((response) => {
      return response
    })
}
