import React from "react"
import {
  LIST_RESULT_FILES_TABLE_FETCHING_DATA,
  LIST_RESULT_FILES_TABLE_RECEIVE_DATA,
} from "../constants/constants"
import { Chip } from "@material-ui/core"

const getFileSize = (size: number): string => {
  const fSExt = ["Bytes", "KB", "MB", "GB"]
  let fSize = size
  let i = 0
  while (fSize > 900) {
    fSize /= 1024
    i++
  }
  return Math.round(fSize * 100) / 100 + " " + fSExt[i]
}

const renderTags = (tags) => {
  if (tags && tags.length > 0) {
    return (
      <div>
        {tags.map((item, idx) => {
          return <Chip key={idx} label={item} />
        })}
      </div>
    )
  } else {
    return <div />
  }
}

const formatReceiveData = (action) => {
  return action.payload.data.map((el) => {
    return {
      tags: el.tags,
      name: el.name || "-",
      size: getFileSize(el.size),
    }
  })
}

const initialState = {
  columns: [
    {
      Header: "Name",
      maxWidth: 250,
      accessor: "name",
      id: "name",
      show: true,
      sortField: "name",
    },
    {
      Header: "Size",
      maxWidth: 150,
      accessor: "size",
      id: "size",
      show: true,
      sortField: "size",
    },
    {
      Header: "Tags",
      maxWidth: 150,
      accessor: "tags",
      id: "tags",
      className: "tags-row",
      show: true,
      sortField: "tags",
      Cell: (row) => {
        return <div>{renderTags(row.tags)}</div>
      },
    },
  ],
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_RESULT_FILES_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case LIST_RESULT_FILES_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
