import * as React from "react"
import zoom from "../../../assets/meddream-icons/zoom.svg"
import zoomactive from "../../../assets/meddream-icons/zoomactive.svg"
import dropdownIcon from "../../../assets/meddream-icons/dropdownicon.svg"
import dropdownIconActive from "../../../assets/meddream-icons/dropdowniconactive.svg"
import mouse from "../../../assets/meddream-icons/mouse.svg"

import "../../icons/styling-icons.sass"

interface IZoom {
  zoomIcon: boolean
  handleActiveIcon: any
  dropdown: string
  handleDropdown: any
}

const ZoomIcon = (props: IZoom) => {
  const { dropdown, handleDropdown, zoomIcon, handleActiveIcon } = props
  const globalViewport: any = global

  const handleZoomChange = () => {
    if (zoomIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 0)
    } else {
      handleActiveIcon("zoom")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 2)
    }
  }

  return (
    <div className={`container-icons ${zoomIcon && "active"}`}>
      <div className="study-top-btn" onClick={() => handleZoomChange()}>
        <img
          width="30px"
          height="30px"
          src={zoomIcon ? zoomactive : zoom}
          alt="zoom"
        />
        <h5 style={{ color: zoomIcon ? "#2CA895" : "#666" }}>Zoom</h5>
      </div>

      {/* <div className="study-top-dropdown">
        <img
          onClick={() => handleDropdown()}
          className="top-btn-drop-down"
          width="15px"
          height="15px"
          src={zoomIcon ? dropdownIconActive : dropdownIcon}
          alt="drop-down"
        />
      </div>
      <div className="dropdown-container">
        {dropdown && (
          <div>
            <p>Hello</p>
          </div>
        )}
      </div> */}
    </div>
  )
}

export default ZoomIcon
