import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core"
import { connect } from "react-redux"
import history from "../../../utils/history"
import { setCurrentAnalysis } from "../../../actions/currentAction"

const ITEM_HEIGHT = 48

const styles = (theme) => ({
  icon: {
    padding: "0px",
  },
})

const LongMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleOptionClick() {
    if (props.type === "qc") {
      const url = `/project/${props.match.params.project_id}/data/session/${props.data.id},${props.data.patient_secret_name},${props.data.container_id}`
      history.push(url)
    } else {
      props.setCurrentAnalysis(props.data)
      props.history.push(props.option.url)
    }
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        classes={{ root: props.classes.icon }}
        onClick={handleClick}
        disabled={!props.data.analysis_id && props.type === "analysis"}
        id={props.id}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        <MenuItem onClick={handleOptionClick}>{props.option.name}</MenuItem>
      </Menu>
    </div>
  )
}

const mapStateToProps = ({ sessionTable, current }) => ({
  sessionTable,
  current,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentAnalysis: (data) => dispatch(setCurrentAnalysis(data)),
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LongMenu)
