import * as React from "react"
import "./project-list-page.sass"

import { connect } from "react-redux"

import { fetchList } from "../../actions/ProjectListAction"
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core"

import EmptyTableMessage from "../../utils/empty-table-message"

interface IProps {
  fetchProjects: () => {}
  classes: any
  projects: any
  project_config: any
  history: any
}

const columns: any = [
  {
    label: "Status",
    className: "tag is-active",
    accessor: "status",
    id: "status",
    width: "20%",
    align: "left",
    bullet: true,
  },
  {
    label: "Name",
    accessor: "name",
    id: "name",
    width: "50%",
    align: "left",
    bullet: false,
  },
  {
    label: "My access role",
    accessor: "my_access",
    id: "my_access",
    className: "access-column",
    width: "30%",
    align: "left",
    bullet: false,
  },
]

class ProjectList extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
    }
  }

  componentDidMount() {
    this.props.fetchProjects()
  }

  redirect(url) {
    return this.props.history.push(url)
  }

  MyTableCell = (props: any) => {
    return <TableCell {...props} />
  }

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: +event.target.value })
    this.setState({ page: 0 })
  }

  render() {
    const { projects, project_config } = this.props

    return (
      <Grid container className="container-project-list-page">
        <Grid item xs={12} sm={12} md={12}>
          <h1 className="h1">
            Studies &nbsp;
            {projects.isFetching ? (
              <img
                className="base-margin-half"
                width="24px"
                height="24px"
                src="assets/icons/loading-image.gif"
                alt="loading"
              />
            ) : null}
          </h1>
        </Grid>
        <Grid item>
          <p className="paragraph">
            The studies that are owned by you and/or assigned to you are shown
            on this page
          </p>
        </Grid>
        {project_config.data.allowed_project_types?.includes(
          "central_reading"
        ) && (
          <Grid container justifyContent={"flex-end"}>
            <Grid item xs={12} sm={12} md={12}>
              <div className="button-row">
                <button
                  onClick={() => this.redirect("/project/create")}
                  className="btn primary"
                >
                  Create New Study
                </button>
              </div>
            </Grid>
          </Grid>
        )}
        {projects.data.length ? (
          <Grid item xs={12} sm={12} md={12}>
            <Paper className="root">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => {
                        return (
                          <TableCell
                            className="header-cell"
                            key={index}
                            align={column.align}
                            style={{ width: column.width }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.data
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row, rowIndex) => {
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            hover={true}
                            key={rowIndex}
                            onClick={() =>
                              this.redirect("/project/" + row.id + "/overview")
                            }
                            className="body-row"
                          >
                            {columns.map((column, cellIndex) => {
                              return (
                                <this.MyTableCell
                                  className="body-cell"
                                  width={`${column.width}`}
                                  component="th"
                                  scope="row"
                                  id={
                                    column.id +
                                    "-row-" +
                                    rowIndex +
                                    "-cell-" +
                                    cellIndex
                                  }
                                  key={
                                    column.id +
                                    "-row-" +
                                    rowIndex +
                                    "-cell-" +
                                    cellIndex
                                  }
                                >
                                  <span className={column.className}>
                                    {column.bullet && (
                                      <span className="bullet">•</span>
                                    )}
                                    {row[column.id]}
                                  </span>
                                </this.MyTableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={projects.data.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="align-center"
            style={
              projects.data.length === 0
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <EmptyTableMessage
              fetching={projects.isFetching}
              title="studies list"
              text={` Please add a collaborator on the "+ ADD COLLABORATOR" button on the
            top left of the screen`}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: () => dispatch(fetchList()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)
