import * as React from "react"

import { Route, Switch } from "react-router-dom"

import Activity from "./activity-page"
import AdjudicatorViewer from "./adjudicator-viewer"
import CentralReviewProgressMonitoring from "./central-review-progress-monitoring"
import Collaborators from "./collaborators-page"
import CollaboratorsAdd from "./collaborators-add"
import CollaboratorsEdit from "./collaborators-edit"
import EditStudy from "./edit-study"
import NotFoundPage from "../../components/common/notFound"
import Overview from "./overview-page"
import ProgressMonitoring from "./progress-monitoring"
import WorkListMatrix from "./work-list-session-matrix"
import WorkListSession from "./work-list-session"
import WorkListViewer from "./work-list-viewer"
import { connect } from "react-redux"
import { fetchItem } from "../../actions/ProjectListAction"

class Study extends React.Component<any, any> {
  componentDidMount() {
    const { getStudyData } = this.props
    getStudyData()
  }

  render() {
    if (
      this.props.current.project.data._id !==
        Number(this.props.match.params.project_id) ||
      this.props.current.project.isFetching
    ) {
      return <div></div>
    }

    return (
      <React.Fragment>
        <Switch>
          <Route
            path={"/project/:project_id/overview"}
            exact={true}
            render={(props) => <Overview {...props} />}
          />
          <Route
            path={"/project/:project_id/adjudicate/:task_id"}
            render={(props) => <AdjudicatorViewer {...props} />}
          />
          <Route
            path={"/project/:project_id/task/:task_id/history"}
            render={(props) => <WorkListMatrix {...props} />}
          />
          <Route
            path={"/project/:project_id/task/:task_id"}
            render={(props) => <WorkListViewer {...props} />}
          />
          <Route
            path={"/project/:project_id/collaborators/add"}
            render={(props) => <CollaboratorsAdd {...props} />}
          />
          <Route
            path={"/project/:project_id/collaborators/edit"}
            render={(props) => <CollaboratorsEdit {...props} />}
          />
          <Route
            path={"/project/:project_id/collaborators"}
            render={(props) => <Collaborators {...props} />}
          />
          <Route
            path={"/project/:project_id/information/edit"}
            render={(props) => <EditStudy {...props} />}
          />
          <Route
            path={"/project/:project_id/monitoring"}
            render={(props) => <CentralReviewProgressMonitoring {...props} />}
          />
          <Route
            path={"/project/:project_id/study-monitoring"}
            render={(props) => <ProgressMonitoring {...props} />}
          />
          <Route
            path={"/project/:project_id/worklist"}
            render={(props) => <WorkListSession {...props} />}
          />
          <Route
            path={"/project/:project_id/activity"}
            render={(props) => <Activity {...props} />}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state,
})

const mapDisptachToProps = (dispatch, props) => {
  return {
    getStudyData: () => dispatch(fetchItem(props.match.params.project_id)),
  }
}
export default connect(mapStateToProps, mapDisptachToProps)(Study)
