import React from "react"
import {
  ANALYSIS_HISTORY_TABLE_RECEIVE_DATA,
  ANALYSIS_HISTORY_TABLE_FETCHING_DATA,
  DATE_FORMAT,
} from "../constants/constants"
import { format } from "date-fns"
const formatReceiveData = (action) => {
  return action.payload.data.map((el) => ({
    id: el._id || " - ",
    date: format(el.time_created.$date, `${DATE_FORMAT}`) || " - ", // 'LLL' should format the format from the browser locale
    timestamp: el.time_created.$date,
    username: el.userfull || " - ",
    value: el.new_value || " - ",
    comment: el.comment,
  }))
}

const initialState = {
  columns: [
    {
      Header: "Date / time",
      maxWidth: 250,
      accessor: "date",
      id: "date",
      show: true,
      sortField: "timestamp",
    },
    {
      Header: "Author",
      maxWidth: 250,
      accessor: "username",
      id: "username",
      show: true,
      sortField: "username",
    },
    {
      Header: "New value",
      accessor: "value",
      id: "value",
      maxWidth: 450,
      show: true,
      sortField: "value",
      Cell: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.value }} />
      },
    },
    {
      Header: "Comment",
      accessor: "comment",
      id: "comment",
      maxWidth: 450,
      show: true,
      sortField: "comment",
      Cell: (props) => {
        return <div dangerouslySetInnerHTML={{ __html: props.comment }} />
      },
    },
  ],
  data: [],
  page: {
    from: 0,
    to: 0,
    total: 0,
    pages: 0,
    pageIndex: 0,
    pageSize: 10,
  },
  isFetching: false,
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ANALYSIS_HISTORY_TABLE_RECEIVE_DATA:
      return {
        ...state,
        data: formatReceiveData(action),
        page: {
          ...state.page,
          ...action.payload.page,
        },
        empty: action.emptyTable,
        isFetching: false,
        isInvalidated: false,
      }

    case ANALYSIS_HISTORY_TABLE_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
