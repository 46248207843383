import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { compose } from "recompose"
import { useSelector, useDispatch } from "react-redux"
import { Card, CardContent, withStyles } from "@material-ui/core"
import {
  setReader_1,
  setAdjudicator,
  setAssignUser,
  getCentralReadingProgress,
  setReader_2,
  setUnassignUser,
  setNotNeededAdjudicator,
} from "../../actions/CentralReviewAction"
import "./progress-monitoring.sass"
import AddUserModal from "../../components/common/Table/add-user-modal"
import { RootState } from "../../reducers/mainReducer"
import DialogActions from "@material-ui/core/DialogActions"
import { makeStyles } from "@material-ui/core/styles"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table/"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Tooltip from "@material-ui/core/Tooltip"
import ExpandMenuButton from "../../components/common/expand-menu-button"
import MenuItem from "@material-ui/core/MenuItem"
import history from "../../utils/history"

interface Column {
  id: any
  cellName: any
  label: string
  width?: number
  minWidth?: number
  align?: "right"
  className?: string
  sortField: string
  format?: (value: number) => string
}

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
})

const styles = (theme) => ({
  title: {
    marginBottom: "8px",
  },
  form: {
    marginRight: "1rem",
    "& span": {
      textTransform: "capitalize",
    },
  },
  iconDescriptionContainer: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  iconDescriptionContainerRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
  iconDescriptionContainerCell: {
    display: "flex",
    alignItems: "center",
    lineHeight: "25px",
    height: "25px",
  },
  iconCard: {
    paddingBottom: "16px",
  },
  row: {
    cursor: "not-allowed",
  },
  iconimg: {
    marginRight: "5px",
  },
  buttonContainer: {
    padding: "0",
    alignItems: "flex-end",
  },
  rowImg: {
    height: "25px",
    width: "auto",
  },
  rowButton: {
    background: "transparent",
    border: "1px solid black",
    padding: "5px",
    cursor: "pointer",
  },
})

let tmp = {}

const CentralReviewProgressMonitoring: React.FC = (props: any) => {
  const classesForTableContainer = useStyles()
  const [order, orderSet] = React.useState("asc")
  const [orderBy, orderBySet] = React.useState("id")
  const [pageTable, pageTableSet] = React.useState(0)
  const [openDialog, openDialogSet] = React.useState(false)
  const [comesFromAdj, comesFromAdjSet] = React.useState(false)
  const [isLoading, isLoadingSet] = React.useState({
    rowIndex: null,
    cellIndex: null,
    loading: false,
  })
  const [valuesNotNeededAdj, valuesNotNeededAdjSet] = React.useState({})
  const [valuesUnassign, valuesUnassignSet] = React.useState({})
  const [visibleAddUserModal, setVisibleAddUserModal] = React.useState(false)
  const [projectUsers, projectUsersSet] = React.useState<
    Array<{ key: string; value: string }>
  >([])
  const [reader_1, reader_1Set] = React.useState({})
  const [reader_2, reader_2Set] = React.useState({})
  const [columns, setColumns] = React.useState<Column[]>([
    {
      label: "Subject",
      minWidth: 170,
      id: "patient_secret_name",
      cellName: "patient_secret_name",
      sortField: "patient_secret_name",
    },
    {
      label: "Session",
      minWidth: 170,
      id: "ssid",
      cellName: "ssid",
      sortField: "ssid",
    },
    {
      label: "QA",
      minWidth: 170,
      id: "qa_status_icon",
      cellName: "qa",
      sortField: "qa_status_icon",
    },
  ])
  const [adjudicator, adjudicatorSet] = React.useState({})
  const [columnName, setColumnName] = React.useState("")
  const [clickedRow, setClickedRow] = React.useState({
    unassigned_readers: [],
    _id: 0,
  })
  const [queryData, SetQueryData] = React.useState({
    projectset_id: props.match.params.project_id,
    limit: 10,
    offset: 0,
    status: "",
    assigned_to: "",
    sort: "1, ssid",
  })
  const [title] = React.useState("Assign To:")
  const [submitButton] = React.useState("Assign")
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  const DataStatus = [
    {
      name: "Pass",
      value: "pass",
    },
    {
      name: "Fail",
      value: "fail",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "In progress",
      value: "in_progress",
    },
    {
      name: "Not Needed",
      value: "not_needed",
    },
  ]

  useEffect(() => {
    dispatch(getCentralReadingProgress(queryData))
  }, [reader_1, reader_2, adjudicator, queryData])

  useEffect(() => {
    projectUsersSet(state.current.project.data.users)
    for (let i = 1; i <= state.current.project.data.config.num_readers; i++) {
      columns.push({
        label: `Reading ${i}`,
        minWidth: 190,
        id: `reader_${i}_status_icon`,
        cellName: `reading_${i}`,
        sortField: `reader_${i}_status_icon`,
      })
    }

    for (
      let i = 1;
      i <= state.current.project.data.config.num_diagnosis_adjudicators;
      i++
    ) {
      columns.push({
        label: "Adjudication",
        minWidth: 190,
        id: "diagnosis_adjudicator_status_icon",
        cellName: "diagnosis_adjudicator",
        sortField: "diagnosis_adjudicator_status_icon",
      })
    }
  }, [state.current.project.data])

  const handleClickOpen = () => {
    openDialogSet(true)
  }

  const handleClose = () => {
    openDialogSet(false)
  }

  const _openAddUserModal = (
    row: any,
    column: any,
    cellIndex: any,
    rowIndex: any
  ) => {
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    setClickedRow(row)
    setColumnName(column.cellName)
    setVisibleAddUserModal(true)
  }

  const createSortHandler = (property) => {
    if (property) {
      order === "asc" ? orderSet("desc") : orderSet("asc")
      orderBySet(property)
      if (order === "asc") {
        SetQueryData((prevState: any) => ({
          ...prevState,
          sort: `1,${property}`,
        }))
      } else {
        SetQueryData((prevState: any) => ({
          ...prevState,
          sort: `-1,${property}`,
        }))
      }
    }
  }

  const _closeAddUserModal = () => {
    setVisibleAddUserModal(false)
  }

  const filterUsersInModal = (willReturn) => {
    tmp = {}
    clickedRow.unassigned_readers.map((name) => {
      projectUsers.map((user: any, index) => {
        if (user.user_id === name) {
          tmp = {
            ...tmp,
            [user.name]: name,
          }
        }
      })
    })

    if (willReturn) {
      return Object.keys(tmp).map((key) => key)
    }
  }

  const setAssignUserAsync = async (values) => {
    isLoadingSet({
      ...isLoading,
      loading: true,
    })
    Promise.all([dispatch(setAssignUser(values, queryData))]).then(() =>
      isLoadingSet({
        ...isLoading,
        loading: false,
      })
    )
  }

  const setUnassignUserAsync = async () => {
    handleClose()
    Promise.all([dispatch(setUnassignUser(valuesUnassign, queryData))]).then(
      () => {
        isLoadingSet({
          ...isLoading,
          loading: false,
        })
      }
    )
  }

  const onSubmitModal = (friendlyUser) => {
    const user = tmp[friendlyUser]
    setVisibleAddUserModal(false)
    const values = {
      patient_id: clickedRow._id,
      assigned_to: user,
      task_type: columnName,
      projectset_id: props.match.params.project_id,
      // assigned_by: state.CentralReview.workList[0].assigned_by
    }

    isLoadingSet({
      ...isLoading,
      loading: true,
    })

    switch (columnName) {
      case "reading_1":
        reader_1Set(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setReader_1(user))
          filterUsersInModal(false)
        })
      case "reading_2":
        reader_2Set(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setReader_2(user))
          filterUsersInModal(false)
        })
      case "diagnosis_adjudicator":
        adjudicatorSet(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setAdjudicator(user))
          filterUsersInModal(false)
        })
    }
  }

  const { classes } = props

  const MyTableCell = (props: any) => {
    return <TableCell {...props} />
  }

  const MyTableSortLabel = (props: any) => {
    return <TableSortLabel {...props} />
  }

  const handleAssignedUser = (columnIndex, rowIndex) => {
    switch (columnIndex) {
      case 3: // Reader 1
        return state.CentralReview.progress[rowIndex]
          .reader_1_assigned_to_display_name
      case 4: // Reader 2
        return state.CentralReview.progress[rowIndex]
          .reader_2_assigned_to_display_name
      case 5: // Adjudicator
        return state.CentralReview.progress[rowIndex]
          .diagnosis_adjudicator_assigned_to_display_name
    }
  }

  const handleAssignedUserEmails = (columnIndex, rowIndex) => {
    switch (columnIndex) {
      case 3: // Reader 1 email / user_id
        return state.CentralReview.progress[rowIndex].reader_1_assigned_to
      case 4: // Reader 2 email / user_id
        return state.CentralReview.progress[rowIndex].reader_2_assigned_to
      case 5: // Adjudicator email / user_id
        return state.CentralReview.progress[rowIndex]
          .diagnosis_adjudicator_assigned_to
    }
  }

  const setNotNeededAdj = (cellIndex: any, rowIndex: any, patient_id: any) => {
    const values = {
      projectset_id: props.match.params.project_id,
      patient_id,
    }
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    valuesNotNeededAdjSet(values)
    comesFromAdjSet(true)
    handleClickOpen()
  }

  const handleNotNeededAdjDispatch = () => {
    handleClose()
    isLoadingSet({
      ...isLoading,
      loading: true,
    })
    Promise.all([dispatch(setNotNeededAdjudicator(valuesNotNeededAdj))]).then(
      () => {
        isLoadingSet({
          ...isLoading,
          loading: false,
        })
        dispatch(getCentralReadingProgress(queryData))
      }
    )
  }

  const setUnassignUserDispatch = (user_id, cellIndex, rowIndex) => {
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    let task_id
    switch (cellIndex) {
      case 3: // Reader 1 task_id
        task_id = state.CentralReview.progress[rowIndex].reader_1_task_id
        break
      case 4: // Reader 2 task_id
        task_id = state.CentralReview.progress[rowIndex].reader_2_task_id
        break
      case 5: // Adjudicator task_id
        task_id =
          state.CentralReview.progress[rowIndex].diagnosis_adjudicator_task_id
        break
    }

    const values = {
      projectset_id: props.match.params.project_id,
      task_id,
      user_id,
    }
    valuesUnassignSet(values)
    comesFromAdjSet(false)
    handleClickOpen()
  }

  const handleUnassignUserDispatch = () => {
    setUnassignUserAsync().then(() => {
      isLoadingSet({
        ...isLoading,
        loading: false,
      })
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pageTableSet(newPage)
    SetQueryData((prevState: any) => ({
      ...prevState,
      offset: newPage ? newPage * queryData.limit + 1 : 0,
    }))
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    pageTableSet(0)
    SetQueryData((prevState: any) => ({
      ...prevState,
      offset: 0,
      limit: +event.target.value,
    }))
  }

  return (
    <>
      {state.CentralReview.progress && (
        <Grid
          container
          style={{ padding: "16px", justifyContent: "space-between" }}
        >
          <Grid item xs={12}>
            <h1 className={"h1 " + classes.title}>
              Central Review monitoring &nbsp;
              {state.CentralReview.isFetching && (
                <img
                  className="base-margin-half"
                  width="24px"
                  height="24px"
                  src="assets/icons/loading-image.gif"
                  alt="loading"
                />
              )}
            </h1>
            <label>
              You are monitoring {state.CentralReview.totalResult} active tasks
            </label>
          </Grid>
          {state.CentralReview.progress ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                {!state.CentralReview.isFetching && (
                  <Paper className={classesForTableContainer.root}>
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <MyTableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={
                                  orderBy === column.id ? order : false
                                }
                              >
                                {column.label}
                              </MyTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.CentralReview.progress.map((row, rowIndex) => {
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={rowIndex}
                              >
                                {columns.map((column, cellIndex) => {
                                  const value = row[column.id]
                                  if (
                                    column.id === "qa_status_icon" ||
                                    column.id === "reader_1_status_icon" ||
                                    column.id === "reader_2_status_icon" ||
                                    column.id ===
                                      "diagnosis_adjudicator_status_icon"
                                  ) {
                                    if (value === "./assets/icons/assign.png") {
                                      return (
                                        <MyTableCell
                                          width={`${column.minWidth}px`}
                                          component="th"
                                          className={classes.rowText}
                                          scope="row"
                                          id={
                                            column.id +
                                            "-row-" +
                                            rowIndex +
                                            "-cell-" +
                                            cellIndex
                                          }
                                          key={
                                            column.id +
                                            "-row-" +
                                            rowIndex +
                                            "-cell-" +
                                            cellIndex
                                          }
                                        >
                                          {cellIndex === 5 &&
                                          row.reader_1_status === "done" &&
                                          row.reader_2_status === "done" ? (
                                            isLoading.cellIndex === cellIndex &&
                                            isLoading.rowIndex === rowIndex &&
                                            isLoading.loading ? (
                                              <img
                                                className="base-margin-half"
                                                width="24px"
                                                height="24px"
                                                src="assets/icons/loading_white.gif"
                                                alt="loading"
                                              />
                                            ) : (
                                              <FormControl
                                                className={classes.formControl}
                                              >
                                                <Select
                                                  labelId="demo-simple-select-autowidth-label"
                                                  id="demo-simple-select-autowidth"
                                                  value={1}
                                                  onChange={() => null}
                                                  autoWidth
                                                >
                                                  <MenuItem value={1}>
                                                    <button
                                                      className={
                                                        classes.rowButton
                                                      }
                                                      onClick={() =>
                                                        _openAddUserModal(
                                                          row,
                                                          column,
                                                          cellIndex,
                                                          rowIndex
                                                        )
                                                      }
                                                    >
                                                      Assign
                                                    </button>
                                                  </MenuItem>
                                                  <MenuItem value={2}>
                                                    <button
                                                      className={
                                                        classes.rowButton
                                                      }
                                                      onClick={() =>
                                                        setNotNeededAdj(
                                                          cellIndex,
                                                          rowIndex,
                                                          row._id
                                                        )
                                                      }
                                                    >
                                                      Not Needed
                                                    </button>
                                                  </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                  Assign Adjudicator
                                                </FormHelperText>
                                              </FormControl>
                                            )
                                          ) : (
                                            <div
                                              className={`assignable_${cellIndex}`}
                                            >
                                              {isLoading.cellIndex ===
                                                cellIndex &&
                                              isLoading.rowIndex === rowIndex &&
                                              isLoading.loading ? (
                                                <img
                                                  className="base-margin-half"
                                                  width="24px"
                                                  height="24px"
                                                  src="assets/icons/loading_white.gif"
                                                  alt="loading"
                                                />
                                              ) : cellIndex === 5 ? (
                                                <img
                                                  alt="rowImg"
                                                  className={classes.rowImg}
                                                  src={
                                                    "./assets/icons/open.png"
                                                  }
                                                />
                                              ) : (
                                                <button
                                                  disabled={
                                                    isLoading.loading &&
                                                    isLoading.rowIndex ===
                                                      rowIndex
                                                  }
                                                  className={classes.rowButton}
                                                  onClick={() =>
                                                    _openAddUserModal(
                                                      row,
                                                      column,
                                                      cellIndex,
                                                      rowIndex
                                                    )
                                                  }
                                                >
                                                  Assign
                                                </button>
                                              )}
                                            </div>
                                          )}
                                        </MyTableCell>
                                      )
                                    } else {
                                      return (
                                        <MyTableCell
                                          width={`${column.minWidth}px`}
                                          component="th"
                                          className={classes.rowText}
                                          scope="row"
                                          id={
                                            column.id +
                                            "-row-" +
                                            rowIndex +
                                            "-cell-" +
                                            cellIndex
                                          }
                                          key={
                                            column.id +
                                            "-row-" +
                                            rowIndex +
                                            "-cell-" +
                                            cellIndex
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {isLoading.cellIndex ===
                                              cellIndex &&
                                            isLoading.rowIndex === rowIndex &&
                                            isLoading.loading ? (
                                              <img
                                                className="base-margin-half"
                                                width="24px"
                                                height="24px"
                                                src="assets/icons/loading_white.gif"
                                                alt="loading"
                                              />
                                            ) : (
                                              <div
                                                style={{
                                                  margin: "0",
                                                  padding: "0",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Tooltip
                                                  title={`Task is in ${
                                                    row[column.id]
                                                      .split("/")[3]
                                                      .split(".png")[0]
                                                  } mode`}
                                                  placement="bottom-start"
                                                >
                                                  <img
                                                    alt="rowImg"
                                                    className={classes.rowImg}
                                                    src={value}
                                                  />
                                                </Tooltip>
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    marginLeft: "5px",
                                                    minWidth: "80px",
                                                    maxWidth: "80px",
                                                    overflowWrap: "break-word",
                                                  }}
                                                >
                                                  {handleAssignedUser(
                                                    cellIndex,
                                                    rowIndex
                                                  )}
                                                </span>
                                                {(value.includes("pass") ||
                                                  value.includes("fail") ||
                                                  value.includes("open")) &&
                                                  cellIndex !==
                                                    2 /** 2 is QA */ && (
                                                    <ExpandMenuButton
                                                      centralReadingTable={true}
                                                      row={row}
                                                      isForView={true}
                                                      children={
                                                        <div>
                                                          {value.includes(
                                                            "pass"
                                                          ) && (
                                                            <MenuItem
                                                              id={
                                                                "menu_item_info.row-" +
                                                                rowIndex +
                                                                "-cell-" +
                                                                cellIndex
                                                              }
                                                              onClick={() => {
                                                                switch (
                                                                  cellIndex
                                                                ) {
                                                                  case 3: // Reader 1
                                                                    return history.push(
                                                                      `/project/${props.match.params.project_id}/task/${row.reader_1_task_id}`
                                                                    )
                                                                  case 4: // Reader 2
                                                                    return history.push(
                                                                      `/project/${props.match.params.project_id}/task/${row.reader_2_task_id}`
                                                                    )
                                                                  case 5: // Adjudicator
                                                                    return history.push(
                                                                      `/project/${props.match.params.project_id}/adjudicate/${row.diagnosis_adjudicator_task_id}`
                                                                    )
                                                                }
                                                              }}
                                                            >
                                                              View Read
                                                            </MenuItem>
                                                          )}
                                                          {value.includes(
                                                            "open"
                                                          ) &&
                                                            handleAssignedUserEmails(
                                                              cellIndex,
                                                              rowIndex
                                                            ) != null && (
                                                              <MenuItem
                                                                id={
                                                                  "menu_item_info.row-" +
                                                                  rowIndex +
                                                                  "-cell-" +
                                                                  cellIndex
                                                                }
                                                                onClick={() =>
                                                                  setUnassignUserDispatch(
                                                                    handleAssignedUserEmails(
                                                                      cellIndex,
                                                                      rowIndex
                                                                    ),
                                                                    cellIndex,
                                                                    rowIndex
                                                                  )
                                                                }
                                                              >
                                                                Unassign
                                                              </MenuItem>
                                                            )}
                                                        </div>
                                                      }
                                                      cellDetails={{
                                                        rowIndex,
                                                        cellIndex,
                                                      }}
                                                    />
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        </MyTableCell>
                                      )
                                    }
                                  } else {
                                    return (
                                      <TableCell
                                        key={cellIndex}
                                        align={column.align}
                                      >
                                        {column.format &&
                                        typeof value === "number"
                                          ? column.format(value)
                                          : value}
                                      </TableCell>
                                    )
                                  }
                                })}
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={state.CentralReview.totalResult}
                      rowsPerPage={queryData.limit}
                      page={pageTable}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      backIconButtonProps={
                        isLoading.loading
                          ? {
                              disabled: isLoading.loading,
                            }
                          : undefined
                      }
                      nextIconButtonProps={
                        isLoading.loading
                          ? {
                              disabled: isLoading.loading,
                            }
                          : undefined
                      }
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} className={classes.iconDescriptionContainer}>
                <h2 className={classes.title + " h2"}>Icon description</h2>
                <Card>
                  <CardContent
                    className={classes.iconCard}
                    style={{ paddingBottom: "16px" }}
                  >
                    <Grid
                      container
                      className={classes.iconDescriptionContainerRow}
                    >
                      {DataStatus.map((status, index) => {
                        return (
                          <div
                            key={index}
                            className={classes.iconDescriptionContainerCell}
                          >
                            <img
                              alt={status.name}
                              width={25}
                              src={`./assets/icons/${status.value}.png`}
                              className={classes.iconimg}
                            />
                            <p>{status.name}</p>
                          </div>
                        )
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              justifyContent={"center"}
              alignContent={"center"}
            >
              {state.CentralReview.isEmpty ? (
                <p>No task on this result</p>
              ) : (
                <p>Loading</p>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {visibleAddUserModal && (
        <AddUserModal
          listOfUsers={filterUsersInModal(true)}
          open={visibleAddUserModal}
          onClose={_closeAddUserModal}
          onSubmit={false}
          onSubmitAssignModal={(user) => onSubmitModal(user)}
          title={title}
          submitButton={submitButton}
        />
      )}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {comesFromAdj
              ? "Do you want to set adjudication to not needed?"
              : "Do you want to unassign this user?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#279181" }}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              comesFromAdj
                ? handleNotNeededAdjDispatch()
                : handleUnassignUserDispatch()
            }
            style={{ color: "#279181" }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default compose(withStyles(styles))(CentralReviewProgressMonitoring)
