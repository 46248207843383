import { applyMiddleware, compose, createStore } from "redux"
import createMainReducer from "./reducers/mainReducer"
import thunk from "redux-thunk"

import ApiMiddleware from "./middlewares/ApiMiddleware"

import { NotificationsMiddleware } from "./utils/Notifications/index"

export const store = () =>
  (createStore as any)(
    createMainReducer(),
    compose(applyMiddleware(thunk, ApiMiddleware(), NotificationsMiddleware()))
  )

export default store
