import "./AuthorizationApp.sass"

import * as React from "react"

import { Redirect, Route, Switch } from "react-router-dom"
import { getLaunchDarklyUser, ldClientSideId } from "../utils/launch-darkly"
import { getMe, getProjectModuleConfig } from "../actions/apiAction"

import BreadcrumbContextProvider from "./common/breadcrumbsContext"
import LoggedApp from "./LoggedApp"
import { Notifier } from "../utils/Notifications"
import ScrollTopTop from "../components/scroll-to-top/scrollToTop"
import { compose } from "recompose"
import { connect } from "react-redux"
import { withLDProvider } from "launchdarkly-react-client-sdk"

interface Props {
  session: any
  getMe: any
  getProjectModuleConfig: any
}

const userLD = getLaunchDarklyUser()

class AuthorizationApp extends React.Component<Props> {
  componentDidMount(): void {
    this.props.getMe()
    this.props.getProjectModuleConfig()
  }

  render() {
    return (
      <div id="main" className="grey-background">
        <Notifier />

        <BreadcrumbContextProvider>
          <ScrollTopTop />
          <Switch>
            <Route path="/" exact render={() => <Redirect to="/project" />} />

            <Route component={LoggedApp} />
          </Switch>
        </BreadcrumbContextProvider>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMe: () => dispatch(getMe()),
    getProjectModuleConfig: () => dispatch(getProjectModuleConfig()),
  }
}

const mapStateToProps = ({ session }) => ({
  session,
})

export default compose(
  withLDProvider({
    clientSideID: ldClientSideId(),
    user: userLD,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AuthorizationApp)
