import { SET_FILE } from "../constants/constants"

const INITIAL_STATE = {
  file: {
    name: "",
    tags: [],
  },
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILE:
      return {
        file: action.payload,
      }
    default:
      return state
  }
}
