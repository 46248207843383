import React, { useState } from "react"
import HistoryIcon from "@material-ui/icons/History"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Grid } from "@material-ui/core"
import "./StudiesMenu.sass"
import LinkIcon from "../../../components/icons/link-icon/link-icon"
import WindowIcon from "../../../components/icons/window-icon/window-icon"
import { RootState } from "../../../reducers/mainReducer"
import { getCurrentTask } from "../../../actions/CentralReviewAction"
import history from "../../../utils/history"
import PanIcon from "../../../components/icons/pan-icon/pan-icon"
import ZoomIcon from "../../../components/icons/zoom-icon/zoom-icon"
import RotateIcon from "../../../components/icons/rotate-icon/rotate-icon"

const StudiesMenu = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)
  const [activeDropdown, setActiveDropdown] = useState("")
  const [activeLink, activeLinkSet] = React.useState("")

  const handleActiveDropdown = (value) => {
    // return activeDropdown === value
    //   ? setActiveDropdown("")
    //   : setActiveDropdown(value)
  }

  return (
    <div className="top-menu">
      <div className="top-menu-history">
        {Object.keys(state.CentralReview.currentTask.form_fields).length && (
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Link to={`${history.location.pathname}/history`} target="_blank">
              <div
                onClick={() => getCurrentTask(state.CentralReview.currentTask)}
              >
                <span>
                  <HistoryIcon />
                </span>
                <span>History</span>
              </div>
            </Link>
          </Grid>
        )}
      </div>
      <div className="card-top-btn" id="viewer-buttons-menu">
        <LinkIcon
          linkIcon={activeLink === "link"}
          handleActiveIcon={(value: string) => activeLinkSet(value)}
        />
        <WindowIcon
          windowIcon={props.activeIcon === "window"}
          handleActiveIcon={(value: string) => props.handleActiveIcon(value)}
          dropdown={activeDropdown}
          handleDropdown={() => handleActiveDropdown("window")}
        />
        <PanIcon
          viewerInteractionsIcon={props.activeIcon === "viewerInteractions"}
          handleActiveIcon={(value: string) => props.handleActiveIcon(value)}
          dropdown={activeDropdown}
          handleDropdown={() => handleActiveDropdown("viewerInteractions")}
        />
        <ZoomIcon
          zoomIcon={props.activeIcon === "zoom"}
          handleActiveIcon={(value: string) => props.handleActiveIcon(value)}
          dropdown={activeDropdown}
          handleDropdown={() => handleActiveDropdown("zoom")}
        />
        <RotateIcon
          rotateIcon={props.activeIcon === "rotate"}
          handleActiveIcon={(value: string) => props.handleActiveIcon(value)}
          dropdown={activeDropdown}
          handleDropdown={() => handleActiveDropdown("rotate")}
        />
      </div>
    </div>
  )
}

export default StudiesMenu
