import { COLLABORATORS_TABLE_INVALIDATE_DATA } from "../constants/constants"

const initialState = {
  isInvalidated: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case COLLABORATORS_TABLE_INVALIDATE_DATA:
      return {
        ...state,
        isInvalidated: true,
      }

    default:
      return {
        ...state,
      }
  }
}
