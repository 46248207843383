import {
  ISetAnalysisList,
  ISetCurrentProjectID,
  ISetSession,
  ISetSessionExpiration,
  ISetSessionExpired,
  IUpdateUserSessionAccess,
} from "../constants/interfaces"
import {
  SET_ANALYSIS_LIST,
  SET_CURRENT_PROJECT_ID,
  SET_SESSION,
  SET_SESSION_EXPIRATION,
  SET_SESSION_EXPIRED,
  UPDATE_USER_SESSION_ACCESS,
} from "../constants/constants"

const SESSION_INITIAL_STATE = {
  user_data: {
    "2fa": {},
    av_data: 0,
    av_projects: 0,
    dev: 1,
    email: "",
    institution: "",
    name: "",
    release_version: 0,
    run_parallel: 0,
    session_expiration: 0,
    tab_preference: "",
    username: "",
    access: null,
    isManager: false,
  },
  session_expired: false,
}

interface IUserData {
  "2fa": {}
  av_data: number
  av_projects: number
  dev: number
  email: string
  institution: string
  name: string
  release_version: string | number
  run_parallel: number
  session_expiration: number
  tab_preference: string
  username: string
  access: any
  isManager: boolean | number
}

interface ISessionInitialState {
  user_data: IUserData
  session_expired?: boolean
  active_project?: number
  analysis_list?: any
}

type Action =
  | ISetAnalysisList
  | ISetCurrentProjectID
  | ISetSession
  | ISetSessionExpired
  | ISetSessionExpiration
  | IUpdateUserSessionAccess

export default function (
  state: ISessionInitialState = SESSION_INITIAL_STATE,
  action: Action
) {
  switch (action.type) {
    case SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        active_project: action.payload,
      }

    case SET_SESSION:
      return {
        user_data: {
          ...state.user_data,
          ...action.payload.user_data,
        },
      }

    case UPDATE_USER_SESSION_ACCESS:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          access: action.payload.access,
          isManager: action.payload.isManager,
        },
      }

    case SET_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: action.payload,
      }

    case SET_SESSION_EXPIRATION:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          session_expiration: action.payload,
        },
      }

    case SET_ANALYSIS_LIST:
      return {
        ...state,
        analysis_list: action.payload,
      }
    default:
      return state
  }
}
