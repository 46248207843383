const setLaunchDarklyUser = (session) => {
  const userLD = {
    key: session.email,
    email: session.email,
    name: session.name,
  }
  return userLD
}

const getLaunchDarklyUser = () => {
  // default user key without a session
  let userLD = {
    key: "not_authenticated",
    name: "Unknown user name",
  }
  const qmentaGlobalState = window.localStorage["qmenta-global-state"]

  if (qmentaGlobalState !== undefined) {
    try {
      userLD = setLaunchDarklyUser(
        JSON.parse(qmentaGlobalState).session.user_data
      )
    } catch (error) {
      // return empty userLD
    }
  }
  return userLD
}

const ldClientSideId = () => {
  // trials-dev or localhost
  let ldClientSideId = "5d8b3b2eae67ce07a1ea4098"
  if (
    window.location.host.startsWith("trials.") ||
    window.location.host.startsWith("trials-staging")
  ) {
    ldClientSideId = "5d8b3b2eae67ce07a1ea4099"
  }
  return ldClientSideId
}

export { getLaunchDarklyUser, ldClientSideId }
